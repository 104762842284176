import React from 'react';
import { Twitter, Facebook, Instagram, Linkedin, Github } from 'lucide-react';

const socialLinks = [
  { Icon: Twitter, href: 'https://twitter.com' },
  { Icon: Facebook, href: 'https://facebook.com' },
  { Icon: Instagram, href: 'https://instagram.com' },
  { Icon: Linkedin, href: 'https://linkedin.com' },
  { Icon: Github, href: 'https://github.com' }
];

export function FooterSocial() {
  return (
    <div className="flex space-x-6">
      {socialLinks.map(({ Icon, href }) => (
        <a
          key={href}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-400 hover:text-white transition-colors"
        >
          <Icon className="h-6 w-6" />
        </a>
      ))}
    </div>
  );
}