import React, { useEffect, useState, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { getSubscriptionStartDate, fetchCallRecords } from '../services/api';
import { format } from 'date-fns';
import { 
  ChevronDown, 
  ChevronUp, 
  Copy, 
  Clock, 
  Phone, 
  Calendar 
} from 'lucide-react';

const ThemedCallTrack = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [callRecords, setCallRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    month: '',
    year: '',
    assistantId: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: '',
    direction: 'asc'
  });

  const months = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  const years = Array.from(
    { length: new Date().getFullYear() - 2019 + 1 },
    (_, i) => (2020 + i).toString()
  );

  useEffect(() => {
    if (organizationId) {
      fetchRecords();
    }
  }, [organizationId, filters, currentPage, sortConfig]);

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const response = await fetchCallRecords(organizationId, {
        month: filters.month,
        year: filters.year,
        assistantId: filters.assistantId,
        page: currentPage,
        size: 10,
        sort: sortConfig.key ? `${sortConfig.key},${sortConfig.direction}` : undefined
      });
      setCallRecords(response.content || []);
      setTotalPages(response.totalPages || 1);
    } catch (error) {
      console.error('Error fetching call records:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (key) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const SortIcon = ({ column }) => {
    if (sortConfig.key !== column) {
      return <ChevronUp className="h-4 w-4 text-gray-400" />;
    }
    return sortConfig.direction === 'asc' 
      ? <ChevronUp className="h-4 w-4 text-blue-400" />
      : <ChevronDown className="h-4 w-4 text-blue-400" />;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    // Could add a toast notification here
  };

  return (
    <div className="bg-gray-900 rounded-xl shadow-lg">
      {/* Header */}
      <div className="p-6 border-b border-gray-800">
        <h2 className="text-xl font-semibold text-gray-100 flex items-center gap-2">
          <Phone className="h-5 w-5 text-blue-400" />
          Call Track Records
        </h2>
      </div>

      {/* Filters */}
      <div className="p-6 border-b border-gray-800">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Assistant ID
            </label>
            <input
              type="text"
              value={filters.assistantId}
              onChange={(e) => setFilters(prev => ({ ...prev, assistantId: e.target.value }))}
              placeholder="Enter Assistant ID"
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-100 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Month
            </label>
            <select
              value={filters.month}
              onChange={(e) => setFilters(prev => ({ ...prev, month: e.target.value }))}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">All Months</option>
              {months.map(month => (
                <option key={month.value} value={month.value}>{month.label}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Year
            </label>
            <select
              value={filters.year}
              onChange={(e) => setFilters(prev => ({ ...prev, year: e.target.value }))}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">All Years</option>
              {years.map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-800">
              <th 
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('id')}
              >
                <div className="flex items-center gap-1">
                  Call ID
                  <SortIcon column="id" />
                </div>
              </th>
              <th 
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('assistantName')}
              >
                <div className="flex items-center gap-1">
                  Assistant Name
                  <SortIcon column="assistantName" />
                </div>
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Duration
              </th>
              <th 
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('startTime')}
              >
                <div className="flex items-center gap-1">
                  Date
                  <SortIcon column="startTime" />
                </div>
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Cost
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-800">
            {loading ? (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-gray-400">
                  Loading...
                </td>
              </tr>
            ) : callRecords.length === 0 ? (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-gray-400">
                  No call records found
                </td>
              </tr>
            ) : (
              callRecords.map((record) => (
                <tr key={record.id} className="hover:bg-gray-800/50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-mono text-gray-100">{record.id}</span>
                      <button
                        onClick={() => copyToClipboard(record.id)}
                        className="text-gray-400 hover:text-gray-300"
                      >
                        <Copy className="h-4 w-4" />
                      </button>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="text-sm text-gray-100">
                      {record.assistantResponse?.vapiAssistant?.name}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-1 text-gray-100">
                      <Clock className="h-4 w-4 text-gray-400" />
                      <span>{record.durationInMinutes}m</span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-1 text-gray-100">
                      <Calendar className="h-4 w-4 text-gray-400" />
                      <span>{format(new Date(record.startTime), 'PPp')}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="text-sm font-medium text-gray-100">
                      ${record.cost.toFixed(2)}
                    </span>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex justify-center p-4 border-t border-gray-800">
          <div className="flex gap-2">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => setCurrentPage(i + 1)}
                className={`px-3 py-1 rounded-md text-sm font-medium ${
                  currentPage === i + 1
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                }`}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ThemedCallTrack;