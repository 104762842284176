import React from 'react';
import { Phone, Calendar, Building2, BrainCircuit, Clock, Users, MessageSquare, HeartHandshake } from 'lucide-react';

const FeatureCard = ({ title, description, icon: Icon }) => (
  <div className="p-6 rounded-lg bg-white shadow-lg hover:shadow-xl transition-shadow">
    <div className="flex items-center mb-4">
      <Icon className="w-8 h-8 text-blue-600 mr-3" />
      <h3 className="text-xl font-semibold text-gray-900">{title}</h3>
    </div>
    <p className="text-gray-600">{description}</p>
  </div>
);

const UseCaseCard = ({ title, description, imageUrl }) => (
  <div className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow">
    <div className="h-48 bg-gray-200">
      <img 
        src={imageUrl || "/api/placeholder/400/200"} 
        alt={title}
        className="w-full h-full object-cover"
      />
    </div>
    <div className="p-6">
      <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const AIPhoneAgentPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      {/* Hero Section */}
      <section className="py-20 px-4">
        <div className="max-w-6xl mx-auto text-center">
          <h1 className="text-5xl font-bold text-gray-900 mb-6">
            Transform Your Business Communication with AI Phone Agent
          </h1>
          <p className="text-xl text-gray-600 mb-12 max-w-3xl mx-auto">
            Harness the power of artificial intelligence to handle your phone calls 24/7, 
            schedule appointments, and provide exceptional customer service.
          </p>
          <div className="bg-white rounded-2xl shadow-2xl p-8 max-w-4xl mx-auto">
            <img 
              src="/AIPhoneAgentHeroImage.jpg" 
              alt="AI Phone Agent Interface"
              className="w-full rounded-lg mb-8"
            />
            <div className="grid grid-cols-3 gap-6">
              <div className="text-center">
                <h3 className="text-3xl font-bold text-blue-600 mb-2">24/7</h3>
                <p className="text-gray-600">Always Available</p>
              </div>
              <div className="text-center">
                <h3 className="text-3xl font-bold text-blue-600 mb-2">95%</h3>
                <p className="text-gray-600">Call Resolution Rate</p>
              </div>
              <div className="text-center">
                <h3 className="text-3xl font-bold text-blue-600 mb-2">60%</h3>
                <p className="text-gray-600">Cost Reduction</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Key Features */}
      <section className="py-16 px-4 bg-white">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            Powerful Features for Modern Businesses
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard
              icon={BrainCircuit}
              title="Natural Conversations"
              description="Advanced AI technology enables human-like conversations, understanding context and nuance for better customer interactions."
            />
            <FeatureCard
              icon={Calendar}
              title="Smart Scheduling"
              description="Seamlessly manage appointments, handle rescheduling, and sync with your preferred calendar system."
            />
            <FeatureCard
              icon={Clock}
              title="24/7 Availability"
              description="Never miss a call with round-the-clock service that handles inquiries at any time, any day."
            />
            <FeatureCard
              icon={Building2}
              title="Multi-Location Support"
              description="Manage calls for multiple business locations with customized responses and scheduling for each."
            />
            <FeatureCard
              icon={Users}
              title="Call Routing"
              description="Intelligently route calls to the right department or staff member based on customer needs."
            />
            <FeatureCard
              icon={MessageSquare}
              title="Multilingual Support"
              description="Communicate with customers in multiple languages, expanding your business reach."
            />
          </div>
        </div>
      </section>

      {/* Use Cases */}
      <section className="py-16 px-4">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            Real-World Applications
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <UseCaseCard
              title="Healthcare Practices"
              description="Handle patient appointment scheduling, reminders, and basic inquiries while maintaining HIPAA compliance."
              imageUrl="clinicReception.jpg"
            />
            <UseCaseCard
              title="Real Estate Agencies"
              description="Manage property inquiries, schedule viewings, and qualify leads 24/7 without missing opportunities."
              imageUrl="RealEstate.jpg"
            />
            <UseCaseCard
              title="Professional Services"
              description="Screen and schedule client consultations while providing immediate responses to common questions."
              imageUrl="professionalServices.jpg"
            />
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-20 px-4 bg-blue-600 text-white">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">
            Ready to Transform Your Business Communication?
          </h2>
          <p className="text-xl mb-8">
            Join thousands of businesses already using AI Phone Agent to streamline their operations.
          </p>
          <button className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-blue-50 transition-colors">
            Get Started Today
          </button>
        </div>
      </section>
    </div>
  );
};

export default AIPhoneAgentPage;