import React, { useState, useEffect, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { getEmailConfigs, updateEmailConfig, createEmailConfig, deleteEmailConfig, testEmailConfig} from '../services/api';
import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardDescription, 
  CardContent 
} from "./ui/card";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { useToast } from "./ui/use-toast";
import { Loader2, Mail, Edit, Trash2 } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";
import { Switch } from "./ui/switch";

const EmailConfigurationManager = () => {
  const { organizationId } = useContext(OrganizationContext);
  const { toast } = useToast();
  const [loading, setLoading] = useState(true);
  const [configs, setConfigs] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [formData, setFormData] = useState({
    host: '',
    port: '',
    username: '',
    password: '',
    fromEmail: '',
    fromName: '',
    useSsl: false,
    useStartTls: true,
  });

  useEffect(() => {
    if (organizationId) {
      fetchConfigurations();
    }
  }, [organizationId]);

  const fetchConfigurations = async () => {
    try {
      const data = await getEmailConfigs(organizationId);
      setConfigs(data);
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch email configurations"
      });
    } finally {
      setLoading(false);
    }
  };
  
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedConfig) {
        await updateEmailConfig(organizationId, selectedConfig.id, formData);
      } else {
        await createEmailConfig(organizationId, formData);
      }
  
      toast({
        title: "Success",
        description: `Email configuration ${selectedConfig ? 'updated' : 'created'} successfully`
      });
  
      setShowForm(false);
      fetchConfigurations();
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to save email configuration"
      });
    }
  };
  
  const handleDelete = async () => {
    try {
      await deleteEmailConfig(organizationId, selectedConfig.id);
      toast({
        title: "Success",
        description: "Email configuration deleted successfully"
      });
      setShowDeleteDialog(false);
      fetchConfigurations();
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to delete email configuration"
      });
    }
  };
  
  const testConnection = async (configId) => {
    try {
      await testEmailConfig(organizationId, configId);
      toast({
        title: "Success",
        description: "Email configuration test successful"
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Email configuration test failed"
      });
    }
  };
  const handleEdit = (config) => {
    setSelectedConfig(config);
    setFormData({
      host: config.host,
      port: config.port,
      username: config.username,
      password: '',  // Don't populate password for security
      fromEmail: config.fromEmail,
      fromName: config.fromName,
      useSsl: config.useSsl,
      useStartTls: config.useStartTls,
    });
    setShowForm(true);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-6 w-6 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container max-w-4xl mx-auto py-6">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Mail className="h-6 w-6" />
              Email Configurations
            </div>
            <Button onClick={() => {
              setSelectedConfig(null);
              setFormData({
                host: '',
                port: '',
                username: '',
                password: '',
                fromEmail: '',
                fromName: '',
                useSsl: false,
                useStartTls: true,
              });
              setShowForm(true);
            }}>
              Add Configuration
            </Button>
          </CardTitle>
          <CardDescription>
            Manage email configurations for your organization
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Host</TableHead>
                <TableHead>Port</TableHead>
                <TableHead>Username</TableHead>
                <TableHead>From Name</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {configs.map((config) => (
                <TableRow key={config.id}>
                  <TableCell>{config.host}</TableCell>
                  <TableCell>{config.port}</TableCell>
                  <TableCell>{config.username}</TableCell>
                  <TableCell>{config.fromName}</TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleEdit(config)}
                      >
                        <Edit className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => testConnection(config.id)}
                      >
                        Test
                      </Button>
                      <Button
                        variant="destructive"
                        size="sm"
                        onClick={() => {
                          setSelectedConfig(config);
                          setShowDeleteDialog(true);
                        }}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <Dialog open={showForm} onOpenChange={setShowForm}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {selectedConfig ? 'Edit' : 'Add'} Email Configuration
            </DialogTitle>
            <DialogDescription>
              Configure email settings for your organization
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleFormSubmit} className="space-y-4">
            <div>
              <Label htmlFor="host">SMTP Host</Label>
              <Input
                id="host"
                value={formData.host}
                onChange={(e) => setFormData({ ...formData, host: e.target.value })}
                required
              />
            </div>
            <div>
              <Label htmlFor="port">SMTP Port</Label>
              <Input
                id="port"
                type="number"
                value={formData.port}
                onChange={(e) => setFormData({ ...formData, port: e.target.value })}
                required
              />
            </div>
            <div>
              <Label htmlFor="username">Username</Label>
              <Input
                id="username"
                value={formData.username}
                onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                required
              />
            </div>
            <div>
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                value={formData.password}
                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                required={!selectedConfig}
                placeholder={selectedConfig ? "Leave blank to keep existing password" : ""}
              />
            </div>
            <div>
              <Label htmlFor="fromEmail">From Email</Label>
              <Input
                id="fromEmail"
                type="email"
                value={formData.fromEmail}
                onChange={(e) => setFormData({ ...formData, fromEmail: e.target.value })}
                required
              />
            </div>
            <div>
              <Label htmlFor="fromName">From Name</Label>
              <Input
                id="fromName"
                value={formData.fromName}
                onChange={(e) => setFormData({ ...formData, fromName: e.target.value })}
                required
              />
            </div>
            <div className="flex items-center gap-4">
              <div className="flex items-center space-x-2">
                <Switch
                  id="ssl"
                  checked={formData.useSsl}
                  onCheckedChange={(checked) => setFormData({ ...formData, useSsl: checked })}
                />
                <Label htmlFor="ssl">Use SSL</Label>
              </div>
              <div className="flex items-center space-x-2">
                <Switch
                  id="startTls"
                  checked={formData.useStartTls}
                  onCheckedChange={(checked) => setFormData({ ...formData, useStartTls: checked })}
                />
                <Label htmlFor="startTls">Use STARTTLS</Label>
              </div>
            </div>
            <div className="flex justify-end gap-2">
              <Button type="button" variant="outline" onClick={() => setShowForm(false)}>
                Cancel
              </Button>
              <Button type="submit">
                {selectedConfig ? 'Update' : 'Create'}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Email Configuration</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this email configuration? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete}>Delete</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default EmailConfigurationManager;