import React from 'react';
import { BenefitCard } from './BenefitCard';
import { benefits } from './benefits.data';

export function BenefitsList() {
  return (
    <div className="py-12">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold text-gray-900 mb-4">Benefits of AI Phone Agents</h2>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Transform your customer service operations with intelligent automation that saves time and money while improving satisfaction.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {benefits.map((benefit, index) => (
          <BenefitCard
            key={index}
            Icon={benefit.icon}
            title={benefit.title}
            description={benefit.description}
          />
        ))}
      </div>
    </div>
  );
}