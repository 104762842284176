import React, { useState, useEffect, useContext } from 'react';
import { getPaymentHistory, getUpcomingPayments } from '../services/api';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { Alert, AlertDescription } from "./ui/alert";
import { Badge } from "./ui/badge";
import { Loader2, CreditCard, Calendar } from "lucide-react";

const PaymentHistoryComponent = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [pastPayments, setPastPayments] = useState([]);
  const [upcomingPayments, setUpcomingPayments] = useState([]);
  const [upcomingPaymentsMessage, setUpcomingPaymentsMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        setLoading(true);
        const [history, upcoming] = await Promise.all([
          getPaymentHistory(organizationId),
          getUpcomingPayments(organizationId)
        ]);

        setPastPayments(history);

        if (Array.isArray(upcoming)) {
          setUpcomingPayments(upcoming);
          setUpcomingPaymentsMessage('');
        } else if (upcoming && upcoming.message) {
          setUpcomingPayments([]);
          setUpcomingPaymentsMessage(upcoming.message);
        }
      } catch (error) {
        setError('Failed to load payment data');
      } finally {
        setLoading(false);
      }
    };

    if (organizationId) {
      fetchPaymentData();
    }
  }, [organizationId]);

  const formatDate = (timestamp) => {
    if (!timestamp) return 'Date not available';
    
    try {
      const date = typeof timestamp === 'number' 
        ? new Date(timestamp * 1000) 
        : new Date(timestamp);

      if (isNaN(date.getTime())) {
        return 'Invalid date';
      }

      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).format(date);
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date error';
    }
  };

  const formatAmount = (amount) => {
    if (amount == null) return 'Amount not available';
    
    try {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(amount);
    } catch (error) {
      console.error('Error formatting amount:', error);
      return 'Amount error';
    }
  };

  const getStatusBadgeVariant = (status) => {
    switch (status?.toLowerCase()) {
      case 'succeeded':
        return 'success';
      case 'pending':
        return 'warning';
      case 'failed':
        return 'destructive';
      default:
        return 'secondary';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="container max-w-4xl mx-auto py-6 space-y-6">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <CreditCard className="w-6 h-6" />
            Past Payments
          </CardTitle>
        </CardHeader>
        <CardContent>
          {pastPayments.length === 0 ? (
            <p className="text-center text-muted-foreground py-4">
              No past payments found.
            </p>
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Amount</TableHead>
                  <TableHead>Date</TableHead>
                  <TableHead>Status</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {pastPayments.map(payment => (
                  <TableRow key={payment.id}>
                    <TableCell className="font-medium">
                      {formatAmount(payment.amount)}
                    </TableCell>
                    <TableCell>{formatDate(payment.paymentDate)}</TableCell>
                    <TableCell>
                      <Badge variant={getStatusBadgeVariant(payment.status)}>
                        {payment.status}
                      </Badge>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Calendar className="w-6 h-6" />
            Upcoming Payments
          </CardTitle>
        </CardHeader>
        <CardContent>
          {upcomingPaymentsMessage ? (
            <p className="text-center text-muted-foreground py-4">
              {upcomingPaymentsMessage}
            </p>
          ) : upcomingPayments.length > 0 ? (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Amount</TableHead>
                  <TableHead>Due Date</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {upcomingPayments.map((payment, index) => (
                  <TableRow key={index}>
                    <TableCell className="font-medium">
                      {formatAmount(payment.amount)}
                    </TableCell>
                    <TableCell>
                      {payment.dueDate ? formatDate(payment.dueDate) : 'Due date not set'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <p className="text-center text-muted-foreground py-4">
              No upcoming payments found.
            </p>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default PaymentHistoryComponent;
