import React, { useState, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { removeAuthToken, removeUser } from '../utils/auth';
import { Button } from "./ui/button";
import {
  Home,
  Building2,
  Users,
  Phone,
  FileText,
  CreditCard,
  History,
  Upload,
  Settings,
  Calendar,
  Bot,
  PhoneCall,
  PhoneOutgoing,
  LogOut,
  Menu,
  User,
  PhoneIncoming,
  Smartphone,
  Settings2Icon,
  LibraryBig,
  RefreshCcw,
  Webhook,
  ChartNoAxesCombined,
  MessageSquareCodeIcon,
  MessageSquareCode,
  MessagesSquare,
  BotMessageSquare,
  BookOpenCheck,
  LayoutTemplateIcon,
} from "lucide-react";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "./ui/sheet";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { NotificationBell } from './NotificationBell';
import FloatingChatbot from './FloatingChatbot';

// Sidebar links configuration
const sidebarLinks = [
  { path: '/dashboard', icon: ChartNoAxesCombined, label: 'Dashboard' },
  { path: '/organization', icon: Building2, label: 'Organization' },
  { path: '/assistant-knowledge-base', icon: LibraryBig , label: 'Assistant Knowledge Base' },
  { path: '/email-configuration', icon: LibraryBig , label: 'Email Configuration' },
  { path: '/contact-management', icon: Users, label: 'Contact Management' },
  { path: '/themed-call-summary', icon: FileText, label: 'Call Summary' },
  { path: '/themed-call-track', icon: Phone, label: 'Call Tracking' },
  { path: '/monthly-billing', icon: CreditCard, label: 'Monthly Billing' },
  { path: '/payment-history-component', icon: History, label: 'Payment History' },
  { path: '/upload-files', icon: Upload, label: 'Upload Files' },
  { path: '/subscriptions', icon: CreditCard, label: 'Subscriptions' },
  { path: '/appointments', icon: Calendar, label: 'Appointment Settings' },
  { path: '/email-templates', icon: LayoutTemplateIcon , label: 'Email Templates' },
  { path: '/crm-integration', icon: BookOpenCheck , label: 'CRM Integration' },
  { path: '/vapi-assistants', icon: Bot, label: 'Assistant List' },
  { path: '/vapi-outbound-calls', icon: PhoneOutgoing, label: 'Outbound Dial' },
  { path: '/vapi-phone-numbers', icon: PhoneIncoming, label: 'Phone Numbers' },
  { path: '/sms-conversations', icon: MessagesSquare , label: 'SMS Conversations' },
  { path: '/webhook-sync', icon: Webhook, label: 'Sync Webhook' }
];

const Layout = ({ children, isAdmin }) => {
  const { pathname } = useLocation();
  const shouldShowSidebar = pathname !== '/home' && pathname !== '/pricing';
  const { organizationId, setNavigationEnabled } = useContext(OrganizationContext);
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleLogout = () => {
    removeAuthToken();
    removeUser();
    navigate('/login');
  };

  const Sidebar = () => {
    const { navigationEnabled } = useContext(OrganizationContext);
    const location = useLocation();
    
    return (
      <div 
        className={`fixed left-0 top-16 bottom-0 transition-all duration-300 ease-in-out
          ${isCollapsed ? 'w-20' : 'w-64'} 
          bg-gradient-to-br from-gray-900/95 to-gray-800/95 backdrop-blur-lg 
          shadow-xl border-r border-gray-700/50 flex flex-col`}
      >
        {/* Scrollable Navigation */}
        <div className="flex-1 overflow-y-auto scrollbar-hide">
          <nav className="p-3 space-y-1">
            {sidebarLinks.map((link) => {
              const Icon = link.icon;
              const isActive = location.pathname === link.path;
              
              return (
                <Link
                  key={link.path}
                  to={link.path}
                  disabled={!navigationEnabled}
                  className={`flex items-center px-3 py-2 rounded-lg transition-all duration-200
                    ${isActive 
                      ? 'bg-indigo-600 text-white' 
                      : 'text-gray-300 hover:bg-gray-800 hover:text-white'
                    }
                    ${isCollapsed ? 'justify-center' : ''}`}
                  title={isCollapsed ? link.label : ''}
                >
                  <Icon className={`h-5 w-5 ${isCollapsed ? '' : 'mr-3'}`} />
                  {!isCollapsed && <span className="text-sm font-medium">{link.label}</span>}
                </Link>
              );
            })}
          </nav>
        </div>
      </div>
    );
  };

  const MobileSidebar = () => {
    const { navigationEnabled } = useContext(OrganizationContext);
    
    return (
      <div className="space-y-4 py-4">
        <div className="px-3 py-2">
          <div className="space-y-1">
            {sidebarLinks.map((link) => {
              const Icon = link.icon;
              return (
                <Link
                  key={link.path}
                  to={link.path}
                  className="flex items-center px-3 py-2 text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                  disabled={!navigationEnabled}
                >
                  <Icon className="h-5 w-5 mr-3" />
                  <span>{link.label}</span>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen">
      {/* Mobile Sheet */}
      {shouldShowSidebar && (
        <Sheet>
          <SheetTrigger asChild>
            <Button 
              variant="outline" 
              size="icon" 
              className="fixed top-4 left-4 z-50 lg:hidden"
            >
              <Menu className="h-6 w-6" />
            </Button>
          </SheetTrigger>
          <SheetContent side="left" className="w-64 p-0">
            <div className="pt-16">
              <MobileSidebar />
            </div>
          </SheetContent>
        </Sheet>
      )}

      {/* Header */}
      <header className="sticky top-0 z-40 border-b bg-white dark:bg-gray-800">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center pl-4">
            {shouldShowSidebar && (
              <Button 
                variant="ghost" 
                size="icon" 
                onClick={() => setIsCollapsed(!isCollapsed)}
                className="mr-4 hidden lg:flex"
              >
                <Menu className="h-6 w-6" />
              </Button>
            )}
            <div className="flex items-center">
              <img
                src="/krupa.jpg"
                alt="Krupa Logo"
                className="h-8 w-auto mr-2"
              />
              <Phone className="h-8 w-8 text-indigo-600" />
              <span className="ml-2 text-xl font-bold text-gray-900 dark:text-white">
                AI Phone Agent
              </span>
            </div>
          </div>
          <div className="flex items-center gap-4 pr-4">
            <NotificationBell />
            <Link to="/profile">
              <Button variant="ghost" size="icon">
                <User className="h-5 w-5" />
              </Button>
            </Link>
            <Button variant="ghost" size="icon" onClick={handleLogout}>
              <LogOut className="h-5 w-5" />
            </Button>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <div className="flex">
        {/* Desktop Sidebar */}
        {shouldShowSidebar && <Sidebar />}
    
        {/* Page Content */}
        <main 
          className={`flex-1 p-4 transition-all duration-300 ease-in-out ${
            shouldShowSidebar ? (isCollapsed ? 'ml-20' : 'ml-64') : ''
          }`}
        >
          {children}
        </main>
      </div>
      {/* <FloatingChatbot /> */}
    </div>
  );
};

// Add custom scrollbar styles
const style = document.createElement('style');
style.textContent = `
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;
document.head.appendChild(style);

export default Layout;