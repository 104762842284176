import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

export const Modal = ({
  show,
  onClose,
  children,
  className,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(show);

  const handleClose = () => {
    setIsVisible(false);
    onClose?.();
  };

  return (
    <div
      className={twMerge(
        'fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black/50 backdrop-blur-sm',
        isVisible ? 'visible opacity-100' : 'invisible opacity-0',
        className
      )}
      onClick={handleClose}
      {...props}
    >
      <div
        className="bg-white rounded-lg shadow-lg w-full max-w-md px-6 py-8"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export const ModalHeader = ({ children, className, ...props }) => {
  return (
    <div
      className={twMerge('flex items-center justify-between mb-4', className)}
      {...props}
    >
      {children}
    </div>
  );
};

export const ModalBody = ({ children, className, ...props }) => {
  return (
    <div className={twMerge('mb-4', className)} {...props}>
      {children}
    </div>
  );
};

export const ModalFooter = ({ children, className, ...props }) => {
  return (
    <div className={twMerge('flex justify-end', className)} {...props}>
      {children}
    </div>
  );
};