import React, { useState, useEffect, useContext } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { MessagesSquare, Search, Send } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { NotificationContext } from '../contexts/NotificationContext';
import { getSMSConversations, getSMSMessages, sendSMSMessage } from '../services/api';

const SMSConversationsPage = () => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  const { organizationId } = useContext(OrganizationContext);
  const { notifications } = useContext(NotificationContext);

  useEffect(() => {
    if (organizationId) {
      fetchConversations();
    }
  }, [organizationId]);

  useEffect(() => {
    // Update conversations when new SMS notifications arrive
    const smsNotifications = notifications.filter(n => n.type === 'smsConversation');
    if (smsNotifications.length > 0) {
      updateConversationsWithNotifications(smsNotifications);
    }
  }, [notifications]);

  const updateConversationsWithNotifications = (smsNotifications) => {
    setConversations(prevConversations => {
      const updatedConversations = [...prevConversations];
      smsNotifications.forEach(notification => {
        const conversationIndex = updatedConversations.findIndex(
          c => c.id === notification.conversationId
        );
        if (conversationIndex >= 0) {
          updatedConversations[conversationIndex] = {
            ...updatedConversations[conversationIndex],
            lastMessage: notification.message,
            lastMessageTime: notification.timestamp,
            isNew: true
          };
        }
      });
      return updatedConversations;
    });
  };

  const fetchConversations = async () => {
    try {
      setLoading(true);
      const data = await getSMSConversations(organizationId);
      setConversations(data.map(conv => ({
        ...conv,
        isNew: false
      })));
    } catch (error) {
      console.error('Error fetching conversations:', error);
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async () => {
    if (!newMessage.trim() || !selectedConversation) return;

    try {
      await sendSMSMessage({
        from: selectedConversation.systemPhoneNumber ,
        to: selectedConversation.phoneNumber,
        message: newMessage,
        conversationId: selectedConversation.id,
        organizationId
      });

      setNewMessage('');
      // Refresh conversations to show the new message
      fetchConversations();
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const filteredConversations = conversations.filter(conv => 
    conv.contactName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    conv.phoneNumber.includes(searchTerm)
  );

  return (
    <div className="p-6">
      <Card>
        <CardHeader>
          <CardTitle>SMS Conversations</CardTitle>
          <div className="relative">
            <Search className="absolute left-2 top-3 h-4 w-4 text-gray-400" />
            <Input
              placeholder="Search by name or phone number"
              className="pl-8"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </CardHeader>
        <CardContent>
          {loading ? (
            <div className="text-center py-4">Loading conversations...</div>
          ) : (
            <div className="space-y-4">
              {filteredConversations.map((conversation) => (
                <div
                  key={conversation.id}
                  className={`p-4 rounded-lg border ${
                    conversation.isNew ? 'bg-blue-50 border-blue-200' : ''
                  }`}
                  onClick={() => {
                    setSelectedConversation(conversation);
                    setDialogOpen(true);
                    if (conversation.isNew) {
                      setConversations(prevConvs =>
                        prevConvs.map(conv =>
                          conv.id === conversation.id ? { ...conv, isNew: false } : conv
                        )
                      );
                    }
                  }}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="font-medium">{conversation.contactName}</h3>
                      <p className="text-sm text-gray-500">{conversation.phoneNumber}</p>
                      <p className="text-sm mt-1">{conversation.lastMessage}</p>
                    </div>
                    <div className="text-right">
                      <p className="text-sm text-gray-500">
                        {new Date(conversation.lastMessageTime).toLocaleString()} 
                      </p>
                      <Button
                        variant="outline"
                        size="sm"
                        className="mt-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedConversation(conversation);
                          setDialogOpen(true);
                        }}
                      >
                        <MessagesSquare className="w-4 h-4 mr-2" />
                        View
                      </Button>
                    </div>
                  </div>
                  {conversation.isNew && (
                    <Alert className="mt-2">
                      <AlertDescription>New message received</AlertDescription>
                    </Alert>
                  )}
                </div>
              ))}
            </div>
          )}
        </CardContent>
      </Card>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>
              {selectedConversation?.contactName} - {selectedConversation?.phoneNumber}
            </DialogTitle>
          </DialogHeader>
          <ScrollArea className="h-[400px] w-full rounded-md border p-4">
            <div className="space-y-4">
            {selectedConversation?.messages?.map((message) => (
              <div
                key={message.id}
                className={`rounded-lg p-3 max-w-[80%] ${
                  message.direction === 'OUTBOUND'
                    ? 'ml-auto bg-blue-500 text-white'
                    : 'bg-gray-100'
                }`}
              >
                <p>{message.messageContent}</p>
                <div className="text-xs mt-1 opacity-70">
                  {new Date(message.timestamp).toLocaleString()}
                </div>
              </div>
            ))}
            </div>
          </ScrollArea>
          <div className="flex gap-2 mt-4">
            <Input
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
            />
            <Button onClick={sendMessage}>
              <Send className="w-4 h-4 mr-2" />
              Send
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SMSConversationsPage;