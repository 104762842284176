import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { resetPassword, requestPasswordReset } from '../../services/api';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState('request'); // 'request', 'verify', 'success'
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      console.warn('Execute recaptcha not yet available');
      return 'disabled';
    }
    
    try {
      const token = await executeRecaptcha('forgotPassword');
      return token;
    } catch (error) {
      console.error('reCAPTCHA error:', error);
      throw new Error('Failed to verify reCAPTCHA');
    }
  };

  const handleRequestReset = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const captchaToken = await handleReCaptchaVerify();
      await requestPasswordReset(email, captchaToken);
      setStep('verify');
    } catch (error) {
      setError(error.message || 'Failed to send reset code');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    
    if (!validatePassword(password)) {
      setError('Password must be at least 8 characters long and contain uppercase, lowercase, number, and special character');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      await resetPassword(email, verificationCode, password);
      setStep('success');
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      setError(error.message || 'Failed to reset password');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendCode = async () => {
    try {
      const captchaToken = await handleReCaptchaVerify();
      await requestPasswordReset(email, captchaToken);
      setError('New verification code sent');
    } catch (error) {
      setError('Failed to resend verification code');
    }
  };

  const PasswordStrengthIndicator = ({ password }) => {
    const requirements = [
      { label: 'At least 8 characters', valid: password.length >= 8 },
      { label: 'At least one uppercase letter', valid: /[A-Z]/.test(password) },
      { label: 'At least one lowercase letter', valid: /[a-z]/.test(password) },
      { label: 'At least one number', valid: /\d/.test(password) },
      { label: 'At least one special character (@$!%*?&)', valid: /[@$!%*?&]/.test(password) }
    ];
  
    return (
      <div className="mt-2">
        <p className="text-sm font-medium text-gray-700 mb-1">Password requirements:</p>
        <ul className="text-sm space-y-1">
          {requirements.map((requirement, index) => (
            <li 
              key={index}
              className={`flex items-center ${
                requirement.valid ? 'text-green-600' : 'text-gray-500'
              }`}
            >
              {requirement.valid ? '✓' : '○'} {requirement.label}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="flex justify-center items-center min-h-screen p-4">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle>
            {step === 'request' && 'Forgot Password'}
            {step === 'verify' && 'Reset Password'}
            {step === 'success' && 'Password Reset Successful'}
          </CardTitle>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {step === 'request' && (
            <form onSubmit={handleRequestReset}>
              <div className="space-y-4">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium">
                    Email address
                  </label>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <Button type="submit" className="w-full" disabled={isLoading}>
                  {isLoading ? 'Sending...' : 'Send Reset Code'}
                </Button>
              </div>
            </form>
          )}

          {step === 'verify' && (
            <form onSubmit={handleResetPassword}>
              <div className="space-y-4">
                <p className="text-sm text-gray-600">
                  Please enter the verification code sent to {email}
                </p>

                <div>
                  <label htmlFor="code" className="block text-sm font-medium">
                    Verification Code
                  </label>
                  <Input
                    id="code"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="newPassword" className="block text-sm font-medium">
                    New Password
                  </label>
                  <Input
                    id="newPassword"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <PasswordStrengthIndicator password={password} />
                </div>

                <Button type="submit" className="w-full" disabled={isLoading}>
                  {isLoading ? 'Resetting...' : 'Reset Password'}
                </Button>

                <Button
                  type="button"
                  variant="outline"
                  className="w-full"
                  onClick={handleResendCode}
                >
                  Resend Code
                </Button>
              </div>
            </form>
          )}

          {step === 'success' && (
            <div className="text-center">
              <p className="text-green-600 mb-4">
                Password has been successfully reset. Redirecting to login...
              </p>
            </div>
          )}
        </CardContent>
        <CardFooter className="text-center">
          <a href="/login" className="text-primary hover:underline">
            Back to Login
          </a>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ForgotPassword;