import React from 'react';

export function Cookies() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold mb-8">Cookie Policy</h1>
      
      <div className="space-y-8">
        <section>
          <h2 className="text-2xl font-semibold mb-4">1. What Are Cookies</h2>
          <p className="text-gray-600 mb-4">
            Cookies are small text files stored on your device that help us provide and improve our services.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">2. Types of Cookies We Use</h2>
          <div className="space-y-4">
            <div>
              <h3 className="text-xl font-medium mb-2">Essential Cookies</h3>
              <p className="text-gray-600">Required for basic site functionality and security.</p>
            </div>
            <div>
              <h3 className="text-xl font-medium mb-2">Analytics Cookies</h3>
              <p className="text-gray-600">Help us understand how visitors interact with our website.</p>
            </div>
            <div>
              <h3 className="text-xl font-medium mb-2">Marketing Cookies</h3>
              <p className="text-gray-600">Used to deliver relevant advertisements and track campaign performance.</p>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">3. Managing Cookies</h2>
          <p className="text-gray-600 mb-4">
            You can control cookies through your browser settings and our cookie consent preferences.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">4. Updates to This Policy</h2>
          <p className="text-gray-600">
            We may update this Cookie Policy to reflect changes in our practices or for legal compliance.
          </p>
        </section>
      </div>
    </div>
  );
}