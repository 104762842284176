import React, { useState, useEffect, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { getMonthlyBillings, getCurrentMonthlyBilling, getMonthlyBillingSummary } from '../services/api';
import { format } from 'date-fns';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { Alert, AlertDescription } from "./ui/alert";
import { Loader2, Calendar } from "lucide-react";

const formatCurrency = (amount) => {
  if (!amount) return '£0.00';
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

const formatDate = (dateString) => {
  if (!dateString) return '';
  return format(new Date(dateString), 'PPP');
};

const MonthlyBilling = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [billings, setBillings] = useState([]);
  const [currentBilling, setCurrentBilling] = useState(null);
  const [billingSummary, setBillingSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [dates, setDates] = useState({
    startDate: '',
    endDate: ''
  });

  useEffect(() => {
    if (organizationId) {
      fetchBillings();
      fetchCurrentBilling();
    }
  }, [organizationId, currentPage]);

  const calculateTotalTopUps = (billing) => {
    if (!billing.payments) return 0;
    return billing.payments
      .filter(payment => payment.paymentType === 'top-up')
      .reduce((sum, payment) => sum + (payment.amount || 0), 0);
  };

  const calculateTopUpCount = (billing) => {
    if (!billing.payments) return 0;
    return billing.payments
      .filter(payment => payment.paymentType === 'top-up')
      .length;
  };

  const fetchBillings = async () => {
    setLoading(true);
    try {
      const response = await getMonthlyBillings(organizationId, currentPage);
      if (response && response.content) {
        setBillings(response.content);
        setTotalPages(response.totalPages || 1);
      }
      setError(null);
    } catch (err) {
      setError('Failed to fetch billing information');
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrentBilling = async () => {
    try {
      const current = await getCurrentMonthlyBilling(organizationId);
      setCurrentBilling(current);
    } catch (err) {
      console.error('Error fetching current billing:', err);
    }
  };

  const fetchBillingSummary = async () => {
    if (!dates.startDate || !dates.endDate) return;
    try {
      const summary = await getMonthlyBillingSummary(
        organizationId, 
        dates.startDate, 
        dates.endDate
      );
      setBillingSummary(summary);
    } catch (err) {
      console.error('Error fetching billing summary:', err);
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDates(prev => ({ ...prev, [name]: value }));
  };

  const handleFilter = (e) => {
    e.preventDefault();
    setCurrentPage(0);
    fetchBillings();
    fetchBillingSummary();
  };

  const CurrentBillingCard = () => {
    if (!currentBilling) return null;

    const totalTopUps = calculateTotalTopUps(currentBilling);
    const totalAvailable = (currentBilling.coveredAmount || 0) + 
      totalTopUps + (currentBilling.carryOverAmount || 0);

    const topUpBreakdown = currentBilling.payments
      ?.filter(payment => payment.paymentType === 'top-up')
      .map((payment, index) => (
        <div key={payment.id || index} className="flex justify-between text-sm">
          <span>Top-up {index + 1}:</span>
          <span>{formatCurrency(payment.amount)}</span>
          <span className="text-muted-foreground">
            {formatDate(payment.paymentDate)}
          </span>
        </div>
      )) || [];

    return (
      <Card>
        <CardHeader>
          <CardTitle>Current Billing Period</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <p className="text-sm text-muted-foreground">Period</p>
              <p>{formatDate(currentBilling.billingStartDate)} to</p>
              <p>{formatDate(currentBilling.billingEndDate)}</p>
            </div>
            <div className="text-right">
              <p className="text-sm text-muted-foreground">Usage</p>
              <p className="text-lg font-semibold">
                {formatCurrency(currentBilling.spentAmount)} / 
                {formatCurrency(totalAvailable)}
              </p>
            </div>
          </div>

          <div className="space-y-2">
            <h4 className="text-sm font-semibold">Breakdown</h4>
            <div className="space-y-1">
              <div className="flex justify-between text-sm">
                <span>Base Covered Amount:</span>
                <span>{formatCurrency(currentBilling.coveredAmount)}</span>
              </div>
              {topUpBreakdown}
              <div className="flex justify-between text-sm font-medium">
                <span>Total Top-ups:</span>
                <span>{formatCurrency(totalTopUps)}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span>Carry Over:</span>
                <span>{formatCurrency(currentBilling.carryOverAmount)}</span>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };

  const FilterSection = () => (
    <form onSubmit={handleFilter} className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="space-y-2">
          <label htmlFor="startDate" className="text-sm font-medium">
            Start Date
          </label>
          <Input
            id="startDate"
            type="date"
            name="startDate"
            value={dates.startDate}
            onChange={handleDateChange}
          />
        </div>
        <div className="space-y-2">
          <label htmlFor="endDate" className="text-sm font-medium">
            End Date
          </label>
          <Input
            id="endDate"
            type="date"
            name="endDate"
            value={dates.endDate}
            onChange={handleDateChange}
          />
        </div>
        <div className="flex items-end">
          <Button type="submit" className="w-full">
            <Calendar className="w-4 h-4 mr-2" />
            Filter
          </Button>
        </div>
      </div>
    </form>
  );

  if (loading && billings.length === 0) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container max-w-7xl mx-auto py-6 space-y-6">
      <h1 className="text-3xl font-bold">Monthly Billing</h1>

      {error && (
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <CurrentBillingCard />
      <FilterSection />

      {billingSummary && (
        <Card>
          <CardHeader>
            <CardTitle>Summary for Selected Period</CardTitle>
          </CardHeader>
          <CardContent className="space-y-2">
            <div className="grid grid-cols-3 gap-4">
              <div>
                <p className="text-sm text-muted-foreground">Total Spent</p>
                <p className="text-lg font-semibold">
                  {formatCurrency(billingSummary.totalSpent)}
                </p>
              </div>
              <div>
                <p className="text-sm text-muted-foreground">Total Top-Up</p>
                <p className="text-lg font-semibold">
                  {formatCurrency(billingSummary.totalTopUp)}
                </p>
              </div>
              <div>
                <p className="text-sm text-muted-foreground">Number of Top-Ups</p>
                <p className="text-lg font-semibold">
                  {billingSummary.totalTopUpCount}
                </p>
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      <Card>
        <CardHeader>
          <CardTitle>Billing History</CardTitle>
        </CardHeader>
        <CardContent>
          {billings.length === 0 ? (
            <p className="text-center text-muted-foreground py-4">
              No billing records available for the selected period.
            </p>
          ) : (
            <>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Billing Period</TableHead>
                    <TableHead className="text-right">Covered Amount</TableHead>
                    <TableHead className="text-right">Spent Amount</TableHead>
                    <TableHead className="text-center">Top-Up Count</TableHead>
                    <TableHead className="text-right">Top-Up Total</TableHead>
                    <TableHead className="text-right">Carry Over Amount</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {billings.map((billing) => {
                    const totalTopUps = calculateTotalTopUps(billing);
                    const topUpCount = calculateTopUpCount(billing);
                    return (
                      <TableRow key={billing.id}>
                        <TableCell>
                          {formatDate(billing.billingStartDate)} to{' '}
                          {formatDate(billing.billingEndDate)}
                        </TableCell>
                        <TableCell className="text-right">
                          {formatCurrency(billing.coveredAmount)}
                        </TableCell>
                        <TableCell className="text-right">
                          {formatCurrency(billing.spentAmount)}
                        </TableCell>
                        <TableCell className="text-center">
                          {topUpCount}
                        </TableCell>
                        <TableCell className="text-right">
                          {formatCurrency(totalTopUps)}
                        </TableCell>
                        <TableCell className="text-right">
                        {formatCurrency(billing.carryOverAmount)}
                      </TableCell>
                    </TableRow>
                  );
                })}                
                </TableBody>
              </Table>

              <div className="flex justify-center mt-4 space-x-2">
                {[...Array(totalPages)].map((_, i) => (
                  <Button
                    key={i}
                    variant={currentPage === i ? "default" : "outline"}
                    size="sm"
                    onClick={() => setCurrentPage(i)}
                  >
                    {i + 1}
                  </Button>
                ))}
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default MonthlyBilling;