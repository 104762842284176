import React, { useState, useEffect, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { uploadFile, getUploadedFiles } from '../services/api';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Spinner } from './ui/spinner';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { Table, TableHead, TableBody, TableRow, TableCell } from './ui/table';
import { Badge } from './ui/badge';

const UploadFiles = () => {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  
  const { organizationId } = useContext(OrganizationContext);

  useEffect(() => {
    if (organizationId) {
      fetchFiles();
    }
  }, [organizationId]);

  const fetchFiles = async () => {
    try {
      const data = await getUploadedFiles(organizationId);
      setFiles(data);
      setError(null);
    } catch (err) {
      setError(err.message);
      setFiles([]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) return;

    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
        const maxSize = 10 * 1024 * 1024; // 10MB
        if (selectedFile.size > maxSize) {
            throw new Error('File size exceeds 10MB limit');
        }

        console.log('Uploading file:', selectedFile.name, 'Size:', selectedFile.size);
        
        await uploadFile(selectedFile, organizationId);
        
        setSuccess(true);
        setSelectedFile(null);
        event.target.reset();
        await fetchFiles();
    } catch (err) {
        console.error('Upload error details:', err);
        setError(err.message || 'Failed to upload file');
    } finally {
        setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        const validTypes = [
            'text/markdown',
            'application/pdf',
            'text/plain',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];
        
        if (!validTypes.includes(file.type)) {
            setError('Invalid file type. Please upload Markdown, PDF, plain text, or Microsoft Word files only.');
            setSelectedFile(null);
            event.target.value = null;
            return;
        }

        const maxSize = 10 * 1024 * 1024; // 10MB in bytes
        if (file.size > maxSize) {
            setError('File size exceeds 10MB limit');
            setSelectedFile(null);
            event.target.value = null;
            return;
        }
        
        setSelectedFile(file);
        setError(null);
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const renderFileTable = () => (
    <Table className="w-full">
      <TableHead>
        <TableRow>
          <TableCell>File Name</TableCell>
          <TableCell>Original Name</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Size</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Uploaded At</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {files.map(file => (
          <TableRow key={file.id}>
            <TableCell>
              {file.url ? (
                <a href={file.url} target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">
                  {file.name}
                </a>
              ) : (
                file.name
              )}
            </TableCell>
            <TableCell>{file.originalName}</TableCell>
            <TableCell>{file.mimetype}</TableCell>
            <TableCell>{formatFileSize(file.bytes)}</TableCell>
            <TableCell>
              <Badge variant={file.status === 'indexed' ? 'success' : 'warning'}>
                {file.status}
              </Badge>
            </TableCell>
            <TableCell>{new Date(file.createdAt).toLocaleString()}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <div className="container mx-auto py-8">
      <Card>
        <CardHeader>
          <CardTitle>Upload Files to Knowledge Base</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="mb-6">
            <div className="mb-4">
              <label htmlFor="file-input" className="block text-sm font-medium text-gray-700 mb-2">
                Select File
              </label>
              <div className="flex items-center">
                <input
                  id="file-input"
                  type="file"
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                  onChange={handleFileChange}
                  accept=".md,.pdf,.txt,.doc,.docx"
                />
                <span className="ml-3 text-sm text-gray-500">
                  Supported formats: Markdown, PDF, plain text, or Microsoft Word (.doc and .docx)
                </span>
              </div>
            </div>
            {error && (
              <Alert variant="danger" className="mb-4">
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            {success && (
              <Alert variant="success" className="mb-4">
                <AlertTitle>Success</AlertTitle>
                <AlertDescription>File uploaded successfully!</AlertDescription>
              </Alert>
            )}
            <Button type="submit" disabled={!selectedFile || loading}>
              {loading ? (
                <>
                  <Spinner size="sm" className="mr-2" />
                  Uploading...
                </>
              ) : (
                'Upload File'
              )}
            </Button>
          </form>

          <h2 className="text-xl font-bold mb-4">Uploaded Files</h2>
          {files.length === 0 ? (
            <Alert variant="info">No files uploaded yet.</Alert>
          ) : (
            renderFileTable()
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default UploadFiles;