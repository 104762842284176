import React, { useEffect, useState } from 'react';
import { Card, Container, Alert, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import AppointmentSetterOld from './AppointmentSetterOld.js'

const BookingWidget = ({ containerId, testMode }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [organizationId, setOrganizationId] = useState(
        testMode ? testMode.organizationId : window.ORGANIZATION_ID
    );

    useEffect(() => {
        // Validate organization ID
        if (!organizationId) {
            setError('Organization ID not provided');
            setLoading(false);
            return;
        }

        // Set up height adjustment for iframe
        const sendHeightToParent = () => {
            const height = document.documentElement.scrollHeight;
            if (window.parent !== window) {
                window.parent.postMessage(
                    { 
                        type: 'frameHeight', 
                        height,
                        containerId 
                    }, 
                    '*'
                );
            }
        };

        // Initial height update
        sendHeightToParent();

        // Set up observer for height changes
        const resizeObserver = new ResizeObserver(sendHeightToParent);
        resizeObserver.observe(document.body);

        // Validate organization on mount
        const validateOrganization = async () => {
            try {
                const response = await fetch(`/api/widget/validate/${organizationId}`);
                if (!response.ok) {
                    throw new Error('Invalid organization');
                }
                setLoading(false);
            } catch (err) {
                setError('Unable to load booking widget. Please check your configuration.');
                setLoading(false);
            }
        };

        if (!testMode) {
            validateOrganization();
        } else {
            setLoading(false);
        }

        // Cleanup
        return () => {
            resizeObserver.disconnect();
        };
    }, [organizationId, containerId, testMode]);

    if (loading) {
        return (
            <Container className="p-3">
                <Card className="shadow-sm">
                    <Card.Body className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Card.Body>
                </Card>
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="p-3">
                <Alert variant="danger">
                    {error}
                </Alert>
            </Container>
        );
    }

    return (
        <Container className="p-3" data-testid="booking-widget">
            <Card className="shadow-sm">
                <Card.Body>
                    <AppointmentSetterOld 
                        organizationId={organizationId}
                        onError={(err) => setError(err.message)}
                    />
                </Card.Body>
            </Card>
        </Container>
    );
};

BookingWidget.propTypes = {
    containerId: PropTypes.string,
    testMode: PropTypes.shape({
        organizationId: PropTypes.string.isRequired
    })
};

export default BookingWidget;