import React, { useState, useEffect, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { getSubscriptions, deleteSubscription, createSubscription, cancelSubscription } from '../services/api';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import {
  Table,
  TableHeader,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '../components/ui/table';
import { Button } from '../components/ui/button';
import { Alert, AlertDescription } from '../components/ui/alert';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';

const Subscription = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [newSubscription, setNewSubscription] = useState({ plan: '', status: '', startDate: '' });

  useEffect(() => {
    fetchSubscriptions();
  }, [organizationId]);

  const fetchSubscriptions = async () => {
    try {
      if (organizationId) {
        setLoading(true);
        const fetchedSubscriptions = await getSubscriptions(organizationId);
        setSubscriptions(fetchedSubscriptions);
        setLoading(false);
      }
    } catch (error) {
      console.error('Failed to fetch subscriptions:', error);
      setError('Failed to fetch subscriptions. Please try again later.');
      setLoading(false);
    }
  };

  const handleCancel = async (id) => {
    if (window.confirm('Are you sure you want to cancel this subscription? Your subscription will remain active until the end of the current billing period.')) {
      const reason = prompt('Please provide a reason for cancellation:');
      if (reason !== null) {  // User didn't click Cancel
        await cancelSubscription(id, reason);
        fetchSubscriptions();
      }
    }
  };
  
  const handleCreateSubscription = async (e) => {
    e.preventDefault();
    try {
      await createSubscription(newSubscription);
      setShowDialog(false);
      setNewSubscription({ plan: '', status: '', startDate: '' });
      fetchSubscriptions();
    } catch (error) {
      console.error('Failed to create subscription:', error);
      setError('Failed to create subscription. Please try again.');
    }
  };

   // Modify the TableRow to show cancellation status
   const getStatusDisplay = (subscription) => {
    if (subscription.cancelledAtPeriodEnd) {
      return 'Cancelling at period end';
    }
    return subscription.isActive ? 'Active' : 'Inactive';
  };


  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="text-lg text-muted-foreground">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive" className="max-w-2xl mx-auto my-8">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="container mx-auto py-8 space-y-6">
      {/* { <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Subscriptions</h1>
        <Button onClick={() => setShowDialog(true)}>
          Add New Subscription
        </Button>
      </div> } */}

      <Card>
        <CardContent className="p-0">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Plan</TableHead>
                <TableHead>Active</TableHead>
                <TableHead>Start Date</TableHead>
                <TableHead>Subscription Amount</TableHead>
                <TableHead>Call Amount</TableHead>
                <TableHead>Frequency</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {subscriptions.map(subscription => (
                <TableRow key={subscription.id}>
                  <TableCell className="font-medium">{subscription.subscriptionPlan}</TableCell>
                  <TableCell>{subscription.isActive ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{subscription.startDate}</TableCell>
                  <TableCell>${subscription.subscriptionAmount}</TableCell>
                  <TableCell>{subscription.coveredCallAmount}</TableCell>
                  <TableCell>{subscription.subscriptionFreq}</TableCell>
                  <TableCell className="text-right">
                  <Button 
                      variant="destructive" 
                      size="sm" 
                      onClick={() => handleCancel(subscription.id)}
                      disabled={subscription.cancelledAtPeriodEnd}
                    >
                      {subscription.cancelledAtPeriodEnd ? 'Cancelling' : 'Cancel'}
                  </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <Dialog open={showDialog} onOpenChange={setShowDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add New Subscription</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleCreateSubscription} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="plan">Plan</Label>
              <Select
                value={newSubscription.plan}
                onValueChange={(value) => setNewSubscription({ ...newSubscription, plan: value })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a plan" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="basic">Basic</SelectItem>
                  <SelectItem value="pro">Pro</SelectItem>
                  <SelectItem value="enterprise">Enterprise</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="status">Status</Label>
              <Select
                value={newSubscription.status}
                onValueChange={(value) => setNewSubscription({ ...newSubscription, status: value })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="active">Active</SelectItem>
                  <SelectItem value="pending">Pending</SelectItem>
                  <SelectItem value="cancelled">Cancelled</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="startDate">Start Date</Label>
              <Input
                id="startDate"
                type="date"
                value={newSubscription.startDate}
                onChange={(e) => setNewSubscription({ 
                  ...newSubscription, 
                  startDate: e.target.value 
                })}
              />
            </div>

            <DialogFooter>
              <Button type="submit">Create Subscription</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Subscription;