import React, { useState, useEffect } from 'react';
import { getSchedules, toggleSchedule, startSync, stopSync, getSyncRuns, updateSchedule } from '../services/webhookSyncApi';
import { format } from 'date-fns';
import {
  Play,
  Square,
  Clock,
  Calendar,
  AlertCircle,
  CheckCircle2,
  XCircle,
  RotateCcw,
  Settings,
  List
} from 'lucide-react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WebhookSyncMonitor = () => {
  const [schedules, setSchedules] = useState([]);
  const [runs, setRuns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRun, setSelectedRun] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Schedule states
  const [fullSyncSchedule, setFullSyncSchedule] = useState({
    id: null,
    syncType: 'FULL_SYNC',
    cronExpression: '0 0 * * *',
    enabled: false,
    nextRunTime: null
  });
  
  const [incrementalSyncSchedule, setIncrementalSyncSchedule] = useState({
    id: null, 
    syncType: 'INCREMENTAL_SYNC',
    cronExpression: '*/15 * * * *',
    enabled: false,
    nextRunTime: null
  });

  // Running states
  const [isFullSyncRunning, setIsFullSyncRunning] = useState(false);
  const [isIncrementalSyncRunning, setIsIncrementalSyncRunning] = useState(false);

  useEffect(() => {
    fetchSchedules();
    fetchRuns();
  }, [currentPage]);

  const fetchRuns = async () => {
    try {
      const response = await getSyncRuns({ page: currentPage - 1, size: 10 });
      setRuns(response.content);
      setTotalPages(response.totalPages);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching runs:', error);
      toast.error('Failed to fetch sync runs');
      setLoading(false);
    }
  };

  const fetchSchedules = async () => {
    try {
      const schedules = await getSchedules();
      const fullSync = schedules.find(s => s.syncType === 'FULL_SYNC');
      const incrementalSync = schedules.find(s => s.syncType === 'INCREMENTAL_SYNC');
      
      if (fullSync) setFullSyncSchedule(fullSync);
      if (incrementalSync) setIncrementalSyncSchedule(incrementalSync);
      
      setIsFullSyncRunning(runs.some(r => r.schedule?.id === fullSync?.id && r.status === 'RUNNING'));
      setIsIncrementalSyncRunning(runs.some(r => r.schedule?.id === incrementalSync?.id && r.status === 'RUNNING'));
    } catch (error) {
      console.error('Error fetching schedules:', error);
      toast.error('Failed to fetch schedules');
    }
  };

  useEffect(() => {
    fetchSchedules();
    fetchRuns();
    setLoading(false);
  }, [currentPage]);

  
  const handleStartSync = async (syncType) => {
    try {
      const schedule = syncType === 'FULL_SYNC' ? fullSyncSchedule : incrementalSyncSchedule;
      await startSync(schedule.id);
      
      if (syncType === 'FULL_SYNC') {
        setIsFullSyncRunning(true);
      } else {
        setIsIncrementalSyncRunning(true);
      }
      
      await fetchRuns();
      toast.success(`${syncType} started successfully`);
    } catch (error) {
      console.error('Error starting sync:', error);
      toast.error(`Failed to start ${syncType}`);
    }
  };

  const handleStopSync = async (syncType) => {
    try {
      const schedule = syncType === 'FULL_SYNC' ? fullSyncSchedule : incrementalSyncSchedule;
      const runningProcess = runs.find(r => r.schedule?.id === schedule?.id && r.status === 'RUNNING');
      
      if (runningProcess) {
        await stopSync(runningProcess.id);
        
        if (syncType === 'FULL_SYNC') {
          setIsFullSyncRunning(false);
        } else {
          setIsIncrementalSyncRunning(false);
        }
        
        await fetchRuns();
        toast.success(`${syncType} stopped successfully`);
      }
    } catch (error) {
      console.error('Error stopping sync:', error);
      toast.error(`Failed to stop ${syncType}`);
    }
  };

  const handleScheduleChange = async (syncType, cronExpression) => {
    try {
      const schedule = syncType === 'FULL_SYNC' ? fullSyncSchedule : incrementalSyncSchedule;
      const updatedSchedule = await updateSchedule(schedule.id, { ...schedule, cronExpression });
      
      if (syncType === 'FULL_SYNC') {
        setFullSyncSchedule(updatedSchedule);
      } else {
        setIncrementalSyncSchedule(updatedSchedule);
      }
      
      toast.success('Schedule updated successfully');
    } catch (error) {
      console.error('Error updating schedule:', error);
      toast.error('Failed to update schedule');
    }
  };

  const handleScheduleToggle = async (syncType, enabled) => {
    try {
      const schedule = syncType === 'FULL_SYNC' ? fullSyncSchedule : incrementalSyncSchedule;
      const updatedSchedule = await toggleSchedule(schedule.id);
      
      if (syncType === 'FULL_SYNC') {
        setFullSyncSchedule(prev => ({ ...prev, enabled }));
      } else {
        setIncrementalSyncSchedule(prev => ({ ...prev, enabled }));
      }
      
      toast.success(`Schedule ${enabled ? 'enabled' : 'disabled'} successfully`);
    } catch (error) {
      console.error('Error toggling schedule:', error);
      toast.error('Failed to toggle schedule');
    }
  };

  const Toggle = ({ enabled, onChange }) => (
    <button
      onClick={() => onChange(!enabled)}
      className={`
        relative inline-flex h-6 w-11 items-center rounded-full
        ${enabled ? 'bg-blue-600' : 'bg-gray-700'}
        transition-colors duration-200 ease-in-out
      `}
    >
      <span className="sr-only">Enable schedule</span>
      <span
        className={`
          inline-block h-4 w-4 transform rounded-full bg-white
          transition-transform duration-200 ease-in-out
          ${enabled ? 'translate-x-6' : 'translate-x-1'}
        `}
      />
    </button>
  );

  return (
    <div className="bg-gray-900 rounded-xl shadow-lg">
       <ToastContainer position="top-right" theme="dark" />  
      <div className="p-6 border-b border-gray-800">
        <h2 className="text-xl font-semibold text-gray-100 flex items-center gap-2">
          <RotateCcw className="h-5 w-5 text-blue-400" />
          Webhook Sync Monitor
        </h2>
      </div>

      <div className="p-6 border-b border-gray-800">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Full Sync Controls */}
          <div className="bg-gray-800 rounded-lg p-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-100">Full Sync</h3>
              <div className="flex gap-2">
                <button
                  onClick={() => handleStartSync('FULL_SYNC')}
                  className="p-2 rounded-lg bg-green-500/20 text-green-400 hover:bg-green-500/30"
                  disabled={isFullSyncRunning}
                >
                  <Play className="h-4 w-4" />
                </button>
                <button
                  onClick={() => handleStopSync('FULL_SYNC')}
                  className="p-2 rounded-lg bg-red-500/20 text-red-400 hover:bg-red-500/30"
                  disabled={!isFullSyncRunning}
                >
                  <Square className="h-4 w-4" />
                </button>
              </div>
            </div>
            {fullSyncSchedule && (
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-400">Schedule:</span>
                  <input
                    type="text"
                    value={fullSyncSchedule.cronExpression}
                    onChange={(e) => handleScheduleChange('FULL_SYNC', e.target.value)}
                    placeholder="Cron Expression"
                    className="px-2 py-1 bg-gray-700 rounded text-gray-100 text-sm"
                  />
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-400">Enabled:</span>
                  <Toggle 
                    checked={fullSyncSchedule.enabled}
                    onChange={(checked) => handleScheduleToggle('FULL_SYNC', checked)}
                    className={`${fullSyncSchedule.enabled ? 'bg-blue-600' : 'bg-gray-700'}
                      relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span className="sr-only">Enable schedule</span>
                    <span
                      className={`${fullSyncSchedule.enabled ? 'translate-x-6' : 'translate-x-1'}
                        inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                  </Toggle>
                </div>
                <div className="text-sm text-gray-400">
                  Next run: {fullSyncSchedule.nextRunTime ? format(new Date(fullSyncSchedule.nextRunTime), 'Pp') : 'Not scheduled'}
                </div>
              </div>
            )}
          </div>

          {/* Incremental Sync Controls */}
          <div className="bg-gray-800 rounded-lg p-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-100">Incremental Sync</h3>
              <div className="flex gap-2">
                <button
                  onClick={() => handleStartSync('INCREMENTAL_SYNC')}
                  className="p-2 rounded-lg bg-green-500/20 text-green-400 hover:bg-green-500/30"
                  disabled={isIncrementalSyncRunning}
                >
                  <Play className="h-4 w-4" />
                </button>
                <button
                  onClick={() => handleStopSync('INCREMENTAL_SYNC')}
                  className="p-2 rounded-lg bg-red-500/20 text-red-400 hover:bg-red-500/30"
                  disabled={!isIncrementalSyncRunning}
                >
                  <Square className="h-4 w-4" />
                </button>
              </div>
            </div>
            {incrementalSyncSchedule && (
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-400">Schedule:</span>
                  <input
                    type="text"
                    value={incrementalSyncSchedule.cronExpression}
                    onChange={(e) => handleScheduleChange('INCREMENTAL_SYNC', e.target.value)}
                    placeholder="Cron Expression"
                    className="px-2 py-1 bg-gray-700 rounded text-gray-100 text-sm"
                  />
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-400">Enabled:</span>
                  <Toggle
                    checked={incrementalSyncSchedule.enabled}
                    onChange={(checked) => handleScheduleToggle('INCREMENTAL_SYNC', checked)}
                    className={`${incrementalSyncSchedule.enabled ? 'bg-blue-600' : 'bg-gray-700'}
                      relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span className="sr-only">Enable schedule</span>
                    <span
                      className={`${incrementalSyncSchedule.enabled ? 'translate-x-6' : 'translate-x-1'}
                        inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                  </Toggle>
                </div>
                <div className="text-sm text-gray-400">
                  Next run: {incrementalSyncSchedule.nextRunTime ? format(new Date(incrementalSyncSchedule.nextRunTime), 'Pp') : 'Not scheduled'}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-800">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Start Time</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Duration</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Progress</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-400 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-800">
            {loading ? (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-gray-400">
                  Loading runs...
                </td>
              </tr>
            ) : runs.length === 0 ? (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-gray-400">
                  No sync runs available
                </td>
              </tr>
            ) : (
              runs.map((run) => (
                <tr key={run.id} className="hover:bg-gray-800/50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-1 text-gray-100">
                      <Calendar className="h-4 w-4 text-gray-400" />
                      <span>{format(new Date(run.startTime), 'Pp')}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-1 text-gray-100">
                      <Clock className="h-4 w-4 text-gray-400" />
                      <span>
                        {run.endTime 
                          ? `${Math.round((new Date(run.endTime) - new Date(run.startTime)) / 1000)}s`
                          : 'Running...'}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-2">
                      {run.status === 'COMPLETED' ? (
                        <CheckCircle2 className="h-4 w-4 text-green-400" />
                      ) : run.status === 'FAILED' ? (
                        <XCircle className="h-4 w-4 text-red-400" />
                      ) : (
                        <AlertCircle className="h-4 w-4 text-yellow-400" />
                      )}
                      <span className={`
                        ${run.status === 'COMPLETED' ? 'text-green-400' : ''}
                        ${run.status === 'FAILED' ? 'text-red-400' : ''}
                        ${run.status === 'RUNNING' ? 'text-yellow-400' : ''}
                      `}>
                        {run.status}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-2">
                      <div className="flex-1 bg-gray-700 rounded-full h-2">
                        <div
                          className="bg-blue-500 h-2 rounded-full"
                          style={{
                            width: `${(run.successCount / run.processedCount) * 100}%`
                          }}
                        />
                      </div>
                      <span className="text-sm text-gray-400">
                        {run.successCount}/{run.processedCount}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right">
                    <button
                      onClick={() => setSelectedRun(selectedRun?.id === run.id ? null : run)}
                      className="px-3 py-1 text-sm rounded-md bg-gray-800 text-gray-100 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      {selectedRun?.id === run.id ? 'Hide Details' : 'View Details'}
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {selectedRun && (
        <div className="border-t border-gray-800 p-6">
          <div className="space-y-4">
            <div className="bg-gray-800 rounded-lg overflow-hidden">
              <div className="px-4 py-2 flex items-center justify-between bg-gray-700">
                <span className="flex items-center gap-2 text-gray-100">
                  <List className="h-4 w-4" />
                  Failed Webhooks
                </span>
              </div>
              <div className="px-4 py-2 max-h-72 overflow-y-auto">
                {selectedRun.failedWebhookIds ? (
                  selectedRun.failedWebhookIds.split(',').map((id, index) => (
                    <div key={index} className="text-red-400 py-1">
                      {id}
                    </div>
                  ))
                ) : (
                  <div className="text-gray-400">No failed webhooks</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {totalPages > 1 && (
        <div className="flex justify-center p-4 border-t border-gray-800">
          <div className="flex gap-2">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => setCurrentPage(i + 1)}
                className={`px-3 py-1 rounded-md text-sm font-medium ${
                  currentPage === i + 1
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                }`}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default WebhookSyncMonitor;