import React, { useState, useEffect, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { AlertCircle, CheckCircle2, Loader2 } from 'lucide-react';
import {
  getCrmIntegrations,
  createCrmIntegration,
  deactivateCrmIntegration,
  testCrmConnection,
  syncCrmData
} from '../services/api';

const CrmIntegration = () => {
  const { organizationId } = useContext(OrganizationContext);    
  const [selectedCrm, setSelectedCrm] = useState('');
  const [credentials, setCredentials] = useState({});
  const [locationId, setLocationId] = useState('');
  const [message, setMessage] = useState({ type: '', content: '' });
  const [existingIntegrations, setExistingIntegrations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTesting, setIsTesting] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);

  // Update CRM types to match backend enum
  const crmTypes = {
    'HUBSPOT': 'HubSpot',
    'GOOGLERESOURCE': 'Google Workspace',
    'SALESFORCE': 'Salesforce',
    'GOHIGHLEVEL': 'GoHighLevel',
    'MSDYNAMICS365': 'MS Dynamics 365',
    'MONDAY': 'Monday.com',
    'SAP': 'SAP',
    'ORACLE_CRM': 'Oracle CRM',
    'ORACLE_NETSUITE': 'Oracle NetSuite'
  };

  const crmRequirements = {
    HUBSPOT: [
      { key: 'apiKey', label: 'API Key', type: 'password' },
      { key: 'clientId', label: 'Client ID', type: 'text' },
      { key: 'clientSecret', label: 'Client Secret', type: 'password' }
    ],
    SALESFORCE: [
      { key: 'clientId', label: 'Client ID', type: 'text' },
      { key: 'clientSecret', label: 'Client Secret', type: 'password' },
      { key: 'instanceUrl', label: 'Instance URL', type: 'text' }
    ],
    GOHIGHLEVEL: [
      { key: 'apiKey', label: 'API Key', type: 'password' },
      { key: 'locationId', label: 'Location ID', type: 'text' }
    ],
    MSDYNAMICS365: [
      { key: 'clientId', label: 'Client ID', type: 'text' },
      { key: 'clientSecret', label: 'Client Secret', type: 'password' },
      { key: 'tenantId', label: 'Tenant ID', type: 'text' }
    ],
    MONDAY: [
      { key: 'apiKey', label: 'API Key', type: 'password' }
    ],
    SAP: [
      { key: 'username', label: 'Username', type: 'text' },
      { key: 'password', label: 'Password', type: 'password' },
      { key: 'systemId', label: 'System ID', type: 'text' }
    ],
    ORACLE_CRM: [
      { key: 'username', label: 'Username', type: 'text' },
      { key: 'password', label: 'Password', type: 'password' },
      { key: 'serviceUrl', label: 'Service URL', type: 'text' }
    ],
    ORACLE_NETSUITE: [
      { key: 'accountId', label: 'Account ID', type: 'text' },
      { key: 'consumerKey', label: 'Consumer Key', type: 'text' },
      { key: 'consumerSecret', label: 'Consumer Secret', type: 'password' },
      { key: 'tokenId', label: 'Token ID', type: 'text' },
      { key: 'tokenSecret', label: 'Token Secret', type: 'password' }
    ],
    GOOGLERESOURCE: [
      { key: 'clientId', label: 'Client ID', type: 'text' },
      { key: 'clientSecret', label: 'Client Secret', type: 'password' },
      { key: 'refreshToken', label: 'Refresh Token', type: 'password' }
    ]
  };

  useEffect(() => {
    if (organizationId) {
    fetchExistingIntegrations(organizationId);
    }
  }, [organizationId]);

  const fetchExistingIntegrations = async () => {
    try {
     if (organizationId) {        
            setIsLoading(true);
            const data = await getCrmIntegrations(organizationId);
            setExistingIntegrations(data);
        }
    } catch (error) {
      setMessage({ type: 'error', content: 'Failed to fetch existing integrations' + error});
    } finally {
      setIsLoading(false);
    }
  };

  const handleCrmChange = (value) => {
    setSelectedCrm(value);
    setCredentials({});
    setLocationId('');
    setMessage({ type: '', content: '' });
  };

  const handleTestConnection = async (integrationId) => {
    try {
      setIsTesting(true);
      await testCrmConnection(integrationId);
      setMessage({ type: 'success', content: 'Connection test successful' });
    } catch (error) {
      setMessage({ type: 'error', content: 'Connection test failed: ' + error.message });
    } finally {
      setIsTesting(false);
    }
  };

  const handleSync = async (integrationId, dataType) => {
    try {
      setIsSyncing(true);
      await syncCrmData(integrationId, dataType);
      setMessage({ type: 'success', content: `${dataType} sync initiated successfully` });
    } catch (error) {
      setMessage({ type: 'error', content: `Failed to sync ${dataType}: ` + error.message });
    } finally {
      setIsSyncing(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (existingIntegrations.some(integration => 
      integration.crmType === selectedCrm && integration.active)) {
      setMessage({ 
        type: 'error', 
        content: 'This CRM is already integrated for your organization' 
      });
      return;
    }
    try {
      setIsLoading(true);
      const integrationData = {
        crmType: selectedCrm,
        credentials: credentials,
        locationId: locationId,
        active: true
      };

      await createCrmIntegration(organizationId, integrationData);
      setMessage({ type: 'success', content: 'CRM integration successful' });
      fetchExistingIntegrations();
      setSelectedCrm('');
      setCredentials({});
      setLocationId('');
    } catch (error) {
      setMessage({ 
        type: 'error', 
        content: error.message || 'An error occurred while creating the integration'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeactivate = async (integrationId) => {
    try {
      setIsLoading(true);
      await deactivateCrmIntegration(integrationId);
      setMessage({ type: 'success', content: 'Integration deactivated successfully' });
      fetchExistingIntegrations();
    } catch (error) {
      setMessage({ type: 'error', content: 'Failed to deactivate integration: ' + error.message });
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <Card>
        <CardHeader>
          <CardTitle>CRM Integration</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-2">
              <Label htmlFor="crmType">Select CRM</Label>
              <Select value={selectedCrm} onValueChange={handleCrmChange}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select a CRM" />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(crmTypes).map(([value, label]) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            {selectedCrm && crmRequirements[selectedCrm]?.map(field => (
              <div key={field.key} className="space-y-2">
                <Label htmlFor={field.key}>{field.label}</Label>
                <Input
                  id={field.key}
                  type={field.type}
                  value={credentials[field.key] || ''}
                  onChange={(e) => setCredentials(prev => ({
                    ...prev,
                    [field.key]: e.target.value
                  }))}
                  required
                  className="w-full"
                />
              </div>
            ))}

            {selectedCrm && (
              <Button 
                type="submit" 
                className="w-full"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Processing
                  </>
                ) : (
                  'Integrate CRM'
                )}
              </Button>
            )}
          </form>

          {message.content && (
            <Alert className={`mt-4 ${message.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
              {message.type === 'error' ? <AlertCircle className="h-4 w-4" /> : <CheckCircle2 className="h-4 w-4" />}
              <AlertDescription>{message.content}</AlertDescription>
            </Alert>
          )}

{existingIntegrations.length > 0 && (
            <div className="mt-8">
              <h3 className="text-lg font-semibold mb-4">Existing Integrations</h3>
              <div className="space-y-4">
                {existingIntegrations.map(integration => (
                  <Card key={integration.id} className="p-4">
                    <div className="flex items-center justify-between">
                      <div>
                        <h4 className="font-medium">{integration.crmType}</h4>
                        <p className="text-sm text-gray-500">
                          Last synced: {integration.lastSyncTime ? new Date(integration.lastSyncTime).toLocaleString() : 'Never'}
                        </p>
                      </div>
                      <div className="flex space-x-2">
                        <Button
                          size="sm"
                          variant="outline"
                          onClick={() => handleTestConnection(integration.id)}
                          disabled={isTesting}
                        >
                          {isTesting ? (
                            <Loader2 className="h-4 w-4 animate-spin" />
                          ) : (
                            'Test Connection'
                          )}
                        </Button>
                        <Button
                          size="sm"
                          variant="outline"
                          onClick={() => handleSync(integration.id, 'ALL')}
                          disabled={isSyncing}
                        >
                          {isSyncing ? (
                            <Loader2 className="h-4 w-4 animate-spin" />
                          ) : (
                            'Sync Now'
                          )}
                        </Button>
                        {integration.active && (
                          <Button
                            size="sm"
                            variant="destructive"
                            onClick={() => handleDeactivate(integration.id)}
                            disabled={isLoading}
                          >
                            Deactivate
                          </Button>
                        )}
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default CrmIntegration;