import React, { useState, useEffect, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { getCallSummariesByOrganization } from '../services/api';
import { format } from 'date-fns';
import { 
  ChevronDown, 
  ChevronUp, 
  Clock, 
  Calendar, 
  DollarSign,
  Phone,
  MessageSquare,
  FileText,
  BarChart
} from 'lucide-react';

const ThemedCallSummary = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [summaries, setSummaries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSummary, setSelectedSummary] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    month: '_all',
    year: '_all',
    assistantId: ''
  });

  const months = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  const years = Array.from(
    { length: new Date().getFullYear() - 2019 + 1 },
    (_, i) => (2020 + i).toString()
  );

  useEffect(() => {
    if (organizationId) {
      fetchSummaries();
    }
  }, [organizationId, currentPage, filters]);

  const fetchSummaries = async () => {
    setLoading(true);
    try {
      const response = await getCallSummariesByOrganization(organizationId, {
        month: filters.month === '_all' ? '' : filters.month,
        year: filters.year === '_all' ? '' : filters.year,
        assistantId: filters.assistantId,
        page: currentPage,
        size: 10
      });
      
      if (response?.content) {
        setSummaries(response.content);
        setTotalPages(response.totalPages || 1);
      }
    } catch (error) {
      console.error('Error fetching call summaries:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatDuration = (minutes) => {
    const hrs = Math.floor(minutes / 60);
    const mins = Math.floor(minutes % 60);
    const secs = Math.floor((minutes * 60) % 60);
    return `${hrs}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const formatCost = (cost) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(cost);
  };

  return (
    <div className="bg-gray-900 rounded-xl shadow-lg">
      {/* Header */}
      <div className="p-6 border-b border-gray-800">
        <h2 className="text-xl font-semibold text-gray-100 flex items-center gap-2">
          <MessageSquare className="h-5 w-5 text-blue-400" />
          Call Summaries
        </h2>
      </div>

      {/* Filters */}
      <div className="p-6 border-b border-gray-800">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Assistant ID
            </label>
            <input
              value={filters.assistantId}
              onChange={(e) => setFilters(prev => ({ ...prev, assistantId: e.target.value }))}
              placeholder="Enter Assistant ID"
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-100 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Month
            </label>
            <select
              value={filters.month}
              onChange={(e) => setFilters(prev => ({ ...prev, month: e.target.value }))}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="_all">All Months</option>
              {months.map(month => (
                <option key={month.value} value={month.value}>{month.label}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Year
            </label>
            <select
              value={filters.year}
              onChange={(e) => setFilters(prev => ({ ...prev, year: e.target.value }))}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="_all">All Years</option>
              {years.map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Summary Table */}
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-800">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Duration</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Cost</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Type</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-400 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-800">
            {loading ? (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-gray-400">
                  Loading summaries...
                </td>
              </tr>
            ) : summaries.length === 0 ? (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-gray-400">
                  No call summaries available
                </td>
              </tr>
            ) : (
              summaries.map((summary) => (
                <tr key={summary.id} className="hover:bg-gray-800/50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-1 text-gray-100">
                      <Calendar className="h-4 w-4 text-gray-400" />
                      <span>{format(new Date(summary.callTrack.startTime), 'PP')}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-1 text-gray-100">
                      <Clock className="h-4 w-4 text-gray-400" />
                      <span>{formatDuration(summary.callTrack.durationInMinutes)}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-1 text-gray-100">
                      <DollarSign className="h-4 w-4 text-gray-400" />
                      <span>{formatCost(summary.callTrack.cost)}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="px-2 py-1 text-sm rounded-full bg-blue-500/20 text-blue-400">
                      {summary.callTrack.callType}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right">
                    <button
                      onClick={() => setSelectedSummary(
                        selectedSummary?.id === summary.id ? null : summary
                      )}
                      className="px-3 py-1 text-sm rounded-md bg-gray-800 text-gray-100 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      {selectedSummary?.id === summary.id ? 'Hide Details' : 'View Details'}
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Selected Summary Details */}
      {selectedSummary && (
        <div className="border-t border-gray-800 p-6">
          <div className="space-y-4">
            <div className="bg-gray-800 rounded-lg overflow-hidden">
              <button
                onClick={() => setSelectedSummary(null)}
                className="w-full px-4 py-2 text-left text-gray-100 hover:bg-gray-700 flex items-center justify-between"
              >
                <span className="flex items-center gap-2">
                  <FileText className="h-4 w-4" />
                  Summary
                </span>
                <ChevronUp className="h-4 w-4" />
              </button>
              <div className="px-4 py-2 text-gray-300">
                {selectedSummary.summary}
              </div>
            </div>

            <div className="bg-gray-800 rounded-lg overflow-hidden">
              <button
                onClick={() => setSelectedSummary(null)}
                className="w-full px-4 py-2 text-left text-gray-100 hover:bg-gray-700 flex items-center justify-between"
              >
                <span className="flex items-center gap-2">
                  <MessageSquare className="h-4 w-4" />
                  Transcript
                </span>
                <ChevronUp className="h-4 w-4" />
              </button>
              <div className="px-4 py-2 max-h-72 overflow-y-auto text-gray-300">
                {selectedSummary.transcript}
              </div>
            </div>

            <div className="bg-gray-800 rounded-lg overflow-hidden">
              <button
                onClick={() => setSelectedSummary(null)}
                className="w-full px-4 py-2 text-left text-gray-100 hover:bg-gray-700 flex items-center justify-between"
              >
                <span className="flex items-center gap-2">
                  <BarChart className="h-4 w-4" />
                  Analysis
                </span>
                <ChevronUp className="h-4 w-4" />
              </button>
              <div className="px-4 py-2 font-mono text-sm text-gray-300">
                {selectedSummary.analysis && (
                  <pre>{JSON.stringify(JSON.parse(selectedSummary.analysis), null, 2)}</pre>
                )}
              </div>
            </div>

            <div className="bg-gray-800 rounded-lg overflow-hidden">
              <button
                onClick={() => setSelectedSummary(null)}
                className="w-full px-4 py-2 text-left text-gray-100 hover:bg-gray-700 flex items-center justify-between"
              >
                <span className="flex items-center gap-2">
                  <MessageSquare className="h-4 w-4" />
                  Call Logs
                </span>
                <ChevronUp className="h-4 w-4" />
              </button>
              <div className="h-[300px] overflow-y-auto rounded-md border border-gray-700 p-4 bg-gray-800/50">
                {selectedSummary.callLogs.split('\n').map((line, index) => (
                  <div
                    key={index}
                    className={`py-1 ${
                      line.startsWith('Assistant:') ? 'pl-4 text-blue-400' :
                      line.startsWith('User:') ? 'pl-4 text-emerald-400' : 'text-gray-300'
                    }`}
                  >
                    {line}
                  </div>
                ))}
              </div>
            </div>

            {selectedSummary.recordingUrl && (
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-400 mb-2">Recording</label>
                <audio controls className="w-full">
                  <source src={selectedSummary.recordingUrl} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex justify-center p-4 border-t border-gray-800">
          <div className="flex gap-2">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => setCurrentPage(i + 1)}
                className={`px-3 py-1 rounded-md text-sm font-medium ${
                  currentPage === i + 1
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                }`}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ThemedCallSummary;