import React, { useState, useEffect, useContext } from 'react';
import { getPhoneNumbers, importPhoneNumber, getAssistants } from '../services/api';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Label } from "./ui/label";
import { Alert, AlertDescription } from "./ui/alert";
import { Badge } from "./ui/badge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { Loader2, Phone, Shield } from "lucide-react";

const VapiPhoneNumbers = () => {
  const [numbers, setNumbers] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newNumber, setNewNumber] = useState({
    number: '',
    provider: 'twilio',
    assistantId: '',
    twilioAccountSid: '',
    twilioAuthToken: '',
    credentialId: ''
  });

  const { organizationId } = useContext(OrganizationContext);

  useEffect(() => {
    if (organizationId) {
      fetchData();
    }
  }, [organizationId]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [numbersData, assistantsData] = await Promise.all([
        getPhoneNumbers(organizationId),
        getAssistants(organizationId)
      ]);
      setNumbers(numbersData);
      setAssistants(assistantsData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field, value) => {
    setNewNumber(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const requestData = {
        provider: newNumber.provider,
        number: newNumber.number,
        assistantId: newNumber.assistantId,
        ...(newNumber.provider === 'twilio' && {
          twilioAccountSid: newNumber.twilioAccountSid,
          twilioAuthToken: newNumber.twilioAuthToken,
        }),
        ...(newNumber.provider !== 'twilio' && {
          credentialId: newNumber.credentialId,
        }),
      };

      await importPhoneNumber(requestData, organizationId);
      await fetchData();
      setNewNumber({
        number: '',
        provider: 'twilio',
        assistantId: '',
        twilioAccountSid: '',
        twilioAuthToken: '',
        credentialId: ''
      });
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const ProviderFields = () => {
    switch (newNumber.provider) {
      case 'twilio':
        return (
          <div className="space-y-4">
            <div>
              <Label htmlFor="twilioAccountSid">Twilio Account SID</Label>
              <Input
                id="twilioAccountSid"
                type="text"
                value={newNumber.twilioAccountSid}
                onChange={(e) => handleChange('twilioAccountSid', e.target.value)}
                required
              />
            </div>
            <div>
              <Label htmlFor="twilioAuthToken">Twilio Auth Token</Label>
              <Input
                id="twilioAuthToken"
                type="password"
                value={newNumber.twilioAuthToken}
                onChange={(e) => handleChange('twilioAuthToken', e.target.value)}
                required
              />
            </div>
          </div>
        );
      case 'vonage':
      case 'byo-phone-number':
        return (
          <div>
            <Label htmlFor="credentialId">Credential ID</Label>
            <Input
              id="credentialId"
              type="text"
              value={newNumber.credentialId}
              onChange={(e) => handleChange('credentialId', e.target.value)}
              required
            />
          </div>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container max-w-4xl mx-auto py-6 space-y-6">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Phone className="h-6 w-6" />
            Import Phone Number
          </CardTitle>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-6">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <Label htmlFor="phoneNumber">Phone Number</Label>
              <Input
                id="phoneNumber"
                type="text"
                value={newNumber.number}
                onChange={(e) => handleChange('number', e.target.value)}
                placeholder="Enter phone number (e.g., +1234567890)"
                required
              />
            </div>

            <div>
              <Label htmlFor="provider">Provider</Label>
              <Select
                value={newNumber.provider}
                onValueChange={(value) => handleChange('provider', value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select provider" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="twilio">Twilio</SelectItem>
                  <SelectItem value="vonage">Vonage</SelectItem>
                  {/* <SelectItem value="byo-phone-number">BYO Phone Number</SelectItem> */}
                </SelectContent>
              </Select>
            </div>

            <div>
              <Label htmlFor="assistant">Assistant</Label>
              <Select
                value={newNumber.assistantId}
                onValueChange={(value) => handleChange('assistantId', value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select assistant" />
                </SelectTrigger>
                <SelectContent>
                  {assistants.map(assistant => (
                    <SelectItem key={assistant.id} value={assistant.id}>
                      {assistant.id}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <ProviderFields />

            <Button type="submit" disabled={loading} className="w-full">
              {loading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Importing...
                </>
              ) : (
                'Import Number'
              )}
            </Button>
          </form>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Imported Phone Numbers</CardTitle>
        </CardHeader>
        <CardContent>
          {numbers.length === 0 ? (
            <p className="text-center text-muted-foreground py-4">
              No phone numbers found.
            </p>
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Phone Number</TableHead>
                  <TableHead>Provider</TableHead>
                  <TableHead>Assistant</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Created At</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {numbers.map(number => (
                  <TableRow key={number.id}>
                    <TableCell className="font-medium">{number.phoneNumber}</TableCell>
                    <TableCell>{number.provider}</TableCell>
                    <TableCell>{number.assistantName}</TableCell>
                    <TableCell>
                      <Badge variant={number.status === 'active' ? "success" : "warning"}>
                        {number.status}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      {new Date(number.createdAt).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default VapiPhoneNumbers;