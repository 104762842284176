import React, { useState, useEffect } from 'react';
import { Button } from './ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { ScrollArea } from "./ui/scroll-area";
import { MessagesSquare } from 'lucide-react';
import { getSMSMessagesStandalone } from '../services/api';

const SMSDialog = ({ contact, open, onOpenChange }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (open && contact && contact.id) {
      // Reset pagination when dialog opens
      setPage(0);
      setMessages([]);
      setHasMore(true);
      fetchMessages(0);
    }
  }, [open, contact]);

  const fetchMessages = async (pageNumber) => {
    if (!contact || !contact.id) return;
    
    setLoading(true);
    try {
      const data = await getSMSMessagesStandalone(contact.id, pageNumber);
      
      // If it's the first page, replace messages, otherwise append
      if (pageNumber === 0) {
        setMessages(data.content);
      } else {
        setMessages(prev => [...prev, ...data.content]);
      }
      
      setTotalPages(data.totalPages);
      setHasMore(pageNumber < data.totalPages - 1);
    } catch (error) {
      console.error('Error fetching SMS messages:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    if (hasMore && !loading) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchMessages(nextPage);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>SMS Communications - {contact?.name}</DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[400px] w-full rounded-md border p-4">
          <div className="space-y-4">
            {messages.map((message) => (
              <div
                key={message.id}
                className={`rounded-lg p-3 max-w-[80%] ${
                  message.direction === 'OUTBOUND'
                    ? 'ml-auto bg-blue-500 text-white'
                    : 'bg-gray-100'
                }`}
              >
                <p>{message.messageContent}</p>
                <div className="text-xs mt-1 opacity-70">
                  {new Date(message.timestamp || message.sentAt).toLocaleString()}
                </div>
              </div>
            ))}
            
            {loading && (
              <div className="flex justify-center items-center py-4">
                Loading messages...
              </div>
            )}
            
            {hasMore && !loading && (
              <div className="flex justify-center pt-4">
                <Button onClick={loadMore} variant="outline">
                  Load More
                </Button>
              </div>
            )}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export default function SMSButton({ contact }) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setDialogOpen(true)}
        variant="outline"
        size="sm"
        className="flex gap-2 items-center"
      >
        <MessagesSquare className="w-4 h-4" />
        View SMS
      </Button>
      <SMSDialog
        contact={contact}
        open={dialogOpen}
        onOpenChange={setDialogOpen}
      />
    </>
  );
}