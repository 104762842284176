import React from 'react';
import { Phone } from 'lucide-react';
import { Link } from 'react-router-dom';
import { FooterLinks } from './FooterLinks';
import { FooterSocial } from './FooterSocial';

export function Footer() {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <Link to="/" className="flex items-center">
              <Phone className="h-8 w-8 text-indigo-400" />
              <span className="ml-2 text-xl font-bold">AI Phone Agent</span>
            </Link>
            <p className="text-gray-400 text-base">
              Transform your customer service with AI-powered phone automation. Available 24/7, scalable, and cost-effective.
            </p>
            <FooterSocial />
          </div>
          <div className="mt-12 xl:mt-0 xl:col-span-2">
            <FooterLinks />
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-gray-700">
          <p className="text-base text-gray-400 text-center">
            © {new Date().getFullYear()} AI Phone Agent. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}