import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAppointmentById, cancelAppointmentById } from '../services/api';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Textarea } from "./ui/textarea";
import { Label } from "./ui/label";
import { Alert, AlertDescription } from "./ui/alert";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";
import { format } from 'date-fns';
import ThankYou from './ThankYou';

const CancelAppointment = () => {
  const { appointmentId } = useParams();
  const [appointment, setAppointment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showThankYou, setShowThankYou] = useState(false);
  const [message, setMessage] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    loadAppointmentDetails();
  }, [appointmentId]);

  const loadAppointmentDetails = async () => {
    try {
        const data = await getAppointmentById(appointmentId);
        if (!data) {
            setError('Appointment not found');
            return;
        }
        setAppointment(data);
    } catch (error) {
        console.error('Error loading appointment:', error);
        setError('Unable to load appointment details. Please try again later.');
    } finally {
        setIsLoading(false);
    }
  };

  const handleCancel = async () => {
    setIsLoading(true);
    setError(null);

    try {
      await cancelAppointmentById(appointmentId, message);
      setShowThankYou(true);
    } catch (error) {
      setError('Failed to cancel appointment');
    } finally {
      setIsLoading(false);
      setShowConfirmDialog(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-lg text-muted-foreground">Loading appointment details...</div>
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  if (!appointment) {
    return (
      <Alert>
        <AlertDescription>Appointment not found</AlertDescription>
      </Alert>
    );
  }

  if (showThankYou) {
    return <ThankYou type="cancelled" appointment={appointment} />;
  }

  return (
    <div className="container max-w-2xl mx-auto py-6">
      <Card>
        <CardHeader>
          <CardTitle>Cancel Appointment</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <p className="text-lg">Are you sure you want to cancel your appointment scheduled for:</p>
            <p className="text-2xl font-semibold">
              {format(new Date(appointment.appointmentDateTime), 'PPPp')}
            </p>
          </div>

          <div className="space-y-2">
            <Label htmlFor="message">Cancellation Reason (Optional)</Label>
            <Textarea
              id="message"
              placeholder="Please let us know why you're cancelling"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={3}
            />
          </div>

          <Button 
            variant="destructive"
            disabled={isLoading}
            onClick={() => setShowConfirmDialog(true)}
            className="w-full"
          >
            Cancel Appointment
          </Button>
        </CardContent>
      </Card>

      <AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Cancel Appointment</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to cancel this appointment? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>No, Keep Appointment</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleCancel}
              className="bg-destructive text-destructive-foreground"
            >
              Yes, Cancel Appointment
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default CancelAppointment;