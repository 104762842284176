import React from 'react';
import { FeatureCard } from './FeatureCard';
import { features } from './features.data';

export function FeaturesList() {
  return (
    <div className="py-12">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold text-white mb-4">Powerful Features</h2>
        <p className="text-xl text-gray-300 max-w-3xl mx-auto">
          Everything you need to automate and enhance your customer communication
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <FeatureCard 
            key={index}
            Icon={feature.icon}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </div>
    </div>
  );
}