import React from 'react';
import { PricingSection2 } from '../components/PricingSection2';
import { CheckCircle } from 'lucide-react';

export function Pricing2() {
  return (
    <div>
      <PricingSection2 />
      
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Frequently Asked Questions</h2>
            <p className="text-xl text-gray-600">Everything you need to know about our services</p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              {
                q: "How does the AI phone agent work?",
                a: "Our AI phone agent uses advanced natural language processing to understand and respond to callers naturally. It can handle multiple calls simultaneously and integrates with your existing systems."
              },
              {
                q: "Can I customize the AI's voice and responses?",
                a: "Yes, you can customize the AI's voice, accent, and responses to match your brand identity and specific business needs."
              },
              {
                q: "What happens if the AI can't handle a call?",
                a: "The AI can seamlessly transfer complex calls to human agents based on predefined rules and real-time situation assessment."
              },
              {
                q: "Is there a setup fee?",
                a: "No, there are no hidden setup fees. You only pay the monthly subscription based on your chosen plan."
              }
            ].map((faq, index) => (
              <div key={index} className="bg-gray-50 p-6 rounded-lg">
                <div className="flex items-start">
                  <CheckCircle className="h-6 w-6 text-indigo-600 mt-1 mr-3" />
                  <div>
                    <h3 className="text-lg font-semibold mb-2">{faq.q}</h3>
                    <p className="text-gray-600">{faq.a}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}