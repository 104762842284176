import React, { createContext, useState, useEffect, useContext } from 'react';
import { OrganizationContext } from './OrganizationContext';
import { API_BASE_URL } from '../services/api';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const organizationContext = useContext(OrganizationContext);
  const organizationId = organizationContext?.organizationId;

  useEffect(() => {
    if (!organizationId) {
      console.log('No organization ID available yet');
      return;
    }

    try {
      const eventSourceUrl = new URL(`${API_BASE_URL}/notifications/subscribe/${organizationId}`);
      const eventSource = new EventSource(eventSourceUrl);

      eventSource.addEventListener('endOfCall', (event) => {
        const data = JSON.parse(event.data);
        addNotification({
          type: 'endOfCall',
          title: 'Call Completed',
          message: `Call summary: ${data.summary}`,
          timestamp: new Date(),
          read: false
        });
      });

      eventSource.addEventListener('toolCall', (event) => {
        const data = JSON.parse(event.data);
        addNotification({
          type: 'toolCall',
          title: 'Tool Call Completed',
          message: `Tool call processed: ${data.toolCallId}`,
          timestamp: new Date(),
          read: false
        });
      });

      // Add SMS conversation event listener
      eventSource.addEventListener('smsConversation', (event) => {
        const data = JSON.parse(event.data);
        addNotification({
          type: 'smsConversation',
          title: 'New SMS Message',
          message: `From ${data.contactName}: ${data.message}`,
          timestamp: new Date(),
          read: false,
          conversationId: data.conversationId
        });
      });

      eventSource.onerror = (error) => {
        console.error('EventSource error:', error);
        eventSource.close();
      };

      return () => {
        eventSource.close();
      };
    } catch (error) {
      console.error('Error setting up EventSource:', error);
    }
  }, [organizationId]);

  const requestNotificationPermission = async () => {
    if ('Notification' in window) {
      const permission = await Notification.requestPermission();
      return permission === 'granted';
    }
    return false;
  };

  const addNotification = (notification) => {
    setNotifications(prev => [notification, ...prev]);
    setUnreadCount(prev => prev + 1);

    if (Notification.permission === 'granted') {
      new Notification(notification.title, {
        body: notification.message,
        icon: '/krupa.jpg'
      });
    }
  };

  const markAsRead = (index) => {
    setNotifications(prev => prev.map((notif, i) => 
      i === index ? { ...notif, read: true } : notif
    ));
    setUnreadCount(prev => Math.max(0, prev - 1));
  };

  const markAllAsRead = () => {
    setNotifications(prev => prev.map(notif => ({ ...notif, read: true })));
    setUnreadCount(0);
  };

  const clearNotifications = () => {
    setNotifications([]);
    setUnreadCount(0);
  };

  const value = {
    notifications,
    unreadCount,
    addNotification,
    markAsRead,
    markAllAsRead,
    clearNotifications
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};