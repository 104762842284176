import React, { useState, useEffect, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { createOutboundCall, getAssistants, getPhoneNumbers } from '../services/api';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Label } from "./ui/label";
import { Alert, AlertDescription } from "./ui/alert";
import { Phone, Loader2 } from "lucide-react";

const OutboundDial = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [assistantsData, setAssistantsData] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [callData, setCallData] = useState({
    assistantId: '',
    phoneNumberId: '',
    customer: {
      number: '',
      name: '',
      numberE164CheckEnabled: false
    }
  });

  const { organizationId } = useContext(OrganizationContext);

  useEffect(() => {
    if (organizationId) {
      fetchInitialData();
    }
  }, [organizationId]);

  const fetchInitialData = async () => {
    try {
      const [assistantsData, numbersData] = await Promise.all([
        getAssistants(organizationId),
        getPhoneNumbers(organizationId)
      ]);
      setAssistantsData(assistantsData);
      setPhoneNumbers(numbersData);
    } catch (err) {
      setError('Failed to load initial data');
    }
  };

  const handleChange = (field, value) => {
    if (field.startsWith('customer.')) {
      const customerField = field.split('.')[1];
      setCallData(prev => ({
        ...prev,
        customer: {
          ...prev.customer,
          [customerField]: value
        }
      }));
    } else {
      setCallData(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    return phoneRegex.test(number);
  };

  const isFormValid = () => {
    return (
      callData.assistantId &&
      callData.phoneNumberId &&
      validatePhoneNumber(callData.customer.number) &&
      callData.customer.name
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await createOutboundCall({
        assistantId: callData.assistantId,
        phoneNumberId: callData.phoneNumberId,
        assistantOverrides: {
          variableValues: {
            name: 'John'
          }
        },
        customer: {
          number: callData.customer.number,
          name: callData.customer.name,
          numberE164CheckEnabled: false
        }
      }, organizationId);

      setSuccess(true);
      setCallData({
        assistantId: '',
        phoneNumberId: '',
        customer: {
          number: '',
          name: '',
          numberE164CheckEnabled: false
        }
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container max-w-2xl mx-auto py-6">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Phone className="w-6 h-6" />
            Make Outbound Call
          </CardTitle>
          <CardDescription>
            Initiate a call using an assistant
          </CardDescription>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-6">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {success && (
            <Alert className="mb-6">
              <AlertDescription>Call initiated successfully!</AlertDescription>
            </Alert>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <div>
                <Label htmlFor="assistantId">Assistant</Label>
                <Select
                  value={callData.assistantId}
                  onValueChange={(value) => handleChange('assistantId', value)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select Assistant" />
                  </SelectTrigger>
                  <SelectContent>
                    {assistantsData.map(assistant => (
                      <SelectItem key={assistant.id} value={assistant.id}>
                        {assistant.id}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div>
                <Label htmlFor="phoneNumberId">From Number</Label>
                <Select
                  value={callData.phoneNumberId}
                  onValueChange={(value) => handleChange('phoneNumberId', value)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select Phone Number" />
                  </SelectTrigger>
                  <SelectContent>
                    {phoneNumbers.map(number => (
                      <SelectItem key={number.id} value={number.id}>
                        {number.id}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div>
                <Label htmlFor="customerName">Customer Name</Label>
                <Input
                  id="customerName"
                  value={callData.customer.name}
                  onChange={(e) => handleChange('customer.name', e.target.value)}
                  placeholder="Enter customer name"
                  required
                />
              </div>

              <div>
                <Label htmlFor="customerNumber">To Phone Number</Label>
                <Input
                  id="customerNumber"
                  type="tel"
                  value={callData.customer.number}
                  onChange={(e) => handleChange('customer.number', e.target.value)}
                  placeholder="Enter phone number (e.g., +1234567890)"
                  required
                  className={!validatePhoneNumber(callData.customer.number) && 
                    callData.customer.number ? 'border-red-500' : ''}
                />
                {!validatePhoneNumber(callData.customer.number) && 
                  callData.customer.number && (
                  <p className="text-sm text-red-500 mt-1">
                    Please enter a valid phone number
                  </p>
                )}
              </div>
            </div>

            <Button
              type="submit"
              className="w-full"
              disabled={loading || !isFormValid()}
            >
              {loading ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Initiating Call...
                </>
              ) : (
                'Make Call'
              )}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default OutboundDial;
