import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { getKnowledgeBaseByOrganization, saveKnowledgeBase } from '../services/api';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Loader2 } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

// Provider and Model options
const PROVIDER_MODELS = {
  openai: [
    'o1-preview', 'o1-preview-2024-09-12', 'o1-mini', 'o1-mini-2024-09-12',
    'gpt-4o-realtime-preview-2024-10-01', 'gpt-4o-realtime-preview-2024-12-17',
    'gpt-4o-mini-realtime-preview-2024-12-17', 'gpt-4o-mini', 'gpt-4o-mini-2024-07-18',
    'gpt-4o', 'gpt-4o-2024-05-13', 'gpt-4o-2024-08-06', 'gpt-4o-2024-11-20',
    'gpt-4-turbo', 'gpt-4-turbo-2024-04-09', 'gpt-4-turbo-preview', 'gpt-4-0125-preview',
    'gpt-4-1106-preview', 'gpt-4', 'gpt-4-0613', 'gpt-3.5-turbo', 'gpt-3.5-turbo-0125',
    'gpt-3.5-turbo-1106', 'gpt-3.5-turbo-16k', 'gpt-3.5-turbo-0613'
  ]
};

const TRANSCRIBER_OPTIONS = {
  deepgram: {
    languages: [
      'bg', 'ca', 'cs', 'da', 'da-DK', 'de', 'de-CH', 'el', 'en', 'en-AU',
      'en-GB', 'en-IN', 'en-NZ', 'en-US', 'es', 'es-419', 'es-LATAM', 'et',
      'fi', 'fr', 'fr-CA', 'hi', 'hi-Latn', 'hu', 'id', 'it', 'ja', 'ko',
      'ko-KR', 'lt', 'lv', 'ms', 'multi', 'nl', 'nl-BE', 'no', 'pl', 'pt',
      'pt-BR', 'ro', 'ru', 'sk', 'sv', 'sv-SE', 'ta', 'taq', 'th', 'th-TH',
      'tr', 'uk', 'vi', 'zh', 'zh-CN', 'zh-HK', 'zh-Hans', 'zh-Hant', 'zh-TW'
    ],
    models: [
      'nova-2', 'nova-2-general', 'nova-2-meeting', 'nova-2-phonecall',
      'nova-2-finance', 'nova-2-conversationalai', 'nova-2-voicemail',
      'nova-2-video', 'nova-2-medical', 'nova-2-drivethru', 'nova-2-automotive',
      'nova', 'nova-general', 'nova-phonecall', 'nova-medical', 'enhanced',
      'enhanced-general', 'enhanced-meeting', 'enhanced-phonecall',
      'enhanced-finance', 'base', 'base-general', 'base-meeting',
      'base-phonecall', 'base-finance', 'base-conversationalai',
      'base-voicemail', 'base-video'
    ]
  }
};

const VOICE_OPTIONS = {
  openai: {
    persons: ['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer'],
    models: []
  },
  '11labs': {
    persons: [
      'burt', 'marissa', 'andrea', 'sarah', 'phillip', 'steve', 'joseph',
      'myra', 'paula', 'ryan', 'drew', 'paul', 'mrb', 'matilda', 'mark'
    ],
    models: [
      'eleven_multilingual_v2', 'eleven_turbo_v2', 'eleven_turbo_v2_5',
      'eleven_flash_v2', 'eleven_flash_v2_5', 'eleven_monolingual_v1'
    ]
  }
};

const AssistantKnowledgeBase = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [knowledgeBase, setKnowledgeBase] = useState({
    assistantGreeting: '',
    assistantFunctionalityDesc: '',
    assistantName: '',
    assistantProvider: '',
    assistantModel: '',
    assistantTranscriberProvider: '',
    assistantTranscriberLang: '',
    assistantTranscriberModel: '',
    assistantVoiceProvider: '',
    assistantVoicePerson: '',
    assistantVoiceModel: '',
    assistantForwardingNumber: '',
    hippaCompliantEnabled: false,
    voiceRecordingEnabled: false,
    videoRecordingEnabled: false,
    voicemailMessage: ''
  });

  useEffect(() => {
    const fetchKnowledgeBase = async () => {
      if (!organizationId) return;
      
      setLoading(true);
      try {
        const data = await getKnowledgeBaseByOrganization(organizationId);
        if (data && data.length > 0) {
          setKnowledgeBase(data[0]);
        }
      } catch (err) {
        setError('Failed to fetch assistant knowledge base');
      } finally {
        setLoading(false);
      }
    };

    fetchKnowledgeBase();
  }, [organizationId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const updatedData = await saveKnowledgeBase(organizationId, knowledgeBase);
      setKnowledgeBase(updatedData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field, value) => {
    setKnowledgeBase(prev => ({
      ...prev,
      [field]: value
    }));
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container max-w-2xl mx-auto py-6">
      <Card>
        <CardHeader>
          <CardTitle>Assistant Knowledge Base</CardTitle>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-6">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              {/* Basic Information */}
              <div>
                <Label htmlFor="assistantName">Assistant Name</Label>
                <Input
                  id="assistantName"
                  value={knowledgeBase.assistantName}
                  onChange={(e) => handleChange('assistantName', e.target.value)}
                  placeholder="Enter assistant name"
                />
              </div>

              <div>
                <Label htmlFor="assistantGreeting">Assistant Greeting</Label>
                <Textarea
                  id="assistantGreeting"
                  value={knowledgeBase.assistantGreeting}
                  onChange={(e) => handleChange('assistantGreeting', e.target.value)}
                  rows={3}
                  placeholder="Enter greeting message"
                />
              </div>

              <div>
                <Label htmlFor="assistantFunctionalityDesc">Functionality Description</Label>
                <Textarea
                  id="assistantFunctionalityDesc"
                  value={knowledgeBase.assistantFunctionalityDesc}
                  onChange={(e) => handleChange('assistantFunctionalityDesc', e.target.value)}
                  rows={4}
                  placeholder="Describe assistant functionality"
                />
              </div>

              {/* AI Provider and Model */}
              <div>
                <Label htmlFor="assistantProvider">AI Provider</Label>
                <Select
                  value={knowledgeBase.assistantProvider}
                  onValueChange={(value) => {
                    handleChange('assistantProvider', value);
                    handleChange('assistantModel', ''); // Reset model when provider changes
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select AI provider" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.keys(PROVIDER_MODELS).map((provider) => (
                      <SelectItem key={provider} value={provider}>
                        {provider.toUpperCase()}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {knowledgeBase.assistantProvider && (
                <div>
                  <Label htmlFor="assistantModel">AI Model</Label>
                  <Select
                    value={knowledgeBase.assistantModel}
                    onValueChange={(value) => handleChange('assistantModel', value)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select AI model" />
                    </SelectTrigger>
                    <SelectContent>
                      {PROVIDER_MODELS[knowledgeBase.assistantProvider]?.map((model) => (
                        <SelectItem key={model} value={model}>
                          {model}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}

              {/* Transcriber Settings */}
              <div>
                <Label htmlFor="assistantTranscriberProvider">Transcriber Provider</Label>
                <Select
                  value={knowledgeBase.assistantTranscriberProvider}
                  onValueChange={(value) => {
                    handleChange('assistantTranscriberProvider', value);
                    handleChange('assistantTranscriberLang', '');
                    handleChange('assistantTranscriberModel', '');
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select transcriber provider" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.keys(TRANSCRIBER_OPTIONS).map((provider) => (
                      <SelectItem key={provider} value={provider}>
                        {provider.toUpperCase()}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {knowledgeBase.assistantTranscriberProvider && (
                <>
                  <div>
                    <Label htmlFor="assistantTranscriberLang">Transcriber Language</Label>
                    <Select
                      value={knowledgeBase.assistantTranscriberLang}
                      onValueChange={(value) => handleChange('assistantTranscriberLang', value)}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select language" />
                      </SelectTrigger>
                      <SelectContent>
                        {TRANSCRIBER_OPTIONS[knowledgeBase.assistantTranscriberProvider]?.languages.map((lang) => (
                          <SelectItem key={lang} value={lang}>
                            {lang}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>

                  <div>
                    <Label htmlFor="assistantTranscriberModel">Transcriber Model</Label>
                    <Select
                      value={knowledgeBase.assistantTranscriberModel}
                      onValueChange={(value) => handleChange('assistantTranscriberModel', value)}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select model" />
                      </SelectTrigger>
                      <SelectContent>
                        {TRANSCRIBER_OPTIONS[knowledgeBase.assistantTranscriberProvider]?.models.map((model) => (
                          <SelectItem key={model} value={model}>
                            {model}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </>
              )}

              {/* Voice Settings */}
              <div>
                <Label htmlFor="assistantVoiceProvider">Voice Provider</Label>
                <Select
                  value={knowledgeBase.assistantVoiceProvider}
                  onValueChange={(value) => {
                    handleChange('assistantVoiceProvider', value);
                    handleChange('assistantVoicePerson', '');
                    handleChange('assistantVoiceModel', '');
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select voice provider" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.keys(VOICE_OPTIONS).map((provider) => (
                      <SelectItem key={provider} value={provider}>
                        {provider.toUpperCase()}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {knowledgeBase.assistantVoiceProvider && (
                <>
                  <div>
                    <Label htmlFor="assistantVoicePerson">Voice Person</Label>
                    <Select
                      value={knowledgeBase.assistantVoicePerson}
                      onValueChange={(value) => handleChange('assistantVoicePerson', value)}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select voice person" />
                        </SelectTrigger>
                      <SelectContent>
                        {VOICE_OPTIONS[knowledgeBase.assistantVoiceProvider]?.persons.map((person) => (
                          <SelectItem key={person} value={person}>
                            {person}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>

                  {knowledgeBase.assistantVoiceProvider === '11labs' && (
                    <div>
                      <Label htmlFor="assistantVoiceModel">Voice Model</Label>
                      <Select
                        value={knowledgeBase.assistantVoiceModel}
                        onValueChange={(value) => handleChange('assistantVoiceModel', value)}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select voice model" />
                        </SelectTrigger>
                        <SelectContent>
                          {VOICE_OPTIONS[knowledgeBase.assistantVoiceProvider]?.models.map((model) => (
                            <SelectItem key={model} value={model}>
                              {model}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  )}
                </>
              )}

              {/* Call Settings */}
              <div>
                <Label htmlFor="assistantForwardingNumber">Forwarding Number</Label>
                <Input
                  id="assistantForwardingNumber"
                  value={knowledgeBase.assistantForwardingNumber}
                  onChange={(e) => handleChange('assistantForwardingNumber', e.target.value)}
                  placeholder="Enter forwarding number"
                />
              </div>

              <div>
                <Label htmlFor="voicemailMessage">Voicemail Message</Label>
                <Textarea
                  id="voicemailMessage"
                  value={knowledgeBase.voicemailMessage}
                  onChange={(e) => handleChange('voicemailMessage', e.target.value)}
                  rows={3}
                  placeholder="Enter voicemail message"
                />
              </div>

              {/* Toggle Settings */}
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <Label htmlFor="hippaCompliantEnabled">HIPPA Compliant</Label>
                  <Switch
                    id="hippaCompliantEnabled"
                    checked={knowledgeBase.hippaCompliantEnabled}
                    onCheckedChange={(checked) => handleChange('hippaCompliantEnabled', checked)}
                  />
                </div>

                <div className="flex items-center justify-between">
                  <Label htmlFor="voiceRecordingEnabled">Voice Recording</Label>
                  <Switch
                    id="voiceRecordingEnabled"
                    checked={knowledgeBase.voiceRecordingEnabled}
                    onCheckedChange={(checked) => handleChange('voiceRecordingEnabled', checked)}
                  />
                </div>

                <div className="flex items-center justify-between">
                  <Label htmlFor="videoRecordingEnabled">Video Recording</Label>
                  <Switch
                    id="videoRecordingEnabled"
                    checked={knowledgeBase.videoRecordingEnabled}
                    onCheckedChange={(checked) => handleChange('videoRecordingEnabled', checked)}
                  />
                </div>
              </div>
            </div>

            <Button
              type="submit"
              className="w-full"
              disabled={loading}
            >
              {loading ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Saving...
                </>
              ) : (
                'Save Knowledge Base'
              )}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default AssistantKnowledgeBase;  