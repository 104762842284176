import { Brain, Globe, Users, Link, ListChecks, Calendar, Phone, FileText, BarChart, Bot, Shield, Clock } from 'lucide-react';
import type { LucideIcon } from 'lucide-react';

interface Feature {
  icon: LucideIcon;
  title: string;
  description: string;
}

export const features: Feature[] = [
  {
    icon: Bot,
    title: "AI-Powered",
    description: "Advanced natural language processing for human-like conversations"
  },
  {
    icon: Phone,
    title: "24/7 Availability",
    description: "Never miss a call with round-the-clock automated support"
  },
  {
    icon: Shield,
    title: "Secure & Reliable",
    description: "Enterprise-grade security for your business communications"
  },
  {
    icon: Phone,
    title: "Call Memory",
    description: "AI-powered caller identification system that remembers customer preferences, past interactions, and conversation history for personalized experiences"
  },
  {
    icon: FileText,
    title: "Call Logs & Analytics",
    description: "Comprehensive call documentation including transcripts, recordings, and AI-generated summaries. Access insights instantly through our secure dashboard"
  },
  {
    icon: BarChart,
    title: "Volume Analysis",
    description: "Advanced analytics identifying peak call times, common inquiries, and customer patterns to optimize your resource allocation and response strategies"
  },
  {
    icon: ListChecks,
    title: "Smart Call Management",
    description: "Intelligently handle and prioritize multiple calls using AI-powered routing and real-time urgency assessment"
  },
  {
    icon: Brain,
    title: "Custom Knowledge Base",
    description: "Train the AI with your business-specific information to provide accurate, contextual responses to customer inquiries"
  },
  {
    icon: Globe,
    title: "Multilingual Support",
    description: "Break language barriers with real-time translation and support in over 30+ languages, ensuring global accessibility"
  },
  {
    icon: Users,
    title: "AI Squads",
    description: "Create specialized AI teams for different departments or services, each with unique knowledge and handling protocols"
  },
  {
    icon: Link,
    title: "CRM Integrations",
    description: "Seamlessly connect with popular CRM platforms to maintain customer history and provide personalized experiences"
  },
  {
    icon: Clock,
    title: "Automated Scheduling",
    description: "Let AI handle your appointment booking and calendar management seamlessly, reducing scheduling conflicts and manual work"
  }
];