import React from 'react';
import { Hero } from '../components/Hero/Hero';
import { Footer } from '../components/Footer/Footer';
import { MessageSquare, BarChart, Clock } from 'lucide-react';

export function Home() {
  return (
    <div>
      <Hero />
      
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Why Choose AI Phone Agent?</h2>
            <p className="text-xl text-gray-600">Transform your business communication with AI</p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <MessageSquare className="h-12 w-12 text-indigo-600 mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-2">Natural Conversations</h3>
              <p className="text-gray-600">Our AI understands context and maintains natural dialogue flow</p>
            </div>
            <div className="text-center">
              <BarChart className="h-12 w-12 text-indigo-600 mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-2">Detailed Analytics</h3>
              <p className="text-gray-600">Get insights into call patterns and customer interactions</p>
            </div>
            <div className="text-center">
              <Clock className="h-12 w-12 text-indigo-600 mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-2">Time Savings</h3>
              <p className="text-gray-600">Reduce manual call handling and focus on growth</p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-indigo-600 text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-8">Ready to transform your business?</h2>
          <button className="bg-white text-indigo-600 px-8 py-3 rounded-md hover:bg-gray-100">
            Start Free Trial
          </button>
        </div>
      </section>
      <Footer/>
    </div>
  );
}