import React from 'react';
import { Phone, Menu, LogIn } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';

export function Navbar() {
  const navigate = useNavigate();

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <Phone className="h-8 w-8 text-indigo-600" />
              <span className="ml-2 text-xl font-bold text-gray-900">AI Phone Agent</span>
            </Link>
          </div>
          <div className="hidden md:flex items-center space-x-8">
            <Link to="/" className="text-gray-700 hover:text-indigo-600">Home</Link>
            <Link to="/pricing" className="text-gray-700 hover:text-indigo-600">Pricing</Link>
            <a href="#features" className="text-gray-700 hover:text-indigo-600">Features</a>
            <button 
              onClick={() => navigate('/login')}
              className="flex items-center text-gray-700 hover:text-indigo-600"
            >
              <LogIn className="h-5 w-5 mr-1" />
              Login
            </button>
            <button className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700">
              Get Started
            </button>
          </div>
          <div className="md:hidden">
            <Menu className="h-6 w-6 text-gray-700" />
          </div>
        </div>
      </div>
    </nav>
  );
}