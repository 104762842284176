import React, { useState, useContext, useEffect } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { saveAppointmentSettings, getAppointmentSetting } from '../services/api';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from './ui/card';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';
import { Checkbox } from './ui/checkbox';
import { Label } from './ui/label';
import { Alert, AlertDescription } from './ui/alert';

const AppointmentSettings = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [settings, setSettings] = useState({
    organizationId: organizationId,
    eventName: '',
    googleCalendarId: '',
    meetingDurationMinutes: 30,
    dayStartTime: '09:00',
    dayEndTime: '17:00',
    timeBetweenMeetingsMinutes: 15,
    appointmentBufferHours: 1,
    bookingOpenNoDays: 30,
    weekendDays: [],
    timeZone: 'Europe/London',
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});


  useEffect(() => {
    if (organizationId) {
      const fetchSettings = async () => {
        try {
          const fetchedSettings = await getAppointmentSetting(organizationId);
          setSettings({
            ...fetchedSettings,
            weekendDays: Array.isArray(fetchedSettings.weekendDays) ? fetchedSettings.weekendDays : [],
            organizationId: organizationId || '',
          });
  
          // Initialize checkbox states
          const initialCheckboxStates = DAYS_OF_WEEK.reduce((acc, _, index) => {
            acc[index] = Array.isArray(fetchedSettings.weekendDays) && 
                         fetchedSettings.weekendDays.includes(index);
            return acc;
          }, {});
          setCheckboxStates(initialCheckboxStates);
        } catch (error) {
          console.error('Error fetching appointment settings:', error);
          setError('Failed to fetch appointment settings');
        }
      };
  
      fetchSettings();
    }
  }, [organizationId]);
  
  // Update the handleWeekendDaysChange function
  const handleWeekendDaysChange = (dayIndex) => {
    setCheckboxStates(prev => ({
      ...prev,
      [dayIndex]: !prev[dayIndex]
    }));
  
    setSettings(prevSettings => {
      const currentWeekendDays = Array.isArray(prevSettings.weekendDays) ? 
                                prevSettings.weekendDays : [];
      const newWeekendDays = currentWeekendDays.includes(dayIndex)
        ? currentWeekendDays.filter(d => d !== dayIndex)
        : [...currentWeekendDays, dayIndex].sort((a, b) => a - b);
      
      return {
        ...prevSettings,
        weekendDays: newWeekendDays
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: value  || '' // Use empty string if value is null/undefined
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);
    if (settings.organizationId == null)
      settings.organizationId = organizationId;
    try {
      await saveAppointmentSettings(settings);
      setSuccess(true);
    } catch (error) {
      console.error('Error saving settings:', error);
      setError('Failed to save appointment settings');
    }
  };
  
  const DAYS_OF_WEEK = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 
    'Thursday', 'Friday', 'Saturday'
  ];

  return (
    <div className="container mx-auto py-8">
      <Card>
        <CardHeader>
          <CardTitle>Appointment Settings</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            {error && (
              <Alert variant="destructive" className="mb-4">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {success && (
              <Alert variant="success" className="mb-4">
                <AlertDescription>Settings saved successfully</AlertDescription>
              </Alert>
            )}

            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="organizationId">Organization ID</Label>
              <Input
                id="organizationId"
                name="organizationId"
                value={settings.organizationId||organizationId}
                readOnly
              />
            </div>

            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="eventName">Event Name</Label>
              <Input
                id="eventName"
                name="eventName"
                value={settings.eventName || ''}
                onChange={handleChange}
              />
            </div>

            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="googleCalendarId">Google Calendar ID</Label>
              <Input
                id="googleCalendarId"
                name="googleCalendarId"
                value={settings.googleCalendarId || ''}
                onChange={handleChange}
              />
            </div>

            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="meetingDurationMinutes">Meeting Duration (minutes)</Label>
              <Input
                id="meetingDurationMinutes"
                name="meetingDurationMinutes"
                type="number"
                value={settings.meetingDurationMinutes || ''}
                onChange={handleChange}
              />
            </div>

            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="dayStartTime">Day Start Time</Label>
              <Input
                id="dayStartTime"
                name="dayStartTime"
                type="time"
                value={settings.dayStartTime || ''}
                onChange={handleChange}
              />
            </div>

            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="dayEndTime">Day End Time</Label>
              <Input
                id="dayEndTime"
                name="dayEndTime"
                type="time"
                value={settings.dayEndTime || ''}
                onChange={handleChange}
              />
            </div>

            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="timeBetweenMeetingsMinutes">Time Between Meetings (minutes)</Label>
              <Input
                id="timeBetweenMeetingsMinutes"
                name="timeBetweenMeetingsMinutes"
                type="number"
                value={settings.timeBetweenMeetingsMinutes || ''}
                onChange={handleChange}
              />
            </div>

            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="appointmentBufferHours">Appointment Buffer Hours</Label>
              <Input
                id="appointmentBufferHours"
                name="appointmentBufferHours"
                type="number"
                value={settings.appointmentBufferHours || ''}
                onChange={handleChange}
              />
            </div>

            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="bookingOpenNoDays">Booking Open Number of Days</Label>
              <Input
                id="bookingOpenNoDays"
                name="bookingOpenNoDays"
                type="number"
                value={settings.bookingOpenNoDays || ''}
                onChange={handleChange}
              />
            </div>

            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="timeZone">Timezone</Label>
              <Select
                name="timeZone"
                value={settings.timeZone}
                onValueChange={(value) => handleChange({ target: { name: 'timeZone', value } })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select timezone" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Europe/London">Europe/London</SelectItem>
                  <SelectItem value="America/New_York">America/New_York</SelectItem>
                  <SelectItem value="America/Los_Angeles">America/Los_Angeles</SelectItem>
                  <SelectItem value="Asia/Tokyo">Asia/Tokyo</SelectItem>
                  <SelectItem value="Australia/Sydney">Australia/Sydney</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div>
              <Label className="text-base font-semibold">Weekend Days</Label>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-2">
                {DAYS_OF_WEEK.map((day, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <Checkbox
                      id={`day-${index}`}
                      checked={checkboxStates[index] ?? false}
                      onCheckedChange={() => handleWeekendDaysChange(index)}
                      className="data-[state=checked]:bg-primary"
                      aria-checked={checkboxStates[index] ?? false}
                    />
                    <label 
                      htmlFor={`day-${index}`}
                      className="text-sm cursor-pointer"
                    >
                      {day} ({checkboxStates[index] ? 'Yes' : 'No'})
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </form>
        </CardContent>
        <CardFooter>
          <Button onClick={handleSubmit}>Save Settings</Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AppointmentSettings;