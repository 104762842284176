import React from 'react';
import { Check } from 'lucide-react';

export function PricingSection2() {
  const plans = [
    {
      name: 'Starter',
      price: 549,
      features: [
        'Up to 100 calls/month',
        'Basic call handling',
        'Email support',
        'Call analytics',
        'Business hours coverage'
      ]
    },
    {
      name: 'Professional',
      price: 999,
      features: [
        'Up to 500 calls/month',
        'Advanced call routing',
        'Priority support',
        'Custom voice options',
        '24/7 coverage',
        'CRM integration'
      ]
    },
    {
      name: 'Enterprise',
      price: 1999,
      features: [
        'Up to 1000 calls/month',
        'Custom AI training',
        'Dedicated account manager',
        'Advanced analytics',
        'Multi-language support',
        'API access',
        'Custom integrations'
      ]
    }
  ];

  return (
    <div className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Simple, Transparent Pricing</h2>
          <p className="text-xl text-gray-600">Choose the perfect plan for your business</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {plans.map((plan) => (
            <div key={plan.name} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="p-6">
                <h3 className="text-2xl font-bold text-gray-900 mb-4">{plan.name}</h3>
                <div className="mb-6">
                  <span className="text-4xl font-bold">${plan.price}</span>
                  <span className="text-gray-600">/month</span>
                </div>
                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-center">
                      <Check className="h-5 w-5 text-green-500 mr-2" />
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
                <button className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700">
                  Get Started
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}