import { Heart, PhoneForwarded, Calendar, Globe, Shield, TrendingUp, Clock, DollarSign, Sparkles } from 'lucide-react';
import type { LucideIcon } from 'lucide-react';

interface Benefit {
  icon: LucideIcon;
  title: string;
  description: string;
}

export const benefits: Benefit[] = [
  {
    icon: Clock,
    title: "24/7 Availability",
    description: "Never miss a customer call with round-the-clock service that doesn't take breaks or holidays"
  },
  {
    icon: DollarSign,
    title: "Cost Reduction",
    description: "Significantly lower operational costs compared to traditional call centers while maintaining quality"
  },
  {
    icon: Heart,
    title: "Consistent Experience",
    description: "Deliver the same high-quality customer experience every time, regardless of call volume or time of day"
  },
  {
    icon: Sparkles,
    title: "Zero Learning Curve",
    description: "AI agents come pre-trained and ready to handle calls from day one, with continuous improvement over time"
  },
  {
    icon: Shield,
    title: "Compliance & Security",
    description: "Maintain perfect compliance with regulations and secure handling of sensitive customer information"
  },
  {
    icon: TrendingUp,
    title: "Scalable Operations",
    description: "Easily handle sudden spikes in call volume without the need to hire and train new staff"
  },
  {
    icon: Heart,
    title: "Boost Customer Satisfaction",
    description: "Provide instant support and seamless scheduling. Your customers will appreciate the immediate response and professional service, any time of day"
  },
  {
    icon: PhoneForwarded,
    title: "Intelligent Call Routing",
    description: "Advanced AI algorithms automatically direct calls to the right department or specialist based on context, urgency, and customer history"
  },
  {
    icon: Calendar,
    title: "Smart Campaign Management",
    description: "Schedule and execute targeted outbound campaigns with AI-optimized timing and personalized messaging for maximum engagement"
  }
];