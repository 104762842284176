import React, { useEffect, useState, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { getSubscriptionStartDate, fetchCallRecords } from '../services/api';
import { format } from 'date-fns';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Button } from "./ui/button";

const CallTrack = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [callRecords, setCallRecords] = useState([]);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [assistantId, setAssistantId] = useState('');
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSubscriptionDate = async () => {
      if (organizationId) {
        try {
          const subscriptionDate = await getSubscriptionStartDate(organizationId);
          setSubscriptionStartDate(new Date(subscriptionDate));
        } catch (error) {
          console.error('Error fetching subscription start date:', error);
        }
      }
    };

    fetchSubscriptionDate();
  }, [organizationId]);

  useEffect(() => {
    fetchRecords();
  }, [organizationId, month, year, assistantId, currentPage]);

  const fetchRecords = async () => {
    if (!organizationId) return;
    
    setLoading(true);
    try {
      const response = await fetchCallRecords(organizationId, { 
        month: month || undefined, 
        year: year || undefined, 
        assistantId, 
        page: currentPage, 
        size: 10
      });
      setCallRecords(response.content || []);
      setTotalPages(response.totalPages || 1);
    } catch (error) {
      console.error('Error fetching call records:', error);
    } finally {
      setLoading(false);
    }
  };

  const months = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  const years = Array.from(
    { length: new Date().getFullYear() - 2019 + 1 }, 
    (_, i) => (2020 + i).toString()
  );

  const renderFilters = () => (
    <div className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <Input
            placeholder="Assistant ID"
            value={assistantId}
            onChange={(e) => {
              setAssistantId(e.target.value);
              setCurrentPage(1);
            }}
          />
        </div>
        
        {subscriptionStartDate && (
          <>
            <Select
              value={month}
              onValueChange={(value) => {
                setMonth(value);
                setCurrentPage(1);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select Month" />
              </SelectTrigger>
              <SelectContent>
                {months.map((m) => (
                  <SelectItem key={m.value} value={m.value}>
                    {m.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Select
              value={year}
              onValueChange={(value) => {
                setYear(value);
                setCurrentPage(1);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select Year" />
              </SelectTrigger>
              <SelectContent>
                {years.map((year) => (
                  <SelectItem key={year} value={year}>
                    {year}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </>
        )}
      </div>
    </div>
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle>Call Track Records</CardTitle>
      </CardHeader>
      <CardContent>
        {renderFilters()}

        <div className="mt-6">
          {loading ? (
            <div className="text-center py-4">Loading...</div>
          ) : callRecords.length === 0 ? (
            <div className="text-center py-4 text-muted-foreground">
              No call records available.
            </div>
          ) : (
            <>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Call ID</TableHead>
                    <TableHead>Assistant Name</TableHead>
                    <TableHead>Organization Phone</TableHead>
                    <TableHead>Duration (min)</TableHead>
                    <TableHead>Date</TableHead>
                    <TableHead>Caller ID</TableHead>
                    <TableHead>Caller Phone</TableHead>
                    <TableHead className="text-right">Cost</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {callRecords.map((record) => (
                    <TableRow key={record.id}>
                      <TableCell>{record.id}</TableCell>
                      <TableCell>{record.assistantResponse.vapiAssistant.name}</TableCell>
                      <TableCell>{record.organizationPhoneNumberId}</TableCell>
                      <TableCell>{record.durationInMinutes}</TableCell>
                      <TableCell>
                        {format(new Date(record.startTime), 'PPp')}
                      </TableCell>
                      <TableCell>{record.callId}</TableCell>
                      <TableCell>{record.customerPhoneNumber}</TableCell>
                      <TableCell className="text-right">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }).format(record.cost)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <div className="flex justify-center gap-2 mt-4">
                {[...Array(totalPages)].map((_, i) => (
                  <Button
                    key={i}
                    variant={currentPage === i + 1 ? "default" : "outline"}
                    size="sm"
                    onClick={() => setCurrentPage(i + 1)}
                  >
                    {i + 1}
                  </Button>
                ))}
              </div>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default CallTrack;