import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  getOrganizationByUser, 
  saveOrganization, 
  getUser, 
  saveSubscriptionFromPlan,
  getSubscriptions 
} from '../services/api';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Textarea } from "./ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Label } from "./ui/label";
import { Alert, AlertDescription } from "./ui/alert";
import { Loader2, Building2 } from "lucide-react";

const TOP_UP_AMOUNTS = [
  { value: 50, label: '£50' },
  { value: 100, label: '£100' },
  { value: 200, label: '£200' },
  { value: 300, label: '£300' }
];

const Organization = () => {
  const [organization, setOrganization] = useState({
    id: '',
    name: '',
    logo: '',
    briefInformation: '',
    defaultTopUpAmount: 100,
    website: '',
    address: '',
    country: '',
    phoneNumber: '',
    mobileNumber: '',
    user: [],

  });
  const { organizationId, setNavigationEnabled } = useContext(OrganizationContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [hasCheckedOut,setHasCheckedOut] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrganizationAndSubscription = async () => {
      try {
        const email = getUser();
        const fetchedOrganization = await getOrganizationByUser(email);
        
        setOrganization({
          ...fetchedOrganization,
          name: fetchedOrganization.name ?? '',
          logo: fetchedOrganization.logo ?? '',
          briefInformation: fetchedOrganization.briefInformation ?? '',
          defaultTopUpAmount: fetchedOrganization.defaultTopUpAmount ?? 100,
        });

        // Disable navigation if organization ID is null
        setNavigationEnabled(Boolean(fetchedOrganization?.id));

        // Check for existing subscription if organization exists
        if (fetchedOrganization?.id) {
          const subscriptions = await getSubscriptions(fetchedOrganization.id);
          setHasSubscription(subscriptions && subscriptions?.length > 0);
          setHasCheckedOut(subscriptions && 
            Array.isArray(subscriptions) && 
            subscriptions.length > 0 && 
            subscriptions.some(sub => sub.isActive && (sub.stripeSubscriptionId != null) ));
        }
        console.log('HasSubscription='+hasSubscription+" hasCheckedOut"+hasCheckedOut);
      } catch (error) {
        setError('Failed to fetch organization details');
        setNavigationEnabled(false);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizationAndSubscription();
  }, [setNavigationEnabled]);

  const handleChange = (field, value) => {
    setOrganization(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const orgSaved = await saveOrganization(organization);
      
      // Only proceed to subscription/checkout if there's no existing subscription
      if (!hasSubscription) {
        await saveSubscriptionFromPlan(orgSaved);
        navigate('/checkout');
      }
      if (!hasCheckedOut) {
          navigate('/checkout');
      }

    } catch (error) {
      setError('Failed to save organization');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container max-w-2xl mx-auto py-6">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Building2 className="w-6 h-6" />
            Organization Details
          </CardTitle>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-6">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <div>
                <Label htmlFor="name">Organization Name</Label>
                <Input
                  id="name"
                  value={organization.name}
                  onChange={(e) => handleChange('name', e.target.value)}
                  required
                />
              </div>

              <div>
                <Label htmlFor="logo">Organization Logo URL</Label>
                <Input
                  id="logo"
                  value={organization.logo}
                  onChange={(e) => handleChange('logo', e.target.value)}
                  placeholder="Enter logo URL"
                />
              </div>

              <div>
                <Label htmlFor="email">Organization Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={organization.email}
                  onChange={(e) => handleChange('email', e.target.value)}
                />
              </div>

              <div>
                <Label htmlFor="briefInformation">Brief Information</Label>
                <Textarea
                  id="briefInformation"
                  value={organization.briefInformation}
                  onChange={(e) => handleChange('briefInformation', e.target.value)}
                  rows={4}
                  placeholder="Enter organization description"
                />
              </div>

              <div>
                <Label htmlFor="defaultTopUpAmount">Default Top-up Amount</Label>
                <Select
                  value={organization.defaultTopUpAmount.toString()}
                  onValueChange={(value) => handleChange('defaultTopUpAmount', parseInt(value))}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select amount" />
                  </SelectTrigger>
                  <SelectContent>
                    {TOP_UP_AMOUNTS.map((amount) => (
                      <SelectItem key={amount.value} value={amount.value.toString()}>
                        {amount.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div>
              <Label htmlFor="website">Website</Label>
              <Input
                id="website"
                value={organization.website || ''}
                onChange={(e) => handleChange('website', e.target.value)}
                placeholder="Enter website URL"
              />
            </div>

            <div>
              <Label htmlFor="address">Address</Label>
              <Textarea
                id="address"
                value={organization.address || ''}
                onChange={(e) => handleChange('address', e.target.value)}
                rows={3}
                placeholder="Enter organization address"
              />
            </div>

            <div>
              <Label htmlFor="country">Country</Label>
              <Input
                id="country"
                value={organization.country || ''}
                onChange={(e) => handleChange('country', e.target.value)}
                placeholder="Enter country"
              />
            </div>

            <div>
              <Label htmlFor="phoneNumber">Phone Number</Label>
              <Input
                id="phoneNumber"
                value={organization.phoneNumber || ''}
                onChange={(e) => handleChange('phoneNumber', e.target.value)}
                placeholder="Enter phone number"
              />
            </div>

            <div>
              <Label htmlFor="mobileNumber">Mobile Number</Label>
              <Input
                id="mobileNumber"
                value={organization.mobileNumber || ''}
                onChange={(e) => handleChange('mobileNumber', e.target.value)}
                placeholder="Enter mobile number"
              />
            </div>

            </div>

            <Button 
              type="submit" 
              className="w-full"
              disabled={loading}
            >
              {loading ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Saving...
                </>
              ) : (
                'Save Organization'
              )}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default Organization;