import React from 'react';
import { Link } from 'react-router-dom';
import { format, isValid } from 'date-fns';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "./ui/card";
import { Button } from "./ui/button";
import { Badge } from "./ui/badge";
import { CheckCircle, Calendar, Mail, Phone, Clock, MessageSquare } from "lucide-react";

const ThankYou = ({ type, appointment }) => {
  const getTitle = () => {
    switch (type) {
      case 'booked':
        return 'Appointment Booked Successfully!';
      case 'rescheduled':
        return 'Appointment Rescheduled Successfully!';
      case 'cancelled':
        return 'Appointment Cancelled Successfully';
      default:
        return 'Thank You';
    }
  };

  const formatDateTime = (dateTimeString) => {
    try {
      const date = new Date(dateTimeString);
      if (!isValid(date)) {
        return 'Invalid Date';
      }
      return format(date, 'PPpp');
    } catch (error) {
      console.error('Date formatting error:', error);
      return 'Invalid Date';
    }
  };

  const renderAppointmentDetails = () => {
    if (!appointment) return null;

    const dateTimeFormatted = formatDateTime(appointment.appointmentDateTime);
    
    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between p-4 rounded-lg bg-muted/50">
          <div className="flex items-center space-x-2">
            <Calendar className="h-5 w-5 text-primary" />
            <span className="text-sm font-medium">Date and Time</span>
          </div>
          <span className="text-sm">{dateTimeFormatted}</span>
        </div>

        {appointment.contact && (
          <>
            <div className="grid gap-4">
              <div className="flex items-center justify-between p-4 rounded-lg bg-muted/50">
                <div className="flex items-center space-x-2">
                  <span className="h-5 w-5 text-primary" />
                  <span className="text-sm font-medium">User Name</span>
                </div>
                <span className="text-sm">{appointment.contact.name || 'N/A'}</span>
              </div>

              <div className="flex items-center justify-between p-4 rounded-lg bg-muted/50">
                <div className="flex items-center space-x-2">
                  <Mail className="h-5 w-5 text-primary" />
                  <span className="text-sm font-medium">Email</span>
                </div>
                <span className="text-sm">{appointment.contact.email || 'N/A'}</span>
              </div>

              <div className="flex items-center justify-between p-4 rounded-lg bg-muted/50">
                <div className="flex items-center space-x-2">
                  <Phone className="h-5 w-5 text-primary" />
                  <span className="text-sm font-medium">Phone</span>
                </div>
                <span className="text-sm">{appointment.contact.phone || 'N/A'}</span>
              </div>
            </div>
          </>
        )}

        <div className="flex items-center justify-between p-4 rounded-lg bg-muted/50">
          <div className="flex items-center space-x-2">
            <Clock className="h-5 w-5 text-primary" />
            <span className="text-sm font-medium">Timezone</span>
          </div>
          <span className="text-sm">{appointment.timezone || 'N/A'}</span>
        </div>

        {appointment.message && (
          <div className="flex items-start space-x-2 p-4 rounded-lg bg-muted/50">
            <MessageSquare className="h-5 w-5 text-primary mt-0.5" />
            <div className="space-y-1">
              <span className="text-sm font-medium">Message</span>
              <p className="text-sm text-muted-foreground">{appointment.message}</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container max-w-2xl mx-auto py-6">
      <Card>
        <CardHeader className="border-b">
          <div className="flex items-center gap-2">
            <CheckCircle className="h-6 w-6 text-primary" />
            <div>
              <CardTitle>{getTitle()}</CardTitle>
              <CardDescription>
                Please keep this information for your records.
              </CardDescription>
            </div>
          </div>
        </CardHeader>
        <CardContent className="pt-6">
          {renderAppointmentDetails()}

          {type === 'cancelled' && (
            <div className="mt-6 text-center space-y-4">
              <p className="text-muted-foreground">
                Would you like to schedule a new appointment?
              </p>
              <Link to="/appointment-setter">
                <Button className="w-full">
                  <Calendar className="mr-2 h-4 w-4" />
                  Book New Appointment
                </Button>
              </Link>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default ThankYou;
