import React, { useState, useEffect, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { Switch } from '@/components/ui/switch';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { AlertCircle, CheckCircle2, Loader2, Copy } from 'lucide-react';
import {
    getEmailTemplates,
    saveEmailTemplate,
    previewEmailTemplate,
    getDefaultEmailTemplate,
    deleteEmailTemplate,
    getAppointmentSettings
  } from '../services/api';
  

const EmailTemplateEditor = ({ appointmentSettingsId }) => {
  const { organizationId } = useContext(OrganizationContext);  
  const [templates, setTemplates] = useState({});
  const [activeTemplate, setActiveTemplate] = useState('CONFIRMATION');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', content: '' });
  const [appointmentSettings, setAppointmentSettings] = useState([]);
  const [selectedSettingsId, setSelectedSettingsId] = useState('');


  const templateTypes = [
    { value: 'CONFIRMATION', label: 'Confirmation Email' },
    { value: 'CANCELLATION', label: 'Cancellation Email' },
    { value: 'RESCHEDULE', label: 'Reschedule Email' },
    { value: 'REMINDER', label: 'Reminder Email' }
  ];

  const placeholders = [
    { value: '{{clientName}}', label: 'Client Name' },
    { value: '{{appointmentDateTime}}', label: 'Appointment Date & Time' },
    { value: '{{appointmentId}}', label: 'Appointment ID' },
    { value: '{{cancelLink}}', label: 'Cancellation Link' },
    { value: '{{rescheduleLink}}', label: 'Reschedule Link' },
    { value: '{{organizationName}}', label: 'Organization Name' },
    { value: '{{organizationEmail}}', label: 'Organization Email' },
    { value: '{{organizationPhone}}', label: 'Organization Phone' }
  ];


  useEffect(() => {
    fetchAppointmentSettings();
  }, [organizationId]);

  useEffect(() => {
    if (selectedSettingsId) {
      fetchTemplates();
    }
  }, [selectedSettingsId]);
  
  const fetchAppointmentSettings = async () => {
    try {
      const data = await getAppointmentSettings(organizationId);
      // Ensure we're working with an array
      const settingsArray = Array.isArray(data) ? data : [data].filter(Boolean);
      setAppointmentSettings(settingsArray);
      
      if (settingsArray.length > 0) {
        setSelectedSettingsId(settingsArray[0].id.toString());
      }
    } catch (error) {
      setMessage({ type: 'error', content: 'Failed to fetch appointment settings' });
    }
  };

  const fetchTemplates = async () => {
    if (!selectedSettingsId) return;

    try {
      setIsLoading(true);
      const data = await getEmailTemplates(selectedSettingsId);
      setTemplates(data);
    } catch (error) {
      setMessage({ type: 'error', content: 'Failed to fetch templates' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleTemplateChange = async (type) => {
    setActiveTemplate(type);
    
    // If template doesn't exist, load default
    if (!templates[type]) {
      try {
        const defaultTemplate = await getDefaultEmailTemplate(appointmentSettingsId, type);
        setTemplates(prev => ({
          ...prev,
          [type]: defaultTemplate
        }));
      } catch (error) {
        setMessage({ type: 'error', content: 'Failed to load default template' });
      }
    }
  };

  const handleInsertPlaceholder = (placeholder) => {
    const template = templates[activeTemplate];
    const textArea = document.getElementById('htmlContent');
    const start = textArea.selectionStart;
    const end = textArea.selectionEnd;
    const text = textArea.value;
    const before = text.substring(0, start);
    const after = text.substring(end);
    
    setTemplates({
      ...templates,
      [activeTemplate]: {
        ...template,
        htmlContent: before + placeholder + after
      }
    });
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const savedTemplate = await saveEmailTemplate(
        selectedSettingsId,
        {
          type: activeTemplate,
          ...templates[activeTemplate]
        }
      );
      
      setTemplates(prev => ({
        ...prev,
        [activeTemplate]: savedTemplate
      }));
      
      setMessage({ type: 'success', content: 'Template saved successfully' });
    } catch (error) {
      setMessage({ type: 'error', content: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreview = async () => {
    try {
      const previewHtml = await previewEmailTemplate({
        type: activeTemplate,
        ...templates[activeTemplate]
      });
      
      const previewWindow = window.open('', '_blank');
      previewWindow.document.write(previewHtml);
      previewWindow.document.close();
    } catch (error) {
      setMessage({ type: 'error', content: 'Failed to generate preview' });
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <Card>
        <CardHeader>
          <CardTitle>Email Template Editor</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
          {appointmentSettings.length > 0 ? (
            <div className="space-y-2">
              <Label>Select Appointment Settings</Label>
              <Select 
                value={selectedSettingsId} 
                onValueChange={(value) => setSelectedSettingsId(value)}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select appointment settings" />
                </SelectTrigger>
                <SelectContent>
                    {appointmentSettings.map(setting => (
                      <SelectItem 
                        key={setting.id} 
                        value={setting.id.toString()}
                      >
                        {setting.eventName || `Settings ${setting.id}`}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </div>
            ) : (
              <Alert>
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>
                  No appointment settings found. Please create appointment settings first.
                </AlertDescription>
              </Alert>
            )}            
          </div> 
        </CardContent>   
        <CardContent>
          <div className="space-y-6">
            <div>
              <Label>Template Type</Label>
              <Select value={activeTemplate} onValueChange={handleTemplateChange}>
                <SelectTrigger>
                  <SelectValue placeholder="Select template type" />
                </SelectTrigger>
                <SelectContent>
                  {templateTypes.map(type => (
                    <SelectItem key={type.value} value={type.value}>
                      {type.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <div></div>
              {templates[activeTemplate]?.id && (
                      <div className="flex items-center gap-2 bg-gray-100 px-3 py-1 rounded text-sm">
                        <span className="text-gray-500">ID:</span>
                        <span className="font-mono">{templates[activeTemplate].id}</span>
                      </div>
                    )}
            </div>

            <div>
              <Label>Subject</Label>
              <Input
                value={templates[activeTemplate]?.subject || ''}
                onChange={(e) => setTemplates({
                  ...templates,
                  [activeTemplate]: {
                    ...templates[activeTemplate],
                    subject: e.target.value
                  }
                })}
              />
            </div>

            <div>
              <Label>Insert Placeholder</Label>
              <div className="flex gap-2 flex-wrap mt-2">
                {placeholders.map(placeholder => (
                  <Button
                    key={placeholder.value}
                    variant="outline"
                    size="sm"
                    onClick={() => handleInsertPlaceholder(placeholder.value)}
                  >
                    {placeholder.label}
                  </Button>
                ))}
              </div>
            </div>

            <div>
              <Label>HTML Content</Label>
              <Textarea
                id="htmlContent"
                className="min-h-[300px] font-mono"
                value={templates[activeTemplate]?.htmlContent || ''}
                onChange={(e) => setTemplates({
                  ...templates,
                  [activeTemplate]: {
                    ...templates[activeTemplate],
                    htmlContent: e.target.value
                  }
                })}
              />
            </div>

            <div className="flex items-center space-x-2">
              <Switch
                checked={templates[activeTemplate]?.includeCalendarAttachment}
                onCheckedChange={(checked) => setTemplates({
                  ...templates,
                  [activeTemplate]: {
                    ...templates[activeTemplate],
                    includeCalendarAttachment: checked
                  }
                })}
              />
              <Label>Include Calendar Attachment</Label>
            </div>

            <div className="flex space-x-4">
              <Button
                onClick={handleSave}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Saving
                  </>
                ) : 'Save Template'}
              </Button>

              <Button
                variant="outline"
                onClick={handlePreview}
                disabled={isLoading}
              >
                Preview
              </Button>
            </div>

            {message.content && (
              <Alert className={`mt-4 ${message.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
                {message.type === 'error' ? (
                  <AlertCircle className="h-4 w-4" />
                ) : (
                  <CheckCircle2 className="h-4 w-4" />
                )}
                <AlertDescription>{message.content}</AlertDescription>
              </Alert>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default EmailTemplateEditor;