import React from 'react';

export function Privacy() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold mb-8">Privacy Policy</h1>
      
      <div className="space-y-8">
        <section>
          <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
          <p className="text-gray-600 mb-4">
            This Privacy Policy explains how AI Phone Agent ("we," "our," or "us") collects, uses, and protects your personal information.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">2. Data We Collect</h2>
          <ul className="list-disc pl-6 text-gray-600 space-y-2">
            <li>Contact information (name, email, phone number)</li>
            <li>Company information</li>
            <li>Call recordings and transcripts</li>
            <li>Usage data and analytics</li>
            <li>Technical information (IP address, browser type)</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">3. GDPR Compliance</h2>
          <p className="text-gray-600 mb-4">
            Under GDPR, you have the following rights:
          </p>
          <ul className="list-disc pl-6 text-gray-600 space-y-2">
            <li>Right to access your personal data</li>
            <li>Right to rectification</li>
            <li>Right to erasure ("right to be forgotten")</li>
            <li>Right to restrict processing</li>
            <li>Right to data portability</li>
            <li>Right to object</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">4. Data Protection</h2>
          <p className="text-gray-600 mb-4">
            We implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">5. Contact Us</h2>
          <p className="text-gray-600">
            For any privacy-related questions or requests, please contact our Data Protection Officer at dpo@aiphoneagent.com
          </p>
        </section>
      </div>
    </div>
  );
}