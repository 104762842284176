import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { createCheckoutSession, getPaymentHistory } from '../services/api';
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from './ui/card';
import { Button } from './ui/button';
import { Spinner } from './ui/spinner';
import { CheckCircle, XCircle } from 'lucide-react';
import { Alert } from './ui/alert';

const PRODUCTS = [
  {
    id: 'basic',
    name: 'Basic Plan',
    priceId: process.env.REACT_APP_BASIC_PRICE_ID,
    description: 'Basic features for small businesses',
    price: '$9.99/month'
  },
  {
    id: 'pro',
    name: 'Pro Plan',
    priceId: process.env.REACT_APP_PRO_PRICE_ID,
    description: 'Advanced features for growing businesses',
    price: '$29.99/month'
  }
];

const SuccessPage = () => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { organizationId } = useContext(OrganizationContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        setLoading(true);
        const searchParams = new URLSearchParams(location.search);
        const sessionId = searchParams.get('session_id');

        if (!sessionId) {
          throw new Error('No session ID found');
        }

        if (!organizationId) {
          console.log('Waiting for organization ID...');
          return; // Exit early if organizationId is not available yet
        }

        console.log("Fetching payment history for organization:", organizationId);
        const paymentHistory = await getPaymentHistory(organizationId);
        console.log("Payment history received:", paymentHistory);

        if (!paymentHistory || paymentHistory.length === 0) {
          throw new Error('No payment history found');
        }

        const latestPayment = paymentHistory[0];
        console.log("Latest payment:", latestPayment);

        setPaymentDetails({
          sessionId,
          amount: latestPayment.amount,
          currency: latestPayment.currency || 'GBP',
          status: latestPayment.status,
          date: new Date(latestPayment.paymentDate).toLocaleDateString(),
          paymentType: latestPayment.paymentType,
          referenceId: latestPayment.stripePaymentIntentId
        });

        setError(null);
      } catch (err) {
        console.error('Error in fetchPaymentDetails:', err);
        setError('Failed to load payment details. Please contact support.');
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentDetails();
  }, [organizationId, location.search]);

  useEffect(() => {
    // Debug logging for context values
    console.log("Organization ID from context:", organizationId);
  }, [organizationId]);

  if (!organizationId) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loading">Initializing payment details...</div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loading">Loading payment details...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Card className="max-w-md p-6 rounded-lg shadow-lg bg-white">
          <CardHeader>
            <div className="flex items-center space-x-2">
              <XCircle className="h-6 w-6 text-red-500" />
              <CardTitle>Error Loading Payment Details</CardTitle>
            </div>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600 mb-4">{error}</p>
            <p className="text-gray-600 mb-4">Organization ID: {organizationId}</p>
            <Button onClick={() => navigate('/')} className="w-full">
              Return to Dashboard
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <Card className="max-w-md p-6 rounded-lg shadow-lg bg-white">
        <CardHeader>
          <div className="flex items-center space-x-2">
            <CheckCircle className="h-6 w-6 text-green-500" />
            <CardTitle>Payment Successful!</CardTitle>
          </div>
        </CardHeader>
        <CardContent>
          {paymentDetails && (
            <div className="space-y-4">
              <h3 className="text-xl font-bold">Payment Details</h3>
              <div className="flex justify-between">
                <span className="text-gray-600">Amount:</span>
                <span className="font-medium">
                  {new Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency: paymentDetails.currency
                  }).format(paymentDetails.amount)}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Date:</span>
                <span className="font-medium">{paymentDetails.date}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Payment Type:</span>
                <span className="font-medium">{paymentDetails.paymentType}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Reference ID:</span>
                <span className="font-medium">{paymentDetails.referenceId}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Status:</span>
                <span className="font-medium text-green-500">{paymentDetails.status}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Organization ID:</span>
                <span className="font-medium">{organizationId}</span>
              </div>
            </div>
          )}
          <p className="text-gray-600 mt-4">You will receive a confirmation email shortly.</p>
          <Button onClick={() => navigate('/')} className="w-full mt-4">
            Return to Dashboard
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

const FailedPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center h-screen">
      <Card className="max-w-md p-6 rounded-lg shadow-lg bg-white">
        <CardHeader>
          <div className="flex items-center space-x-2">
            <XCircle className="h-6 w-6 text-red-500" />
            <CardTitle>Payment Failed</CardTitle>
          </div>
        </CardHeader>
        <CardContent>
          <p className="text-gray-600 mb-4">Unfortunately, your payment was not successful.</p>
          <div className="bg-red-100 p-4 rounded-lg mb-4">
            <p className="text-red-600 mb-2">This could be due to:</p>
            <ul className="list-disc pl-6 text-red-600">
              <li>Insufficient funds</li>
              <li>Expired card</li>
              <li>Invalid card details</li>
              <li>Bank declined transaction</li>
            </ul>
          </div>
          <p className="text-gray-600 mb-4">Please try again or contact your bank for more information.</p>
          <div className="flex space-x-4 justify-center">
            <Button onClick={() => navigate('/stripe-payment-component')} className="flex-1">
              Try Again
            </Button>
            <Button onClick={() => navigate('/')} className="flex-1">
              Return to Dashboard
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { organizationId } = useContext(OrganizationContext);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || !selectedProduct) {
      console.log('Missing required elements:', { 
        stripe: !!stripe, 
        elements: !!elements, 
        selectedProduct: !!selectedProduct 
      });
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const { sessionId } = await createCheckoutSession(organizationId, selectedProduct.priceId);
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        setError(error.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="subscription-container max-w-2xl mx-auto">
      <Card>
        <CardHeader>
          <CardTitle>Select a Subscription Plan</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {PRODUCTS.map((product) => (
              <div 
                key={product.id}
                className={`product-card rounded-lg shadow-lg p-4 cursor-pointer ${selectedProduct?.id === product.id ? 'bg-primary text-white' : 'bg-white hover:bg-gray-100'}`}
                onClick={() => setSelectedProduct(product)}
              >
                <div className="space-y-2">
                  <h3 className="text-xl font-bold">{product.name}</h3>
                  <p className="text-gray-600">{product.description}</p>
                  <p className="text-2xl font-bold">{product.price}</p>
                </div>
              </div>
            ))}
          </div>

          {error && (
            <div className="mt-4">
              <Alert variant="danger">{error}</Alert>
            </div>
          )}

          {selectedProduct && (
            <form onSubmit={handleSubmit} className="mt-4">
              <Button 
                type="submit"
                disabled={!stripe || isLoading}
                className="w-full"
              >
                {isLoading ? (
                  <>
                    <Spinner size="sm" className="mr-2" />
                    Processing...
                  </>
                ) : (
                  `Subscribe to ${selectedProduct.name}`
                )}
              </Button>
            </form>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

const StripePaymentComponent = () => {
  const location = useLocation();

  if (location.pathname.includes('/success')) {
    return <SuccessPage />;
  }

  if (location.pathname.includes('/canceled')) {
    return <FailedPage />;
  }

  return <PaymentForm />;
};

export default StripePaymentComponent;