import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getVAPIAssistant, createVAPIAssistant, updateVAPIAssistant } from '../services/api';

import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Textarea } from "./ui/textarea";
import { Switch } from "./ui/switch";
import { Label } from "./ui/label";
import { Alert, AlertDescription } from "./ui/alert";
import { ScrollArea } from "./ui/scroll-area";

const DEFAULT_ASSISTANT = {
  name: '',
  transcriber: {
    provider: 'deepgram',
    model: 'nova-2'
  },
  model: {
    provider: 'openai',
    model: 'gpt-4',
    messages: [
      {
        role: 'system',
        content: "You're an AI assistant. How can I help you today?"
      }
    ],
    toolIds: [
      '66dd97bf-2386-4467-aa1b-68c3cefdee6c',
      '7bac242a-dd7f-49f6-9ea5-2368faf6ea6f',
      '107a01c9-70d9-4a86-abd2-1702a7c10e6f'
    ],
    tools: [            
      {
        async: 'false', 
        type: 'function', 
        name: 'check_availability',
        description: 'Check availability for an appointment',
        parameters: {
          organizationId: 'string',
          preferredDateTime: 'string'
        }
      },
      {
        async: 'false',  
        type: 'function',  
        name: 'book_appointment',
        description: 'Book an appointment',
        parameters: {
          organizationId: 'string',
          appointmentDateTime: 'string',
          customerEmail: 'string'
        }
      },
      {
        async: 'false',  
        type: 'function',  
        name: 'convert_spoken_datetime',
        description: 'Convert a spoken date and time to ISO 8601 format',
        parameters: {
          spokenDateTime: 'string'
        }
      }  
    ]        
  },
  voice: {
    provider: '11labs',
    voiceId: 'marissa'
  },
  firstMessageMode: 'assistant-speaks-first',
  recordingEnabled: true,
  hipaaEnabled: false,
  clientMessages: [
    'conversation-update', 'function-call', 'hang', 'model-output',
    'speech-update', 'status-update', 'transcript', 'tool-calls',
    'user-interrupted', 'voice-input'
  ],
  serverMessages: [
    'conversation-update', 'end-of-call-report', 'function-call',
    'hang', 'speech-update', 'status-update', 'tool-calls',
    'transfer-destination-request', 'user-interrupted'
  ],
  silenceTimeoutSeconds: 30,
  maxDurationSeconds: 600,
  backgroundSound: 'off',
  backchannelingEnabled: false,
  backgroundDenoisingEnabled: false,
  modelOutputInMessagesEnabled: false,
  transportConfigurations: [
    { provider: 'twilio', timeout: 60, record: false, recordingChannels: 'mono' }
  ],
  firstMessage: '',
  voicemailDetection: { provider: 'twilio', enabled: false },
  voicemailMessage: '',
  endCallMessage: '',
  endCallPhrases: [],
  metadata: {},
  serverUrl: 'https://distributewithease.com:8080/api/vapi-webhook',
  serverUrlSecret: '',
  analysisPlan: {},
  artifactPlan: {},
  messagePlan: {},
  startSpeakingPlan: {},
  stopSpeakingPlan: {},
  credentialIds: []
};

const AssistantForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [assistant, setAssistant] = useState(DEFAULT_ASSISTANT);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (id) {
      fetchAssistant();
    }
  }, [id]);

  const fetchAssistant = async () => {
    try {
      setLoading(true);
      const data = await getVAPIAssistant(id);
      setAssistant(data);
    } catch (error) {
      setError('Failed to fetch assistant');
      console.error('Error fetching assistant:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field, value) => {
    setAssistant(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleJsonChange = (field, value) => {
    try {
      const parsedValue = JSON.parse(value);
      handleChange(field, parsedValue);
    } catch (error) {
      console.error(`Invalid JSON for ${field}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const formattedAssistant = {
        ...assistant,
        transcriber: typeof assistant.transcriber === 'string' 
          ? assistant.transcriber 
          : JSON.stringify(assistant.transcriber),
        model: typeof assistant.model === 'string' 
          ? assistant.model 
          : JSON.stringify(assistant.model),
        voice: typeof assistant.voice === 'string' 
          ? assistant.voice 
          : JSON.stringify(assistant.voice),
        clientMessages: typeof assistant.clientMessages === 'string' 
          ? assistant.clientMessages 
          : JSON.stringify(assistant.clientMessages),
        serverMessages: typeof assistant.serverMessages === 'string' 
          ? assistant.serverMessages 
          : JSON.stringify(assistant.serverMessages),
        transportConfigurations: typeof assistant.transportConfigurations === 'string' 
          ? assistant.transportConfigurations 
          : JSON.stringify(assistant.transportConfigurations),
        voicemailDetection: typeof assistant.voicemailDetection === 'string' 
          ? assistant.voicemailDetection 
          : JSON.stringify(assistant.voicemailDetection),
        endCallPhrases: typeof assistant.endCallPhrases === 'string' 
          ? assistant.endCallPhrases 
          : JSON.stringify(assistant.endCallPhrases),
        metadata: typeof assistant.metadata === 'string' 
          ? assistant.metadata 
          : JSON.stringify(assistant.metadata),
        analysisPlan: typeof assistant.analysisPlan === 'string' 
          ? assistant.analysisPlan 
          : JSON.stringify(assistant.analysisPlan),
        artifactPlan: typeof assistant.artifactPlan === 'string' 
          ? assistant.artifactPlan 
          : JSON.stringify(assistant.artifactPlan),
        messagePlan: typeof assistant.messagePlan === 'string' 
          ? assistant.messagePlan 
          : JSON.stringify(assistant.messagePlan),
        startSpeakingPlan: typeof assistant.startSpeakingPlan === 'string' 
          ? assistant.startSpeakingPlan 
          : JSON.stringify(assistant.startSpeakingPlan),
        stopSpeakingPlan: typeof assistant.stopSpeakingPlan === 'string' 
          ? assistant.stopSpeakingPlan 
          : JSON.stringify(assistant.stopSpeakingPlan),
        credentialIds: typeof assistant.credentialIds === 'string' 
          ? assistant.credentialIds 
          : JSON.stringify(assistant.credentialIds)
      };

      if (id) {
        await updateVAPIAssistant(id, formattedAssistant);
      } else {
        await createVAPIAssistant(formattedAssistant);
      }
      navigate('/vapi-assistants');
    } catch (error) {
      setError('Failed to save assistant');
      console.error('Error saving assistant:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading && id) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-lg text-muted-foreground">Loading assistant...</div>
      </div>
    );
  }

  const JsonField = ({ label, field, value, rows = 4 }) => (
    <div className="space-y-2">
      <Label htmlFor={field}>{label}</Label>
      <Textarea
        id={field}
        value={JSON.stringify(value, null, 2)}
        onChange={(e) => handleJsonChange(field, e.target.value)}
        className="font-mono"
        rows={rows}
      />
    </div>
  );

  return (
    <ScrollArea className="h-[calc(100vh-8rem)]">
      <div className="container max-w-4xl mx-auto py-6">
        <Card>
          <CardHeader>
            <CardTitle>{id ? 'Edit' : 'Create'} Assistant</CardTitle>
          </CardHeader>
          <CardContent>
            {error && (
              <Alert variant="destructive" className="mb-6">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-4">
                <div>
                  <Label htmlFor="name">Name</Label>
                  <Input
                    id="name"
                    value={assistant.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                    required
                  />
                </div>

                <JsonField 
                  label="Transcriber Configuration"
                  field="transcriber"
                  value={assistant.transcriber}
                  rows={4}
                />

                <JsonField 
                  label="Model Configuration"
                  field="model"
                  value={assistant.model}
                  rows={8}
                />

                <JsonField 
                  label="Voice Configuration"
                  field="voice"
                  value={assistant.voice}
                  rows={4}
                />

                <div>
                  <Label>First Message Mode</Label>
                  <Select
                    value={assistant.firstMessageMode}
                    onValueChange={(value) => handleChange('firstMessageMode', value)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select mode" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="assistant-speaks-first">
                        Assistant Speaks First
                      </SelectItem>
                      <SelectItem value="user-speaks-first">
                        User Speaks First
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <Switch
                      id="recordingEnabled"
                      checked={assistant.recordingEnabled}
                      onCheckedChange={(checked) => 
                        handleChange('recordingEnabled', checked)
                      }
                    />
                    <Label htmlFor="recordingEnabled">Recording Enabled</Label>
                  </div>

                  <div className="flex items-center space-x-2">
                    <Switch
                      id="hipaaEnabled"
                      checked={assistant.hipaaEnabled}
                      onCheckedChange={(checked) => 
                        handleChange('hipaaEnabled', checked)
                      }
                    />
                    <Label htmlFor="hipaaEnabled">HIPAA Enabled</Label>
                  </div>
                </div>

                <JsonField 
                  label="Client Messages"
                  field="clientMessages"
                  value={assistant.clientMessages}
                  rows={4}
                />

                <JsonField 
                  label="Server Messages"
                  field="serverMessages"
                  value={assistant.serverMessages}
                  rows={4}
                />

                <div>
                  <Label htmlFor="silenceTimeoutSeconds">
                    Silence Timeout (seconds)
                  </Label>
                  <Input
                    id="silenceTimeoutSeconds"
                    type="number"
                    value={assistant.silenceTimeoutSeconds}
                    onChange={(e) => handleChange('silenceTimeoutSeconds', 
                      parseInt(e.target.value))}
                  />
                </div>

                <div>
                  <Label htmlFor="maxDurationSeconds">
                    Max Duration (seconds)
                  </Label>
                  <Input
                    id="maxDurationSeconds"
                    type="number"
                    value={assistant.maxDurationSeconds}
                    onChange={(e) => handleChange('maxDurationSeconds', 
                      parseInt(e.target.value))}
                  />
                </div>

                <div>
                  <Label htmlFor="backgroundSound">Background Sound</Label>
                  <Input
                    id="backgroundSound"
                    value={assistant.backgroundSound}
                    onChange={(e) => handleChange('backgroundSound', e.target.value)}
                  />
                </div>

                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <Switch
                      id="backchannelingEnabled"
                      checked={assistant.backchannelingEnabled}
                      onCheckedChange={(checked) => 
                        handleChange('backchannelingEnabled', checked)
                      }
                    />
                    <Label htmlFor="backchannelingEnabled">
                      Backchanneling Enabled
                    </Label>
                  </div>

                  <div className="flex items-center space-x-2">
                    <Switch
                      id="backgroundDenoisingEnabled"
                      checked={assistant.backgroundDenoisingEnabled}
                      onCheckedChange={(checked) => 
                        handleChange('backgroundDenoisingEnabled', checked)
                      }
                    />
                    <Label htmlFor="backgroundDenoisingEnabled">
                      Background Denoising Enabled
                    </Label>
                  </div>

                  <div className="flex items-center space-x-2">
                    <Switch
                      id="modelOutputInMessagesEnabled"
                      checked={assistant.modelOutputInMessagesEnabled}
                      onCheckedChange={(checked) => 
                        handleChange('modelOutputInMessagesEnabled', checked)
                      }
                    />
                    <Label htmlFor="modelOutputInMessagesEnabled">
                      Model Output In Messages Enabled
                    </Label>
                  </div>
                </div>

                <JsonField 
                  label="Transport Configurations"
                  field="transportConfigurations"
                  value={assistant.transportConfigurations}
                  rows={4}
                />

                <div>
                  <Label htmlFor="firstMessage">First Message</Label>
                  <Input
                    id="firstMessage" 
                    value={assistant.firstMessage}
                    onChange={(e) => handleChange('firstMessage', e.target.value)}
                  />
                </div>

                <JsonField 
                  label="Voicemail Detection"
                  field="voicemailDetection"
                  value={assistant.voicemailDetection}
                  rows={4}
                />

                <div>
                  <Label htmlFor="voicemailMessage">Voicemail Message</Label>
                  <Input
                    id="voicemailMessage"
                    value={assistant.voicemailMessage}
                    onChange={(e) => handleChange('voicemailMessage', e.target.value)}
                  />
                </div>

                <div>
                  <Label htmlFor="endCallMessage">End Call Message</Label>
                  <Input
                    id="endCallMessage"
                    value={assistant.endCallMessage}
                    onChange={(e) => handleChange('endCallMessage', e.target.value)}
                  />
                </div>

                <JsonField 
                  label="End Call Phrases"
                  field="endCallPhrases"
                  value={assistant.endCallPhrases}
                  rows={4}
                />

                <JsonField 
                  label="Metadata"
                  field="metadata"
                  value={assistant.metadata}
                  rows={4}
                />

                <div>
                  <Label htmlFor="serverUrl">Server URL</Label>
                  <Input
                    id="serverUrl"
                    value={assistant.serverUrl}
                    onChange={(e) => handleChange('serverUrl', e.target.value)}
                  />
                </div>

                <div>
                  <Label htmlFor="serverUrlSecret">Server URL Secret</Label>
                  <Input
                    id="serverUrlSecret"
                    type="password"
                    value={assistant.serverUrlSecret}
                    onChange={(e) => handleChange('serverUrlSecret', e.target.value)}
                  />
                </div>

                <JsonField 
                  label="Analysis Plan"
                  field="analysisPlan"
                  value={assistant.analysisPlan}
                  rows={4}
                />

                <JsonField 
                  label="Artifact Plan"
                  field="artifactPlan"
                  value={assistant.artifactPlan}
                  rows={4}
                />

                <JsonField 
                  label="Message Plan"
                  field="messagePlan"
                  value={assistant.messagePlan}
                  rows={4}
                />

                <JsonField 
                  label="Start Speaking Plan"
                  field="startSpeakingPlan"
                  value={assistant.startSpeakingPlan}
                  rows={4}
                />

                <JsonField 
                  label="Stop Speaking Plan"
                  field="stopSpeakingPlan"
                  value={assistant.stopSpeakingPlan}
                  rows={4}
                />

                <JsonField 
                  label="Credential IDs"
                  field="credentialIds"
                  value={assistant.credentialIds}
                  rows={4}
                />
              </div>

              <div className="flex justify-end space-x-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => navigate('/vapi-assistants')}
                >
                  Cancel
                </Button>
                <Button 
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <span>Saving...</span>
                  ) : (
                    <span>{id ? 'Update' : 'Create'} Assistant</span>
                  )}
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </ScrollArea>
  );
};

export default AssistantForm;