import React from 'react';
import { FeaturesList } from '../Features';
import { BenefitsList } from '../Benefits';
import { WaveBackground } from './WaveBackground';
import './Hero.css';

export function Hero() {
  return (
    <>
      {/* Hero Section - Keep the wave background */}
      <section className="hero-section relative overflow-hidden">
        <WaveBackground />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="text-left">
              <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
                Transform Your <span className="text-indigo-600">Customer Service</span> with AI
              </h1>
              <p className="text-xl text-gray-600 mb-8">
                Handle calls intelligently, automate responses, and deliver exceptional customer experiences 24/7.
              </p>
              <div className="flex gap-4">
                <button className="bg-indigo-600 text-white px-8 py-3 rounded-md hover:bg-indigo-700 shadow-lg transition-all">
                  Start Free Trial
                </button>
                <button className="border-2 border-indigo-600 text-indigo-600 px-8 py-3 rounded-md hover:bg-indigo-50 transition-all">
                  Watch Demo
                </button>
              </div>
            </div>
            <div className="relative">
              <div className="relative rounded-lg overflow-hidden shadow-2xl">
                <img 
                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d"
                  alt="Professional smiling business person"
                  className="w-full h-[600px] object-cover rounded-lg"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-indigo-600/20 to-transparent"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section - Gradient background */}
      <section className="relative py-20 bg-gradient-to-b from-white via-indigo-50 to-white">
        <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <BenefitsList />
        </div>
      </section>

      {/* Features Section - Subtle pattern background */}
      <section className="relative py-20 bg-gray-900 text-white">
        <div className="absolute inset-0 opacity-10 bg-[radial-gradient(circle_at_1px_1px,rgba(255,255,255,0.15)_1px,transparent_0)]" style={{ backgroundSize: '24px 24px' }}></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <FeaturesList />
        </div>
      </section>
    </>
  );
}