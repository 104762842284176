import React from 'react';
import './WaveBackground.css';

export function WaveBackground() {
  return (
    <div className="wave-container">
      <div className="wave wave1"></div>
      <div className="wave wave2"></div>
      <div className="wave wave3"></div>
    </div>
  );
}