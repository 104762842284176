import {API_BASE_URL, getJsonHeaders, getAuthToken } from './api';

export const handleResponse = async (response) => {
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'An error occurred');
    }
    return response.status === 204 ? null : response.json(); // Handle no-content responses
  };

export const getSchedules = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/webhook-sync/schedules`, {
        method: 'GET',
        headers: getJsonHeaders(getAuthToken())
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error fetching schedules:', error);
      throw error;
    }
  };

  export const updateSchedule = async (scheduleId, scheduleData) => {
    try {
      const response = await fetch(`${API_BASE_URL}/webhook-sync/schedules/${scheduleId}`, {
        method: 'PUT',
        headers: getJsonHeaders(getAuthToken()),
        body: JSON.stringify(scheduleData)
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error updating schedule:', error);
      throw error;
    }
  };
  
  export const toggleSchedule = async (scheduleId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/webhook-sync/schedules/${scheduleId}/toggle`, {
        method: 'POST',
        headers: getJsonHeaders(getAuthToken())
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error toggling schedule:', error);
      throw error;
    }
  };
  
  export const startSync = async (scheduleId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/webhook-sync/sync/start/${scheduleId}`, {
        method: 'POST',
        headers: getJsonHeaders(getAuthToken())
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error starting sync:', error);
      throw error;
    }
  };
  
  export const stopSync = async (runId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/webhook-sync/sync/stop/${runId}`, {
        method: 'POST',
        headers: getJsonHeaders(getAuthToken())
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error stopping sync:', error);
      throw error;
    }
  };
  
  export const getSyncRuns = async (params = {}) => {
    try {
      const { 
        scheduleId,
        status,
        startDate,
        endDate,
        page = 0,
        size = 10
      } = params;
  
      const queryParams = new URLSearchParams();
    
      if (scheduleId) queryParams.append('scheduleId', scheduleId);
      if (status) queryParams.append('status', status);
      if (startDate) queryParams.append('startDate', startDate.toISOString());
      if (endDate) queryParams.append('endDate', endDate.toISOString());
      queryParams.append('page', page);
      queryParams.append('size', size);
  
      const response = await fetch(`${API_BASE_URL}/webhook-sync/runs?${queryParams}`, {
        method: 'GET',
        headers: getJsonHeaders(getAuthToken())
      });
  
      return handleResponse(response);
    } catch (error) {
      console.error('Error fetching sync runs:', error);
      throw error;
    }
  };
