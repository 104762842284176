import React, { useState, useContext } from 'react';
import { Bell } from 'lucide-react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./ui/popover";
import { Button } from "./ui/button";
import { format } from 'date-fns';
import { NotificationContext } from '../contexts/NotificationContext';

export const NotificationBell = () => {
  const [open, setOpen] = useState(false);   
  const notificationContext = useContext(NotificationContext);
  
  if (!notificationContext) {
    return null;
  }
  
  const { notifications, unreadCount, markAsRead, markAllAsRead, clearNotifications } = notificationContext;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="icon" className="relative">
          <Bell className="h-5 w-5" />
          {unreadCount > 0 && (
            <span className="absolute -top-1 -right-1 h-5 w-5 rounded-full bg-red-500 text-white text-xs flex items-center justify-center">
              {unreadCount}
            </span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent 
        className="w-[380px] p-0 overflow-hidden" 
        align="end" 
        sideOffset={5}
      >
        <div className="flex flex-col h-[400px]">
          <div className="flex items-center justify-between p-4 border-b bg-white">
            <h4 className="font-semibold text-lg min-w-[120px]">Notifications</h4>
            <div className="flex items-center space-x-2 flex-shrink-0">
              <Button 
                variant="ghost" 
                size="sm" 
                onClick={markAllAsRead}
                className="text-sm px-2 py-1"
              >
                Mark all read
              </Button>
              <Button 
                variant="ghost" 
                size="sm" 
                onClick={clearNotifications}
                className="text-sm px-2 py-1"
              >
                Clear all
              </Button>
            </div>
          </div>
          
          <div className="flex-1 overflow-auto">
            {notifications.length === 0 ? (
              <div className="p-4 text-center text-gray-500">
                No notifications
              </div>
            ) : (
              <div className="divide-y">
                {notifications.map((notification, index) => (
                  <div
                    key={index}
                    className={`p-4 hover:bg-gray-50 cursor-pointer ${
                      !notification.read ? 'bg-blue-50' : ''
                    }`}
                    onClick={() => markAsRead(index)}
                  >
                    <div className="flex justify-between items-start mb-1">
                      <span className="font-medium">{notification.title}</span>
                      <span className="text-xs text-gray-500">
                        {format(notification.timestamp, 'HH:mm')}
                      </span>
                    </div>
                    <p className="text-sm text-gray-600 break-words">{notification.message}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};