import React, { useEffect, useState, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { 
  searchContacts, 
  createContact, 
  deleteContact, 
  importContacts,
  updateOptOutPreferences,
  toggleCampaignSelection 
} from '../services/api';
import { Card, CardContent, CardHeader } from './ui/card';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table";
import { Checkbox } from './ui/checkbox';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";
import { Upload } from 'lucide-react';
import { Alert, AlertDescription } from "./ui/alert";
import SMSButton from './SMSDialog';

const ContactManagement = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [contacts, setContacts] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchEmail, setSearchEmail] = useState('');
  const [searchPhone, setSearchPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showImportDialog, setShowImportDialog] = useState(false);
  const [importFile, setImportFile] = useState(null);

  // Form state
  const [newContact, setNewContact] = useState({
    clientName: '',
    clientEmail: '',
    clientPhone: '',
    clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  });

  
  const fetchContacts = async () => {
    if (!organizationId) return;
    
    setLoading(true);
    try {
      const data = await searchContacts(organizationId, {
        page: currentPage - 1,
        size: 10,
        email: searchEmail,
        phone: searchPhone
      });
      
      setContacts(data.content);
      
      // Initialize checkbox states right after fetching contacts
      const states = data.content.reduce((acc, contact) => {
        acc[contact.id] = {
          emailOptOut: Boolean(contact.emailOptOut),
          smsOptOut: Boolean(contact.smsOptOut),
          phoneOptOut: Boolean(contact.phoneOptOut),
          aiPhoneOptOut: Boolean(contact.aiPhoneOptOut),
          aiPhonePermissionGranted: Boolean(contact.aiPhonePermissionGranted)
        };
        return acc;
      }, {});
      setCheckboxStates(states);
      
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setError('Failed to fetch contacts');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [organizationId, currentPage, searchEmail, searchPhone]);


  const handleCreateContact = async (e) => {
    e.preventDefault();
    
    try {
      await createContact(organizationId, newContact);
      
      // Reset form and refresh contacts
      setNewContact({
        clientName: '',
        clientEmail: '',
        clientPhone: '',
        clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      fetchContacts();
    } catch (error) {
      console.error('Error creating contact:', error);
      setError('Failed to create contact');
    }
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await deleteContact(contactId);
      fetchContacts();
    } catch (error) {
      console.error('Error deleting contact:', error);
      setError('Failed to delete contact');
    }
  };

  const handleImportFile = async () => {
    if (!importFile) return;

    const formData = new FormData();
    formData.append('file', importFile);

    try {
      await importContacts(organizationId, formData);
      setShowImportDialog(false);
      fetchContacts();
    } catch (err) {
      setError('Failed to import contacts. Please check your file format.');
    }
  };

  const mapDatabaseToBoolean = (value) => value === 1 || value === '1' || value === true;
  const mapBooleanToDatabase = (value) => value ? 1 : 0;

  const handleOptOutChange = async (contactId, field, value) => {
    try {
      const contact = contacts.find(c => c.id === contactId);
      if (!contact) return;
      
      // Create preferences object with mapped values
      const preferences = {
        emailOptOut: field === 'emailOptOut' ? mapBooleanToDatabase(value) : mapBooleanToDatabase(contact.emailOptOut),
        smsOptOut: field === 'smsOptOut' ? mapBooleanToDatabase(value) : mapBooleanToDatabase(contact.smsOptOut),
        phoneOptOut: field === 'phoneOptOut' ? mapBooleanToDatabase(value) : mapBooleanToDatabase(contact.phoneOptOut),
        aiPhoneOptOut: field === 'aiPhoneOptOut' ? mapBooleanToDatabase(value) : mapBooleanToDatabase(contact.aiPhoneOptOut),
        aiPhonePermissionGranted: field === 'aiPhonePermissionGranted' ? mapBooleanToDatabase(value) : mapBooleanToDatabase(contact.aiPhonePermissionGranted)
      };
  
      const updated = await updateOptOutPreferences(contactId, preferences);
  
      // Update the local state with properly mapped values
      setContacts(prevContacts => 
        prevContacts.map(c => 
          c.id === contactId 
            ? {
                ...c,
                emailOptOut: mapDatabaseToBoolean(updated.emailOptOut),
                smsOptOut: mapDatabaseToBoolean(updated.smsOptOut),
                phoneOptOut: mapDatabaseToBoolean(updated.phoneOptOut),
                aiPhoneOptOut: mapDatabaseToBoolean(updated.aiPhoneOptOut),
                aiPhonePermissionGranted: mapDatabaseToBoolean(updated.aiPhonePermissionGranted)
              }
            : c
        )
      );
    } catch (err) {
      console.error('Failed to update preferences:', err);
      setError('Failed to update preferences');
    }
  };
  

  const handleToggleCampaign = async (contactId) => {
    try {
      await toggleCampaignSelection(contactId);
      fetchContacts();
    } catch (err) {
      setError('Cannot add contact to campaign due to opt-out or missing permission');
    }
  };

  return (
    <div className="space-y-6">
      {error && (
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Contact Management</h1>
        <Button onClick={() => setShowImportDialog(true)} className="flex items-center gap-2">
          <Upload className="w-4 h-4" />
          Import Contacts
        </Button>
      </div>

      <Card>
        <CardHeader>
          <h2 className="text-2xl font-bold">Create New Contact</h2>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleCreateContact} className="space-y-4">
            <Input
              placeholder="Name"
              value={newContact.clientName}
              onChange={(e) => setNewContact({...newContact, clientName: e.target.value})}
              required
            />
            <Input
              type="email"
              placeholder="Email"
              value={newContact.clientEmail}
              onChange={(e) => setNewContact({...newContact, clientEmail: e.target.value})}
              required
            />
            <Input
              placeholder="Phone"
              value={newContact.clientPhone}
              onChange={(e) => setNewContact({...newContact, clientPhone: e.target.value})}
              required
            />
            <Button type="submit">Create Contact</Button>
          </form>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <h2 className="text-2xl font-bold">Contact List</h2>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex gap-4">
              <Input
                placeholder="Search by email"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
              />
              <Input
                placeholder="Search by phone"
                value={searchPhone}
                onChange={(e) => setSearchPhone(e.target.value)}
              />
            </div>

            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Phone</TableHead>
                  <TableHead>Source</TableHead>
                  <TableHead>Opt-Out Preferences</TableHead>
                  <TableHead>AI Call Permission</TableHead>
                  <TableHead>Campaign</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {contacts.map((contact) => (
                  <TableRow key={contact.id}>
                    <TableCell>{contact.name}</TableCell>
                    <TableCell>{contact.email}</TableCell>
                    <TableCell>{contact.phone}</TableCell>
                    <TableCell>{contact.source}</TableCell>
                    <TableCell>
                      <div className="space-y-2">
                        <div className="flex items-center gap-2">
                          <Checkbox 
                            id={`email-${contact.id}`}
                            checked={checkboxStates[contact.id]?.emailOptOut ?? false}
                            onCheckedChange={(checked) => {
                              setCheckboxStates(prev => ({
                                ...prev,
                                [contact.id]: {
                                  ...prev[contact.id],
                                  emailOptOut: checked
                                }
                              }));
                              handleOptOutChange(contact.id, 'emailOptOut', checked);
                            }}
                            className="data-[state=checked]:bg-primary"
                            aria-checked={checkboxStates[contact.id]?.emailOptOut ?? false}
                          />
                          <label 
                            htmlFor={`email-${contact.id}`}
                            className="flex items-center gap-2"
                          >
                            Email
                          </label>
                        </div>

                        <div className="flex items-center gap-2">
                          <Checkbox 
                            id={`sms-${contact.id}`}
                            checked={checkboxStates[contact.id]?.smsOptOut ?? false}
                            onCheckedChange={(checked) => {
                              setCheckboxStates(prev => ({
                                ...prev,
                                [contact.id]: {
                                  ...prev[contact.id],
                                  smsOptOut: checked
                                }
                              }));
                              handleOptOutChange(contact.id, 'smsOptOut', checked);
                            }}
                            className="data-[state=checked]:bg-primary"
                            aria-checked={checkboxStates[contact.id]?.smsOptOut ?? false}
                          />
                          <label 
                            htmlFor={`sms-${contact.id}`}
                            className="flex items-center gap-2"
                          >
                            SMS 
                          </label>
                        </div>

                        <div className="flex items-center gap-2">
                          <Checkbox 
                            id={`phone-${contact.id}`}
                            checked={checkboxStates[contact.id]?.phoneOptOut ?? false}
                            onCheckedChange={(checked) => {
                              setCheckboxStates(prev => ({
                                ...prev,
                                [contact.id]: {
                                  ...prev[contact.id],
                                  phoneOptOut: checked
                                }
                              }));
                              handleOptOutChange(contact.id, 'phoneOptOut', checked);
                            }}
                            className="data-[state=checked]:bg-primary"
                            aria-checked={checkboxStates[contact.id]?.phoneOptOut ?? false}
                          />
                          <label 
                            htmlFor={`phone-${contact.id}`}
                            className="flex items-center gap-2"
                          >
                            Phone 
                          </label>
                        </div>

                        <div className="flex items-center gap-2">
                          <Checkbox 
                            id={`aiphone-${contact.id}`}
                            checked={checkboxStates[contact.id]?.aiPhoneOptOut ?? false}
                            onCheckedChange={(checked) => {
                              setCheckboxStates(prev => ({
                                ...prev,
                                [contact.id]: {
                                  ...prev[contact.id],
                                  aiPhoneOptOut: checked
                                }
                              }));
                              handleOptOutChange(contact.id, 'aiPhoneOptOut', checked);
                            }}
                            className="data-[state=checked]:bg-primary"
                            aria-checked={checkboxStates[contact.id]?.aiPhoneOptOut ?? false}
                          />
                          <label 
                            htmlFor={`aiphone-${contact.id}`}
                            className="flex items-center gap-2"
                          >
                            AI Phone 
                          </label>
                        </div>
                      </div>
                    </TableCell>

                    {/* AI Call Permission TableCell */}
                    <TableCell>
                      <div className="flex items-center gap-2">
                        <Checkbox 
                          id={`permission-${contact.id}`}
                          checked={checkboxStates[contact.id]?.aiPhonePermissionGranted ?? false}
                          onCheckedChange={(checked) => {
                            setCheckboxStates(prev => ({
                              ...prev,
                              [contact.id]: {
                                ...prev[contact.id],
                                aiPhonePermissionGranted: checked
                              }
                            }));
                            handleOptOutChange(contact.id, 'aiPhonePermissionGranted', checked);
                          }}
                          className="data-[state=checked]:bg-primary"
                          aria-checked={checkboxStates[contact.id]?.aiPhonePermissionGranted ?? false}
                        />
                        <label 
                          htmlFor={`permission-${contact.id}`}
                          className="flex items-center gap-2"
                        >
                          Permission Granted 
                        </label>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handleToggleCampaign(contact.id)}
                        disabled={contact.phoneOptOut || !contact.aiPhonePermissionGranted}
                        variant={contact.selectedForCampaign ? "destructive" : "default"}
                        size="sm"
                      >
                        {contact.selectedForCampaign ? 'Remove from Campaign' : 'Add to Campaign'}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button 
                        variant="destructive" 
                        size="sm"
                        onClick={() => handleDeleteContact(contact.id)}
                      >
                        Delete
                      </Button>
                      <SMSButton contact={contact} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <div className="flex justify-center gap-2">
              {[...Array(totalPages)].map((_, i) => (
                <Button
                  key={i}
                  variant={currentPage === i + 1 ? "default" : "outline"}
                  size="sm"
                  onClick={() => setCurrentPage(i + 1)}
                >
                  {i + 1}
                </Button>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>

      <Dialog open={showImportDialog} onOpenChange={setShowImportDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Import Contacts</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <Input
              type="file"
              accept=".csv,.xlsx,.xls"
              onChange={(e) => setImportFile(e.target.files[0])}
            />
            <p className="text-sm text-gray-500">
              File should contain columns: Name, Email, Phone, Timezone
            </p>
            <div className="flex justify-end gap-2">
              <Button variant="outline" onClick={() => setShowImportDialog(false)}>
                Cancel
              </Button>
              <Button onClick={handleImportFile}>
                Import
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ContactManagement;