import React, { useState, useEffect, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { getCallSummariesByOrganization, getSubscriptionStartDate } from '../services/api';
import { format } from 'date-fns';

import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import { ScrollArea } from "./ui/scroll-area";
import { Label } from "./ui/label";
import { Alert, AlertDescription } from "./ui/alert";

const CallSummaryComponent = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [summaries, setSummaries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSummary, setSelectedSummary] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    month: '_all',
    year: '_all',
    assistantId: ''
  });
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);

  const months = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  const years = Array.from(
    { length: new Date().getFullYear() - 2019 + 1 },
    (_, i) => (2020 + i).toString()
  );

  useEffect(() => {
    if (organizationId) {
      fetchSubscriptionDate();
      fetchSummaries();
    }
  }, [organizationId, currentPage, filters]);

  const fetchSubscriptionDate = async () => {
    try {
      const date = await getSubscriptionStartDate(organizationId);
      setSubscriptionStartDate(new Date(date));
    } catch (error) {
      console.error('Error fetching subscription date:', error);
    }
  };

  const fetchSummaries = async () => {
    setLoading(true);
    try {
      console.log('Fetching summaries with params:', {
        organizationId,
        filters,
        currentPage
      });

      const response = await getCallSummariesByOrganization(organizationId, {
        month: filters.month === '_all' ? '' : filters.month,
        year: filters.year === '_all' ? '' : filters.year,
        assistantId: filters.assistantId,
        page: currentPage,
        size: 10
      });

      console.log('API Response:', response);
      console.log('API Response totalPages:', response.totalPages);
      if (response && response.content ) {
        setSummaries(response.content);
        setTotalPages(response.totalPages || 1);
        console.log('Set summaries:', response);
      } else {
        console.log('No response data');
        setSummaries([]);
        setTotalPages(1);
      }
      setError(null);
    } catch (error) {
      console.error('Error fetching call summaries:', error);
      setError('Failed to fetch call summaries');
      setSummaries([]);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (field, value) => {
    setFilters(prev => ({ ...prev, [field]: value }));
    setCurrentPage(1);
  };

  const formatDuration = (minutes) => {
    const hrs = Math.floor(minutes / 60);
    const mins = Math.floor(minutes % 60);
    const secs = Math.floor((minutes * 60) % 60);
    return `${hrs}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const formatCost = (cost) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(cost);
  };

  const renderFilters = () => (
    <div className="grid md:grid-cols-3 gap-4 mb-6">
      <div>
        <Label>Assistant ID</Label>
        <Input
          value={filters.assistantId}
          onChange={(e) => handleFilterChange('assistantId', e.target.value)}
          placeholder="Enter Assistant ID"
        />
      </div>
      {subscriptionStartDate && (
        <>
          <div>
            <Label>Month</Label>
            <Select
              value={filters.month}
              onValueChange={(value) => handleFilterChange('month', value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select month" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="_all">All Months</SelectItem>
                {months.map((month) => (
                  <SelectItem key={month.value} value={month.value}>
                    {month.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div>
            <Label>Year</Label>
            <Select
              value={filters.year}
              onValueChange={(value) => handleFilterChange('year', value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select year" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="_all">All Years</SelectItem>
                {years.map((year) => (
                  <SelectItem key={year} value={year}>
                    {year}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </>
      )}
    </div>
  );

  const renderSummaryDetails = (summary) => (
    <Card className="mt-6">
      <CardHeader>
        <CardTitle>
          Call Details - {format(new Date(summary.callTrack.startTime), 'PPpp')}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Accordion type="single" collapsible>
          <AccordionItem value="summary">
            <AccordionTrigger>Summary</AccordionTrigger>
            <AccordionContent>
              {summary.summary}
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="transcript">
            <AccordionTrigger>Transcript</AccordionTrigger>
            <AccordionContent>
              <ScrollArea className="h-[300px] rounded-md border p-4">
                {summary.transcript}
              </ScrollArea>
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="analysis">
            <AccordionTrigger>Analysis</AccordionTrigger>
            <AccordionContent>
              <div className="font-mono text-sm">
                {summary.analysis && JSON.stringify(JSON.parse(summary.analysis), null, 2)}
              </div>
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="callLogs">
            <AccordionTrigger>Call Logs</AccordionTrigger>
            <AccordionContent>
              <ScrollArea className="h-[300px] rounded-md border p-4">
                {summary.callLogs.split('\n').map((line, index) => (
                  <div
                    key={index}
                    className={`py-1 ${
                      line.startsWith('Assistant:') ? 'pl-4 text-blue-600' :
                      line.startsWith('User:') ? 'pl-4 text-green-600' : ''
                    }`}
                  >
                    {line}
                  </div>
                ))}
              </ScrollArea>
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        {summary.recordingUrl && (
          <div className="mt-4">
            <Label>Recording</Label>
            <audio controls className="w-full mt-2">
              <source src={summary.recordingUrl} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
      </CardContent>
    </Card>
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-lg text-muted-foreground">Loading summaries...</div>
      </div>
    );
  }

  if (error) {
    return <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>;
  }

  return (
    <div className="container max-w-7xl mx-auto py-6 space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Call Summaries</CardTitle>
        </CardHeader>
        <CardContent>
          {renderFilters()}

          {(!summaries || summaries.length === 0) ? (
            <Alert>
              <AlertDescription>No call summaries available.</AlertDescription>
            </Alert>
          ) : (
            <>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Date</TableHead>
                    <TableHead>Duration</TableHead>
                    <TableHead>Cost</TableHead>
                    <TableHead>Type</TableHead>
                    <TableHead className="text-right">Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {summaries && summaries.map((summary) => (
                    <TableRow key={summary.id}>
                      <TableCell>
                        {format(new Date(summary.callTrack.startTime), 'PP')}
                      </TableCell>
                      <TableCell>
                        {formatDuration(summary.callTrack.durationInMinutes)}
                      </TableCell>
                      <TableCell>{formatCost(summary.callTrack.cost)}</TableCell>
                      <TableCell>{summary.callTrack.callType}</TableCell>
                      <TableCell className="text-right">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setSelectedSummary(
                            selectedSummary?.id === summary.id ? null : summary
                          )}
                        >
                          {selectedSummary?.id === summary.id ? 'Hide Details' : 'View Details'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <div className="flex justify-center mt-4 gap-2">
                {[...Array(totalPages)].map((_, i) => (
                                  <Button
                                    key={i}
                                    variant={currentPage === i + 1 ? "default" : "outline"}
                                    size="sm"
                                    onClick={() => setCurrentPage(i + 1)}
                                  >
                                    {i + 1}
                                  </Button>
                                ))}
              </div>
            </>
          )}

          {selectedSummary && renderSummaryDetails(selectedSummary)}
        </CardContent>
      </Card>
    </div>
  );
};

export default CallSummaryComponent;