import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getLiveVAPIAssistant, updateLiveVAPIAssistant } from '../services/api';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Textarea } from "./ui/textarea";
import { Switch } from "./ui/switch";
import { Label } from "./ui/label";
import { Alert, AlertDescription } from "./ui/alert";
import { ScrollArea } from "./ui/scroll-area";
import { Loader2 } from "lucide-react";

const LiveAssistantEdit = () => {
  const [liveAssistant, setLiveAssistant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { assistantId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchLiveAssistant();
  }, [assistantId]);

  const fetchLiveAssistant = async () => {
    try {
      const data = await getLiveVAPIAssistant(assistantId);
      setLiveAssistant(data);
    } catch (error) {
      setError('Failed to fetch live assistant');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field, value) => {
    setLiveAssistant(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleJsonChange = (field, value) => {
    try {
      const parsedValue = JSON.parse(value);
      handleChange(field, parsedValue);
    } catch (error) {
      console.error(`Invalid JSON for ${field}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await updateLiveVAPIAssistant(assistantId, liveAssistant);
      navigate('/vapi-assistants');
    } catch (error) {
      setError('Failed to update live assistant');
    } finally {
      setLoading(false);
    }
  };

  const JsonField = ({ label, field, value, rows = 4 }) => (
    <div className="space-y-2">
      <Label htmlFor={field}>{label}</Label>
      <Textarea
        id={field}
        value={JSON.stringify(value, null, 2)}
        onChange={(e) => handleJsonChange(field, e.target.value)}
        className="font-mono"
        rows={rows}
      />
    </div>
  );

  if (loading && !liveAssistant) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-6 w-6 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  if (!liveAssistant) {
    return (
      <Alert>
        <AlertDescription>Assistant not found</AlertDescription>
      </Alert>
    );
  }

  return (
    <ScrollArea className="h-[calc(100vh-8rem)]">
      <div className="container max-w-4xl mx-auto py-6">
        <Card>
          <CardHeader>
            <CardTitle>Edit Live Assistant</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Basic Information */}
              <div className="space-y-4">
                <div>
                  <Label htmlFor="name">Name</Label>
                  <Input
                    id="name"
                    value={liveAssistant.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                  />
                </div>

                {/* Configuration Fields */}
                <JsonField 
                  label="Transcriber Configuration"
                  field="transcriber"
                  value={liveAssistant.transcriber}
                />

                <JsonField 
                  label="Model Configuration"
                  field="model"
                  value={liveAssistant.model}
                  rows={8}
                />

                <JsonField 
                  label="Voice Configuration"
                  field="voice"
                  value={liveAssistant.voice}
                />

                {/* Message Mode Selection */}
                <div className="space-y-2">
                  <Label>First Message Mode</Label>
                  <Select
                    value={liveAssistant.firstMessageMode}
                    onValueChange={(value) => handleChange('firstMessageMode', value)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select mode" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="assistant-speaks-first">
                        Assistant Speaks First
                      </SelectItem>
                      <SelectItem value="user-speaks-first">
                        User Speaks First
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                {/* Toggle Switches */}
                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <Switch
                      id="recordingEnabled"
                      checked={liveAssistant.recordingEnabled}
                      onCheckedChange={(checked) => 
                        handleChange('recordingEnabled', checked)
                      }
                    />
                    <Label htmlFor="recordingEnabled">Recording Enabled</Label>
                  </div>

                  <div className="flex items-center space-x-2">
                    <Switch
                      id="hipaaEnabled"
                      checked={liveAssistant.hipaaEnabled}
                      onCheckedChange={(checked) => 
                        handleChange('hipaaEnabled', checked)
                      }
                    />
                    <Label htmlFor="hipaaEnabled">HIPAA Enabled</Label>
                  </div>
                </div>

                {/* Message Configuration */}
                <JsonField 
                  label="Client Messages"
                  field="clientMessages"
                  value={liveAssistant.clientMessages}
                />

                <JsonField 
                  label="Server Messages"
                  field="serverMessages"
                  value={liveAssistant.serverMessages}
                />

                {/* Timing Settings */}
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label htmlFor="silenceTimeoutSeconds">
                      Silence Timeout (seconds)
                    </Label>
                    <Input
                      id="silenceTimeoutSeconds"
                      type="number"
                      value={liveAssistant.silenceTimeoutSeconds}
                      onChange={(e) => handleChange('silenceTimeoutSeconds', 
                        parseInt(e.target.value))}
                    />
                  </div>

                  <div>
                    <Label htmlFor="maxDurationSeconds">
                      Max Duration (seconds)
                    </Label>
                    <Input
                      id="maxDurationSeconds"
                      type="number"
                      value={liveAssistant.maxDurationSeconds}
                      onChange={(e) => handleChange('maxDurationSeconds', 
                        parseInt(e.target.value))}
                    />
                  </div>
                </div>
{/* Background Sound Settings */}
<div>
                  <Label htmlFor="backgroundSound">Background Sound</Label>
                  <Input
                    id="backgroundSound"
                    value={liveAssistant.backgroundSound}
                    onChange={(e) => handleChange('backgroundSound', e.target.value)}
                  />
                </div>

                {/* Audio Processing Settings */}
                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <Switch
                      id="backchannelingEnabled"
                      checked={liveAssistant.backchannelingEnabled}
                      onCheckedChange={(checked) => 
                        handleChange('backchannelingEnabled', checked)
                      }
                    />
                    <Label htmlFor="backchannelingEnabled">
                      Backchanneling Enabled
                    </Label>
                  </div>

                  <div className="flex items-center space-x-2">
                    <Switch
                      id="backgroundDenoisingEnabled"
                      checked={liveAssistant.backgroundDenoisingEnabled}
                      onCheckedChange={(checked) => 
                        handleChange('backgroundDenoisingEnabled', checked)
                      }
                    />
                    <Label htmlFor="backgroundDenoisingEnabled">
                      Background Denoising Enabled
                    </Label>
                  </div>

                  <div className="flex items-center space-x-2">
                    <Switch
                      id="modelOutputInMessagesEnabled"
                      checked={liveAssistant.modelOutputInMessagesEnabled}
                      onCheckedChange={(checked) => 
                        handleChange('modelOutputInMessagesEnabled', checked)
                      }
                    />
                    <Label htmlFor="modelOutputInMessagesEnabled">
                      Model Output In Messages Enabled
                    </Label>
                  </div>
                </div>

                {/* Transport Configuration */}
                <JsonField 
                  label="Transport Configurations"
                  field="transportConfigurations"
                  value={liveAssistant.transportConfigurations}
                />

                {/* Message Settings */}
                <div>
                  <Label htmlFor="firstMessage">First Message</Label>
                  <Textarea
                    id="firstMessage"
                    value={liveAssistant.firstMessage}
                    onChange={(e) => handleChange('firstMessage', e.target.value)}
                  />
                </div>

                {/* Voicemail Settings */}
                <JsonField 
                  label="Voicemail Detection"
                  field="voicemailDetection"
                  value={liveAssistant.voicemailDetection}
                />

                <div>
                  <Label htmlFor="voicemailMessage">Voicemail Message</Label>
                  <Textarea
                    id="voicemailMessage"
                    value={liveAssistant.voicemailMessage}
                    onChange={(e) => handleChange('voicemailMessage', e.target.value)}
                  />
                </div>

                {/* Call End Settings */}
                <div>
                  <Label htmlFor="endCallMessage">End Call Message</Label>
                  <Textarea
                    id="endCallMessage"
                    value={liveAssistant.endCallMessage}
                    onChange={(e) => handleChange('endCallMessage', e.target.value)}
                  />
                </div>

                <JsonField 
                  label="End Call Phrases"
                  field="endCallPhrases"
                  value={liveAssistant.endCallPhrases}
                />

                {/* Additional Configurations */}
                <JsonField 
                  label="Metadata"
                  field="metadata"
                  value={liveAssistant.metadata}
                />

                <div>
                  <Label htmlFor="serverUrl">Server URL</Label>
                  <Input
                    id="serverUrl"
                    value={liveAssistant.serverUrl}
                    onChange={(e) => handleChange('serverUrl', e.target.value)}
                  />
                </div>

                {/* Advanced Settings */}
                <JsonField 
                  label="Analysis Plan"
                  field="analysisPlan"
                  value={liveAssistant.analysisPlan}
                />

                <JsonField 
                  label="Artifact Plan"
                  field="artifactPlan"
                  value={liveAssistant.artifactPlan}
                />

                <JsonField 
                  label="Message Plan"
                  field="messagePlan"
                  value={liveAssistant.messagePlan}
                />

                <JsonField 
                  label="Start Speaking Plan"
                  field="startSpeakingPlan"
                  value={liveAssistant.startSpeakingPlan}
                />

                <JsonField 
                  label="Stop Speaking Plan"
                  field="stopSpeakingPlan"
                  value={liveAssistant.stopSpeakingPlan}
                />

                <JsonField 
                  label="Credential IDs"
                  field="credentialIds"
                  value={liveAssistant.credentialIds}
                />
              </div>

                {/* Additional Fields */}
                {/* Add remaining fields similarly ... */}

              {/* Form Actions */}
              <div className="sticky bottom-0 pt-6 pb-2 bg-background flex justify-end space-x-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => navigate('/vapi-assistants')}
                >
                  Cancel
                </Button>
                <Button 
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Saving Changes...
                    </>
                  ) : (
                    'Save Changes'
                  )}
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </ScrollArea>
  );
};

export default LiveAssistantEdit;                        