import React from 'react';
import { LucideIcon } from 'lucide-react';

interface BenefitCardProps {
  Icon: LucideIcon;
  title: string;
  description: string;
}

export function BenefitCard({ Icon, title, description }: BenefitCardProps) {
  return (
    <div className="flex items-start p-6 bg-gradient-to-br from-white to-indigo-50/50 rounded-xl shadow-sm hover:shadow-md transition-all">
      <Icon className="h-8 w-8 text-indigo-600 mt-1 flex-shrink-0" />
      <div className="ml-4">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">{title}</h3>
        <p className="text-gray-600 leading-relaxed">{description}</p>
      </div>
    </div>
  );
}