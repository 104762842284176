import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { createCheckoutSession, getPaymentHistory } from '../services/api';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "./ui/card";
import { Button } from "./ui/button";
import { Alert, AlertDescription } from "./ui/alert";
import { Loader2, CreditCard, CheckCircle, XCircle, ArrowLeft } from "lucide-react";
import  PricingSection  from './PricingSection';

const LoadingSpinner = () => (
  <div className="flex items-center justify-center">
    <Loader2 className="h-5 w-5 animate-spin" />
  </div>
);

const SuccessPage = () => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { organizationId } = useContext(OrganizationContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        setLoading(true);
        const searchParams = new URLSearchParams(location.search);
        const sessionId = searchParams.get('session_id');
        
        if (!sessionId) {
          throw new Error('No session ID found');
        }

        if (!organizationId) {
          console.log('Waiting for organization ID...');
          return;
        }

        const paymentHistory = await getPaymentHistory(organizationId);

        if (!paymentHistory || paymentHistory.length === 0) {
          throw new Error('No payment history found');
        }

        const latestPayment = paymentHistory[0];

        setPaymentDetails({
          sessionId,
          amount: latestPayment.amount,
          currency: latestPayment.currency || 'GBP',
          status: latestPayment.status,
          date: new Date(latestPayment.paymentDate).toLocaleDateString(),
          paymentType: latestPayment.paymentType,
          referenceId: latestPayment.stripePaymentIntentId
        });

        setError(null);
      } catch (err) {
        console.error('Error in fetchPaymentDetails:', err);
        setError('Failed to load payment details. Please contact support.');
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentDetails();
  }, [organizationId, location.search]);

  if (!organizationId || loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <Card className="max-w-md mx-auto mt-8">
        <CardHeader>
          <div className="flex items-center justify-center mb-4">
            <XCircle className="h-12 w-12 text-destructive" />
          </div>
          <CardTitle className="text-center">Error Loading Payment Details</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
          <p className="text-sm text-muted-foreground text-center">
            Organization ID: {organizationId}
          </p>
          <Button 
            variant="outline" 
            className="w-full"
            onClick={() => navigate('/')}
          >
            <ArrowLeft className="mr-2 h-4 w-4" />
            Return to Dashboard
          </Button>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="max-w-md mx-auto mt-8">
      <CardHeader>
        <div className="flex items-center justify-center mb-4">
          <CheckCircle className="h-12 w-12 text-primary" />
        </div>
        <CardTitle className="text-center">Payment Successful!</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        {paymentDetails && (
          <div className="space-y-4">
            <h3 className="font-medium">Payment Details</h3>
            <div className="grid grid-cols-2 gap-2 text-sm">
              <span className="text-muted-foreground">Amount:</span>
              <span className="font-medium">
                {new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: paymentDetails.currency
                }).format(paymentDetails.amount)}
              </span>
              
              <span className="text-muted-foreground">Date:</span>
              <span className="font-medium">{paymentDetails.date}</span>
              
              <span className="text-muted-foreground">Payment Type:</span>
              <span className="font-medium">{paymentDetails.paymentType}</span>
              
              <span className="text-muted-foreground">Reference ID:</span>
              <span className="font-medium break-all">
                {paymentDetails.referenceId}
              </span>
              
              <span className="text-muted-foreground">Status:</span>
              <span className="font-medium text-primary">
                {paymentDetails.status}
              </span>
              
              <span className="text-muted-foreground">Organization ID:</span>
              <span className="font-medium">{organizationId}</span>
            </div>
          </div>
        )}
        
        <p className="text-sm text-muted-foreground text-center">
          You will receive a confirmation email shortly.
        </p>
        
        <Button 
          className="w-full"
          onClick={() => navigate('/appointments')}
        >
          Continue Setup
        </Button>
      </CardContent>
    </Card>
  );
};

const FailedPage = () => {
  const navigate = useNavigate();

  return (
    <Card className="max-w-md mx-auto mt-8">
      <CardHeader>
        <div className="flex items-center justify-center mb-4">
          <XCircle className="h-12 w-12 text-destructive" />
        </div>
        <CardTitle className="text-center">Payment Failed</CardTitle>
        <CardDescription className="text-center">
          Unfortunately, your payment was not successful.
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="space-y-4">
          <p className="font-medium">This could be due to:</p>
          <ul className="list-disc list-inside text-sm text-muted-foreground space-y-1">
            <li>Insufficient funds</li>
            <li>Expired card</li>
            <li>Invalid card details</li>
            <li>Bank declined transaction</li>
          </ul>
        </div>

        <p className="text-sm text-muted-foreground">
          Please try again or contact your bank for more information.
        </p>

        <div className="space-y-3">
          <Button 
            variant="outline" 
            className="w-full"
            onClick={() => navigate('/checkout')}
          >
            Try Again
          </Button>
          <Button 
            className="w-full"
            onClick={() => navigate('/')}
          >
            Return to Dashboard
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { organizationId, isLoading: isOrgLoading, error: orgError } = 
    useContext(OrganizationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const planData = localStorage.getItem('selectedPlan');
    console.log('checkoutPage planData='+JSON.stringify(planData) );
    if (planData) {
      setSelectedPlan(JSON.parse(planData));
    }
  }, []);

  const handleCheckout = async (event) => {
    event.preventDefault();
    console.log('selectedPlan='+JSON.stringify(selectedPlan));
    if (!stripe || !elements || !selectedPlan || !organizationId) {
      console.log('Missing required elements:', { 
        stripe: !!stripe, 
        elements: !!elements, 
        selectedPlan: !!selectedPlan,
        organizationId: !!organizationId
      });
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const { sessionId } = await createCheckoutSession(
        organizationId, 
        selectedPlan.priceId
      );

      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        throw new Error(error.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isOrgLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <LoadingSpinner />
      </div>
    );
  }

  if (orgError) {
    return (
      <Alert variant="destructive" className="max-w-md mx-auto mt-8">
        <AlertDescription>
          Error loading organization details. Please try again later.
        </AlertDescription>
      </Alert>
    );
  }

  if (!organizationId) {
    return (
      <Card className="max-w-md mx-auto mt-8">
        <CardHeader>
          <CardTitle>Organization Required</CardTitle>
          <CardDescription>
            Please complete your organization setup first.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Button 
            className="w-full"
            onClick={() => navigate('/organization')}
          >
            Setup Organization
          </Button>
        </CardContent>
      </Card>
    );
  }

   // If no plan is selected, show PricingSection instead of an error
   if (!selectedPlan) {
    return (
      <div className="container mx-auto px-4">
        <Card className="mb-8">
          <CardHeader>
            <CardTitle>Select a Plan</CardTitle>
            <CardDescription>
              Please choose a plan to continue with checkout
            </CardDescription>
          </CardHeader>
        </Card>
        
        <PricingSection 
          onPlanSelected={(plan) => {
            setSelectedPlan(plan);
            localStorage.setItem('selectedPlan', JSON.stringify(plan));
          }}
          hideNavigation={true} // Prevent navigation after plan selection
        />
      </div>
    );
  }

  return (
    <Card className="max-w-2xl mx-auto mt-8">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <CreditCard className="h-6 w-6" />
          Checkout Summary
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="space-y-4">
          <h3 className="font-medium">Selected Plan Details</h3>
          <div className="grid grid-cols-2 gap-4 text-sm">
            <div className="space-y-2">
              <span className="text-muted-foreground">Plan Name</span>
              <p className="font-medium">
                {selectedPlan.type.charAt(0).toUpperCase() + selectedPlan.type.slice(1)} Plan
              </p>
            </div>
            <div className="space-y-2">
              <span className="text-muted-foreground">Billing Period</span>
              <p className="font-medium">
                {selectedPlan.billing.charAt(0).toUpperCase() + selectedPlan.billing.slice(1)}
              </p>
            </div>
            <div className="space-y-2">
              <span className="text-muted-foreground">Amount</span>
              <p className="font-medium">
                ${selectedPlan.subscriptionAmount}
              </p>
            </div>
            <div className="space-y-2">
              <span className="text-muted-foreground">Start Date</span>
              <p className="font-medium">
                {new Date(selectedPlan.startDate).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>

        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <Button
          className="w-full"
          onClick={handleCheckout}
          disabled={isLoading || !stripe}
        >
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Processing...
            </>
          ) : (
            'Proceed to Payment'
          )}
        </Button>
      </CardContent>
    </Card>
  );
};

const CheckoutPage = () => {
  const location = useLocation();

  if (location.pathname.includes('/success')) {
    return <SuccessPage />;
  }

  if (location.pathname.includes('/canceled')) {
    return <FailedPage />;
  }

  return <PaymentForm />;
};

export default CheckoutPage;