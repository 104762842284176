import React, { useEffect, useState, useContext } from 'react';
import { handleAuthorize, getGoogleAuthInfo, revokeGoogleAuth, 
  getOrganizationIdFromAPI, checkExistingAppointments, saveCalendlySettings, 
  getCalendlyAuthUrl, revokeCalendlyAuth, revokeCalcomAuth, getCalcomAuthUrl,
  saveCalcomSettings,  getCalcomAuthInfo, getCalcomEventTypes } from '../services/api';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { API_BASE_URL } from '../services/api';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "./ui/card";
import { Button } from "./ui/button";
import { Select } from "./ui/select";
import { SelectTrigger } from "./ui/select";
import { SelectValue } from "./ui/select";
import { SelectContent } from "./ui/select";
import { SelectItem } from "./ui/select";
import { Label } from "./ui/label";
import { Input } from "./ui/input";

import { Alert, AlertDescription } from "./ui/alert";
import { Calendar, LogOut, Mail, Loader2,  CheckCircle, AlertCircle } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";

const EventTypeCard = ({ eventType, selected, onSelect }) => (
  <div 
    className={`p-4 border rounded-lg cursor-pointer transition-all ${
      selected ? 'border-blue-500 bg-blue-50' : 'border-gray-200 hover:border-blue-300'
    }`}
    onClick={() => onSelect(eventType)}
  >
    <div className="flex items-center justify-between mb-2">
      <h3 className="font-medium">{eventType.title}</h3>
      <span className="text-sm text-gray-500">{eventType.length} min</span>
    </div>
    <div className="text-sm text-gray-600 mb-2">
      {eventType.description || 'No description'}
    </div>
    <div className="flex items-center gap-2 text-xs text-gray-500">
      <code className="bg-gray-100 px-2 py-1 rounded">{eventType.link}</code>
      {eventType.locations?.[0]?.type && (
        <span className="bg-blue-100 text-blue-700 px-2 py-1 rounded">
          {eventType.locations[0].type}
        </span>
      )}
    </div>
  </div>
);

const GoogleAuthComponent = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [krupaOrganizationId, setKrupaOrganizationId] = useState(null);
  const [authUrl, setAuthUrl] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [associatedEmail, setAssociatedEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [provider,setProvider] = useState(null);
  const [saveStatus, setSaveStatus] = useState({ type: '', message: '' });
  const [showRevokeDialog, setShowRevokeDialog] = useState(false);

  const [calendarProvider, setCalendarProvider] = useState('GOOGLE');
  const [calendlyKey, setCalendlyKey] = useState('');
  const [calendlyUrl, setCalendlyUrl] = useState('');
  const [calcomKey, setCalcomKey] = useState('');
  const [calcomUrl, setCalcomUrl] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [showSwitchWarning, setShowSwitchWarning] = useState(false);
  const [newProvider, setNewProvider] = useState(null);

  const [eventTypes, setEventTypes] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState(null);
  const [eventTypeLoading, setEventTypeLoading] = useState(false);
  const [showEventTypeList, setShowEventTypeList] = useState(false);

  useEffect(() => {
    const fetchOrganizationId = async () => {
      try {
        const id = await getOrganizationIdFromAPI();
        setKrupaOrganizationId(id);
      } catch (error) {
        setError('Failed to fetch organization ID');
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizationId();
  }, []);

  useEffect(() => {
    if (krupaOrganizationId) {
      checkAuthStatus();
    }
  }, [krupaOrganizationId]);

  const handleProviderChange = async (provider) => {
    if (provider !== calendarProvider) {
      const hasAppointments = await checkExistingAppointments(organizationId);
      if (hasAppointments) {
        setShowWarning(true);
        return;
      }
      setCalendarProvider(provider);
    }
  };

  const handleCalendlySubmit = async () => {
    try {
      // Remove 'Bearer ' if it exists, then add it back to ensure proper format
      const token = calendlyKey.replace(/^Bearer\s+/i, '');
      const formattedApiKey = `Bearer ${token}`;
        
      setSaveStatus({ type: 'loading', message: 'Saving settings...' });

      const response = await saveCalendlySettings({
        organizationId,
        apiKey: formattedApiKey,
        schedulingUrl: calendlyUrl
      });

      if (response.success) {
            setSaveStatus({ 
              type: 'success', 
              message: response.message || 'Calendly settings saved successfully' 
            });
          } else {
            setSaveStatus({ 
              type: 'error', 
              message: response.error || 'Failed to save settings' 
            });
      }

    } catch (error) {
        console.error('Error saving Calendly settings:', error);
        setSaveStatus({ 
          type: 'error', 
          message: 'Failed to save Calendly settings. Please try again.' 
        });
      }
  };

  const checkAuthStatus = async () => {
    if (!krupaOrganizationId) return;

    try {
      let authInfo;
      switch (calendarProvider) {
        case 'GOOGLE':
          authInfo = await getGoogleAuthInfo(organizationId);
          break;
        case 'CALENDLY':
          authInfo = await getCalendlyAuthUrl(organizationId);
          break;
        case 'CALCOM':
          authInfo = await getCalcomAuthInfo(organizationId);
          break;
        default:
          throw new Error('Unsupported calendar provider');
      }
      setProvider(authInfo.provider);
      setIsAuthorized(authInfo.isAuthorized);
      setAssociatedEmail(authInfo.email);
    } catch (error) {
      setError('Failed to check authorization status');
    }
  };

    const handleAuthorizeGoogle = async () => {
        if (!krupaOrganizationId) {
        setError('Organization ID is not available');
        return;
        }

        try {
        const url = await handleAuthorize(krupaOrganizationId, organizationId);
        setAuthUrl(url);
        const authWindow = window.open(url, 'Google Authorization', 'width=600,height=600');

        const checkInterval = setInterval(async () => {
            const authInfo = await getGoogleAuthInfo(organizationId);
            if (authInfo.isAuthorized) {
            clearInterval(checkInterval);
            setIsAuthorized(true);
            setAssociatedEmail(authInfo.email);
            }
        }, 2000);

        setTimeout(() => clearInterval(checkInterval), 120000);
        } catch (error) {
        setError('Failed to get authorization URL');
        }
    };

    const handleUnauthorizeGoogle = async () => {
        if (!krupaOrganizationId) {
            setError('Organization ID is not available');
            return;
        }

        try {
            const response = await revokeGoogleAuth(krupaOrganizationId);
            setIsAuthorized(false);
            setAssociatedEmail('');
            setShowRevokeDialog(false);
        } catch (error) {
            setError('Failed to revoke Google authorization');
        }
    };

    const handleCalendlyAuthorize = async () => {
      try {
        const response = await getCalendlyAuthUrl (organizationId);
        const authUrl = await response.text();
        window.open(authUrl, 'Calendly Authorization', 'width=600,height=600');
      } catch (error) {
        setError('Failed to start Calendly authorization');
      }
    };

    const switchProvider = async (newValue) => {
      try {
        setProvider(newValue);
        if (newValue === 'CALENDLY') {
          const response = await fetch(`${API_BASE_URL}/api/calendly/authorize/${organizationId}`);
          const url = await response.text();
          window.open(url, 'Calendar Authorization', 'width=600,height=600');
        } else {
          const response = await fetch(`${API_BASE_URL}/api/google-auth/authorize/${organizationId}`);
          const url = await response.text();
          window.open(url, 'Calendar Authorization', 'width=600,height=600');
        }
  
        // Poll for auth status
        const checkInterval = setInterval(async () => {
          const status = await checkAuthStatus();
          if (status.isAuthorized) {
            clearInterval(checkInterval);
          }
        }, 2000);
  
        setTimeout(() => clearInterval(checkInterval), 120000);
      } catch (error) {
        setError('Failed to switch calendar provider');
      }
    };

    const handleAuthorizeProvider = async () => {
      if (!krupaOrganizationId) {
        setError('Organization ID is not available');
        return;
      }
  
      try {
        let url;
        switch (calendarProvider) {
          case 'GOOGLE':
            url = await handleAuthorize(krupaOrganizationId, organizationId);
            break;
          case 'CALENDLY':
            url = await getCalendlyAuthUrl(organizationId);
            break;
          case 'CALCOM':
            url = await getCalcomAuthUrl(organizationId);
            break;
          default:
            throw new Error('Unsupported calendar provider');
        }
  
        setAuthUrl(url);
        const authWindow = window.open(url, `${calendarProvider} Authorization`, 'width=600,height=600');
  
        const checkInterval = setInterval(async () => {
          let authInfo;
          switch (calendarProvider) {
            case 'GOOGLE':
              authInfo = await getGoogleAuthInfo(organizationId);
              break;
            case 'CALENDLY':
              authInfo = await getCalendlyAuthUrl(organizationId);
              break;
            case 'CALCOM':
              authInfo = await getCalcomAuthInfo(organizationId);
              break;
          }
  
          if (authInfo.isAuthorized) {
            clearInterval(checkInterval);
            setIsAuthorized(true);
            setAssociatedEmail(authInfo.email);
          }
        }, 2000);
  
        setTimeout(() => clearInterval(checkInterval), 120000);
      } catch (error) {
        setError('Failed to get authorization URL');
      }
    };
  
  
    const handleUnauthorize = async () => {
      if (!krupaOrganizationId) {
        setError('Organization ID is not available');
        return;
      }
  
      try {
        switch (calendarProvider) {
          case 'GOOGLE':
            await revokeGoogleAuth(krupaOrganizationId);
            break;
          case 'CALENDLY':
            await revokeCalendlyAuth(organizationId);
            break;
          case 'CALCOM':
            await revokeCalcomAuth(organizationId);
            break;
        }
  
        setIsAuthorized(false);
        setAssociatedEmail('');
        setShowRevokeDialog(false);
      } catch (error) {
        setError(`Failed to revoke ${calendarProvider} authorization`);
      }
    };

    const handleSaveSettings = async () => {
    try {
      setSaveStatus({ type: 'loading', message: 'Saving settings...' });

      let response;
      switch (calendarProvider) {
        case 'CALENDLY':
          const calendlyToken = calendlyKey.replace(/^Bearer\s+/i, '');
          response = await saveCalendlySettings({
            organizationId,
            apiKey: `Bearer ${calendlyToken}`,
            schedulingUrl: calendlyUrl
          });
          break;
        case 'CALCOM':
          const calcomToken = calcomKey.replace(/^Bearer\s+/i, '');
          response = await handleCalcomSubmit();
          // response = await saveCalcomSettings({
          //   organizationId,
          //   apiKey: `Bearer ${calcomToken}`,
          //   schedulingUrl: calcomUrl
          // });
          break;
      }

      if (response.success) {
        setSaveStatus({ 
          type: 'success', 
          message: response.message || 'Settings saved successfully' 
        });
      } else {
        setSaveStatus({ 
          type: 'error', 
          message: response.error || 'Failed to save settings' 
        });
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      setSaveStatus({ 
        type: 'error', 
        message: `Failed to save ${calendarProvider} settings. Please try again.` 
      });
    }
  };  

  // Function to fetch event types
  const fetchCalcomEventTypes = async (apiKey) => {
    try {
      setEventTypeLoading(true);
      const token = apiKey.replace(/^Bearer\s+/i, '');
      const data = await getCalcomEventTypes(token);
      setEventTypes(data.event_types);
      
      // If there's only one event type, auto-select it
      if (data.event_types.length === 1) {
        setSelectedEventType(data.event_types[0]);
      }
    } catch (error) {
      console.error('Error fetching event types:', error);
      setSaveStatus({ type: 'error', message: 'Failed to fetch event types' });
    } finally {
      setEventTypeLoading(false);
    }
  };

  // Function to match scheduling URL with event types
  const matchSchedulingUrl = (url, types = eventTypes) => {
    // Clean up the URL for comparison
    const cleanUrl = url.trim().replace(/\/$/, '');
    
    // Try to find exact match
    const matchedType = types.find(et => et.link.replace(/\/$/, '') === cleanUrl);
    
    if (matchedType) {
      setSelectedEventType(matchedType);
      setShowEventTypeList(false);
    } else {
      // If no exact match, show the list for manual selection
      setShowEventTypeList(true);
    }
  };

  // Handle scheduling URL change
  const handleSchedulingUrlChange = (e) => {
    const url = e.target.value;
    setCalcomUrl(url);
    
    if (url && eventTypes.length > 0) {
      matchSchedulingUrl(url);
    }
  };

  // Modify the existing handleCalcomSubmit function
  const handleCalcomSubmit = async () => {
    try {
      if (!selectedEventType) {
        setSaveStatus({ type: 'error', message: 'Please select an event type' });
        return;
      }

      const token = calcomKey.replace(/^Bearer\s+/i, '');
      const formattedApiKey = `Bearer ${token}`;
        
      setSaveStatus({ type: 'loading', message: 'Saving settings...' });

      const response = await saveCalcomSettings({
        organizationId,
        apiKey: formattedApiKey,
        eventTypeId: selectedEventType.id.toString(),
        schedulingUrl: calcomUrl || selectedEventType.link,
        eventTypeLocation: selectedEventType.locations?.[0]?.type || null
      });

      if (response.success) {
        setSaveStatus({ 
          type: 'success', 
          message: response.message || 'Cal.com settings saved successfully' 
        });
        await checkAuthStatus();
      } else {
        setSaveStatus({ 
          type: 'error', 
          message: response.error || 'Failed to save settings' 
        });
      }
      return await response;
    } catch (error) {
      console.error('Error saving Cal.com settings:', error);
      setSaveStatus({ 
        type: 'error', 
        message: 'Failed to save Cal.com settings. Please try again.' 
      });
    }
  };

  // Add effect to fetch event types when API key changes
  useEffect(() => {
    if (calcomKey && calendarProvider === 'CALCOM') {
      const token = calcomKey.replace(/^Bearer\s+/i, '');
      fetchCalcomEventTypes(token);
    }
  }, [calcomKey, calendarProvider]);

  // In the render method, render Cal.com settings section
  const renderCalcomSettings = () => (
    <div className="space-y-4">
      <div>
        <Label>Cal.com API Key</Label>
        <Input
          type="password"
          value={calcomKey}
          onChange={(e) => setCalcomKey(e.target.value)}
        />
      </div>
      
      {eventTypes.length > 0 && (
        <div>
          <Label>Scheduling URL</Label>
          <Input
            value={calcomUrl}
            onChange={handleSchedulingUrlChange}
            placeholder="https://cal.com/username/event-type"
          />
          
          {showEventTypeList && (
            <div className="mt-4 space-y-4">
              <Label>Select Event Type</Label>
              <div className="space-y-2">
                {eventTypes.map(eventType => (
                  <EventTypeCard
                    key={eventType.id}
                    eventType={eventType}
                    selected={selectedEventType?.id === eventType.id}
                    onSelect={setSelectedEventType}
                  />
                ))}
              </div>
            </div>
          )}
          
          {selectedEventType && !showEventTypeList && (
            <div className="mt-2 p-3 bg-blue-50 rounded-lg">
              <div className="flex items-center justify-between">
                <div>
                  <h4 className="font-medium">{selectedEventType.title}</h4>
                  <p className="text-sm text-gray-600">{selectedEventType.link}</p>
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setShowEventTypeList(true)}
                >
                  Change
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      
      {renderSaveButton()}
    </div>
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-6 w-6 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container max-w-2xl mx-auto py-6">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Calendar className="h-6 w-6" />
            Calendar Authorization
          </CardTitle>
          <CardDescription>
            Connect your calendar to manage appointments
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div>
            <Label>Calendar Provider</Label>
            <Select value={calendarProvider} onValueChange={handleProviderChange}>
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="GOOGLE">Google Calendar</SelectItem>
                <SelectItem value="CALENDLY">Calendly</SelectItem>
                <SelectItem value="CALCOM">Cal.com</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {calendarProvider === 'CALENDLY' && (
            <div className="space-y-4">
              <div>
                <Label>Calendly API Key</Label>
                <Input
                  type="password"
                  value={calendlyKey}
                  onChange={(e) => setCalendlyKey(e.target.value)}
                />
              </div>
              <div>
                <Label>Calendly Scheduling URL</Label>
                <Input
                  value={calendlyUrl}
                  onChange={(e) => setCalendlyUrl(e.target.value)}
                />
              </div>
              {renderSaveButton()}
            </div>
          )}

{/*           {calendarProvider === 'CALCOM' && (
            <div className="space-y-4">
              <div>
                <Label>Cal.com API Key</Label>
                <Input
                  type="password"
                  value={calcomKey}
                  onChange={(e) => setCalcomKey(e.target.value)}
                />
              </div>
              <div>
                <Label>Cal.com Scheduling URL</Label>
                <Input
                  value={calcomUrl}
                  onChange={(e) => setCalcomUrl(e.target.value)}
                />
              </div>
              {renderSaveButton()}
            </div>
          )}
 */}
          {calendarProvider === 'CALCOM' && renderCalcomSettings()}
          {calendarProvider === 'GOOGLE' && renderGoogleAuth()}

          {error && (
            <Alert variant="destructive">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
        </CardContent>
      </Card>

      {renderDialogs()}
    </div>
  );

  function renderSaveButton() {
    return (
      <>
        {saveStatus?.type === 'loading' && (
          <div className="flex items-center gap-2 text-blue-600">
            <Loader2 className="h-4 w-4 animate-spin" />
            {saveStatus.message}
          </div>
        )}
        
        {saveStatus?.type === 'success' && (
          <Alert>
            <CheckCircle className="h-4 w-4" />
            <AlertDescription>{saveStatus.message}</AlertDescription>
          </Alert>
        )}

        {saveStatus?.type === 'error' && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{saveStatus.message}</AlertDescription>
          </Alert>
        )}

        <Button 
          onClick={handleSaveSettings}
          disabled={saveStatus?.type === 'loading'}
        >
          {saveStatus?.type === 'loading' ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Saving...
            </>
          ) : (
            `Save ${calendarProvider} Settings`
          )}
        </Button>
      </>
    );
  }

  function renderGoogleAuth() {
    return (
      <div>
        {isAuthorized ? (
          <div className="space-y-4">
            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              <Mail className="h-4 w-4" />
              Authorized with Gmail: 
              <span className="font-medium text-foreground">
                {associatedEmail}
              </span>
            </div>
            
            <Button
              variant="destructive"
              onClick={() => setShowRevokeDialog(true)}
              className="w-full"
            >
              <LogOut className="mr-2 h-4 w-4" />
              Unauthorize Google Calendar
            </Button>
          </div>
        ) : (
          <div className="space-y-4">
            <Button 
              onClick={handleAuthorizeProvider}
              className="w-full"
            >
              <Calendar className="mr-2 h-4 w-4" />
              Authorize Google Calendar
            </Button>

            {authUrl && (
              <p className="text-sm text-muted-foreground">
                If the authorization window didn't open, please{' '}
                <a 
                  href={authUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary hover:underline"
                >
                  click here
                </a>
                .
              </p>
            )}
          </div>
        )}
      </div>
    );
  }

  function renderDialogs() {
    return (
      <>
        <AlertDialog open={showRevokeDialog} onOpenChange={setShowRevokeDialog}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Revoke Calendar Access</AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to revoke access to your calendar? 
                This will remove the connection between your account and the application.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction
                onClick={handleUnauthorize}
                className="bg-destructive text-destructive-foreground"
              >
                Yes, Revoke Access
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        <AlertDialog open={showWarning} onOpenChange={setShowWarning}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Warning</AlertDialogTitle>
              <AlertDialogDescription>
                You have existing appointments. Changing calendar providers may affect them.
                Are you sure you want to proceed?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={() => {
                setShowWarning(false);
                setCalendarProvider(newProvider);
              }}>
                Continue
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    );
  }
};

export default GoogleAuthComponent;