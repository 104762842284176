import React, { createContext, useState, useEffect, useCallback } from 'react';
import { getOrganizationByUser, getUser, getSubscriptions } from '../services/api';

export const OrganizationContext = createContext();

export const OrganizationProvider = ({ children }) => {
    const [organizationId, setOrganizationId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [navigationEnabled, setNavigationEnabled] = useState(false);

    const checkNavigationAccess = async (organization) => {
        if (!organization?.id) {
            setNavigationEnabled(false);
            return;
        }

        try {
            const subscriptions = await getSubscriptions(organization.id);
            const hasActiveSubscription = subscriptions && 
                Array.isArray(subscriptions) && 
                subscriptions.length > 0 && 
                subscriptions.some(sub => sub.isActive && sub.stripeSubscriptionId);
            
            setNavigationEnabled(Boolean(organization.id) && hasActiveSubscription);
        } catch (error) {
            console.error('Error checking subscription:', error);
            setNavigationEnabled(false);
        }
    };

    const refreshOrganization = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const email = getUser();
            if (email) {
                const organization = await getOrganizationByUser(email);
                setOrganizationId(organization?.id || null);
                await checkNavigationAccess(organization);
            } else {
                setOrganizationId(null);
                setNavigationEnabled(false);
            }
        } catch (error) {
            console.error('Error fetching organization:', error);
            setError(error.message);
            setOrganizationId(null);
            setNavigationEnabled(false);
        } finally {
            setIsLoading(false);
        }
    }, []);

    // Initial load
    useEffect(() => {
        refreshOrganization();
    }, []);

    // Re-check whenever organizationId changes
    useEffect(() => {
        if (organizationId) {
            checkNavigationAccess({ id: organizationId });
        }
    }, [organizationId]);

    return (
        <OrganizationContext.Provider value={{ 
            organizationId, 
            setOrganizationId, 
            refreshOrganization,
            isLoading,
            error,
            navigationEnabled,
            setNavigationEnabled
        }}>
            {children}
        </OrganizationContext.Provider>
    );
};