// src/utils/auth.js

// Function to decode JWT without a library
const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    return null;
  }
};

  export const isAuthenticated = () => {
    const token = localStorage.getItem('authToken');
    if (!token) return false;

    try {
      const decodedToken = parseJwt(token);
      if (!decodedToken) return false;

      // Check if token is expired
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        // Token is expired - remove it and return false
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  };

  export const setAuthToken = (token) => {
    localStorage.setItem('authToken', token);
  };

  export const setUser = (user) => {
    localStorage.setItem('user', user);
  };

  
  export const removeAuthToken = () => {
    localStorage.removeItem('authToken');
  };

  export const removeUser = (user) => {
    localStorage.removeItem('user', user);
  };

  // Function to check token expiration
  export const checkTokenExpiration = () => {
    const token = localStorage.getItem('authToken');
    if (!token) return false;

    const decodedToken = parseJwt(token);
    if (!decodedToken) return false;

    const currentTime = Date.now() / 1000;
    return decodedToken.exp > currentTime;
  };  