import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

// Sample data for graphs
const appointmentData = [
  { month: 'Aug', calls: 150, appointments: 45 },
  { month: 'Sep', calls: 180, appointments: 60 },
  { month: 'Oct', calls: 200, appointments: 75 },
  { month: 'Nov', calls: 250, appointments: 90 },
  { month: 'Dec', calls: 220, appointments: 85 },
  { month: 'Jan', calls: 280, appointments: 100 },
];

const leadsData = [
  { month: 'Aug', calls: 150, leads: 35 },
  { month: 'Sep', calls: 180, leads: 47 },
  { month: 'Oct', calls: 200, leads: 58 },
  { month: 'Nov', calls: 250, leads: 73 },
  { month: 'Dec', calls: 220, leads: 69 },
  { month: 'Jan', calls: 280, leads: 85 },
];

// Sample sparkline data
const sparklineData = Array(10).fill(0).map((_, i) => ({
  value: Math.random() * 100
}));

// Sample metrics data
const metricsData = [
  {
    title: "Total Call Minutes",
    value: "3340",
    change: "+158.42%",
    sparkData: sparklineData,
    color: "rgb(45, 212, 191)",
    trend: "up"
  },
  {
    title: "Number of inbound Calls",
    value: "989",
    change: "+0.00%",
    sparkData: sparklineData.map(d => ({ value: d.value * 0.8 })),
    color: "rgb(99, 102, 241)",
    trend: "up"
  },
  {
    title: "Total Spent",
    value: "$532.68",
    change: "+140.60%",
    sparkData: sparklineData.map(d => ({ value: d.value * 1.2 })),
    color: "rgb(249, 115, 22)",
    trend: "up"
  },
  {
    title: "Average Cost per Call",
    value: "$0.36",
    change: "-0.02%",
    sparkData: sparklineData.map(d => ({ value: d.value * 0.9 })),
    color: "rgb(34, 197, 94)",
    trend: "up"
  }
];

const MetricCard = ({ title, value, change, sparkData, color }) => (
  <div className="bg-gray-800 rounded-xl p-4 relative overflow-hidden">
    <div className="relative z-10">
      <h3 className="text-gray-400 text-sm font-medium mb-1">{title}</h3>
      <div className="flex items-baseline gap-2">
        <p className="text-white text-2xl font-bold">{value}</p>
        <span className="text-emerald-400 text-sm font-medium">{change}</span>
      </div>
    </div>
    <div className="absolute bottom-0 right-0 w-32 h-16 opacity-30">
      <LineChart width={128} height={64} data={sparkData}>
        <YAxis hide domain={['auto', 'auto']} />
        <Line
          type="monotone"
          dataKey="value"
          stroke={color}
          strokeWidth={2}
          dot={false}
          isAnimationActive={false}
        />
      </LineChart>
    </div>
  </div>
);


const Dashboard = () => {
  return (
    <div className="container mx-auto py-8 px-4">
      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <div 
          className="transform transition-all duration-300 hover:scale-105 hover:rotate-1"
          onMouseEnter={(e) => e.currentTarget.classList.add('animate-shake')}
          onMouseLeave={(e) => e.currentTarget.classList.remove('animate-shake')}
        >
          <div className="bg-gray-900 rounded-xl shadow-lg shadow-blue-500/20 p-6">
            <h3 className="text-xl font-semibold text-gray-100 mb-2">Active Squads</h3>
            <p className="text-4xl font-bold text-blue-400">5</p>
          </div>
        </div>

        <div 
          className="transform transition-all duration-300 hover:scale-105 hover:rotate-1"
          onMouseEnter={(e) => e.currentTarget.classList.add('animate-shake')}
          onMouseLeave={(e) => e.currentTarget.classList.remove('animate-shake')}
        >
          <div className="bg-gray-900 rounded-xl shadow-lg shadow-purple-500/20 p-6">
            <h3 className="text-xl font-semibold text-gray-100 mb-2">Total Calls</h3>
            <p className="text-4xl font-bold text-purple-400">1541</p>
          </div>
        </div>

        <div 
          className="transform transition-all duration-300 hover:scale-105 hover:rotate-1"
          onMouseEnter={(e) => e.currentTarget.classList.add('animate-shake')}
          onMouseLeave={(e) => e.currentTarget.classList.remove('animate-shake')}
        >
          <div className="bg-gray-900 rounded-xl shadow-lg shadow-green-500/20 p-6">
            <h3 className="text-xl font-semibold text-gray-100 mb-2">Active Subscriptions</h3>
            <p className="text-4xl font-bold text-green-400">3</p>
          </div>
        </div>
      </div>
      {/* Metrics Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        {metricsData.map((metric, index) => (
          <MetricCard key={index} {...metric} />
        ))}
      </div>
      {/* Graphs */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="bg-gray-900 rounded-xl shadow-lg p-6">
          <h3 className="text-xl font-semibold text-gray-100 mb-4">Appointments vs Calls</h3>
          <div className="h-80 w-full">
            <LineChart width={500} height={300} data={appointmentData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
              <XAxis dataKey="month" stroke="#9CA3AF" />
              <YAxis stroke="#9CA3AF" />
              <Tooltip 
                contentStyle={{ backgroundColor: '#1F2937', border: 'none', borderRadius: '8px' }}
                labelStyle={{ color: '#F3F4F6' }}
              />
              <Legend />
              <Line 
                type="monotone" 
                dataKey="calls" 
                stroke="#8B5CF6" 
                strokeWidth={2}
                dot={{ stroke: '#8B5CF6', strokeWidth: 2 }}
              />
              <Line 
                type="monotone" 
                dataKey="appointments" 
                stroke="#10B981" 
                strokeWidth={2}
                dot={{ stroke: '#10B981', strokeWidth: 2 }}
              />
            </LineChart>
          </div>
        </div>

        <div className="bg-gray-900 rounded-xl shadow-lg p-6">
          <h3 className="text-xl font-semibold text-gray-100 mb-4">Leads vs Calls</h3>
          <div className="h-80 w-full">
            <LineChart width={500} height={300} data={leadsData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
              <XAxis dataKey="month" stroke="#9CA3AF" />
              <YAxis stroke="#9CA3AF" />
              <Tooltip 
                contentStyle={{ backgroundColor: '#1F2937', border: 'none', borderRadius: '8px' }}
                labelStyle={{ color: '#F3F4F6' }}
              />
              <Legend />
              <Line 
                type="monotone" 
                dataKey="calls" 
                stroke="#8B5CF6" 
                strokeWidth={2}
                dot={{ stroke: '#8B5CF6', strokeWidth: 2 }}
              />
              <Line 
                type="monotone" 
                dataKey="leads" 
                stroke="#F59E0B" 
                strokeWidth={2}
                dot={{ stroke: '#F59E0B', strokeWidth: 2 }}
              />
            </LineChart>
          </div>
        </div>
      </div>

      {/* Call Components */}
      <div className="space-y-6">
        <div className="bg-gray-900 rounded-xl shadow-lg p-6">
          <h3 className="text-xl font-semibold text-gray-100 mb-4">Call Tracking</h3>
          {/* Call track component will go here */}
        </div>

        <div className="bg-gray-900 rounded-xl shadow-lg p-6">
          <h3 className="text-xl font-semibold text-gray-100 mb-4">Call Summary</h3>
          {/* Call summary component will go here */}
        </div>
      </div>
    </div>
  );
};

// Add custom animation
const style = document.createElement('style');
style.textContent = `
  @keyframes shake {
    0%, 100% { transform: rotate(0deg); }
    25% { transform: rotate(0.5deg); }
    75% { transform: rotate(-0.5deg); }
  }
  .animate-shake {
    animation: shake 0.5s ease-in-out;
  }
`;
document.head.appendChild(style);

export default Dashboard;