// VapiInboundCalls.js
import React, { useState, useEffect, useContext } from 'react';
import { Table, Button, Alert, Spinner, Form, Badge  } from 'react-bootstrap';
import { getInboundCalls, createInboundCall, getPhoneNumbers, getAssistants } from '../services/api';
import { OrganizationContext } from '../contexts/OrganizationContext';

const VapiInboundCalls = () => {
  const [inboundCalls, setInboundCalls] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newCall, setNewCall] = useState({
    phoneNumberId: '',
    assistantId: '',
    name: ''
  });

  const { organizationId } = useContext(OrganizationContext);   

  useEffect(() => {
    if (organizationId) {
        fetchData();
    }
  }, [organizationId]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [callsData, numbersData, assistantsData] = await Promise.all([
        getInboundCalls(organizationId),
        getPhoneNumbers(organizationId),
        getAssistants(organizationId)
      ]);
      setInboundCalls(callsData);
      setPhoneNumbers(numbersData);
      setAssistants(assistantsData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createInboundCall(
        newCall.assistantId,
        newCall.phoneNumberId,
        newCall.name,
        organizationId
      );
      fetchData();
      setNewCall({ phoneNumberId: '', assistantId: '', name: '' });
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) return <Spinner animation="border" />;
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <div className="container">
      <h2 className="mb-4">Create Inbound Call Configuration</h2>
      <Form onSubmit={handleSubmit} className="mb-4">
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={newCall.name}
            onChange={(e) => setNewCall({...newCall, name: e.target.value})}
            placeholder="Enter configuration name"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Phone Number</Form.Label>
          <Form.Select
            value={newCall.phoneNumberId}
            onChange={(e) => setNewCall({...newCall, phoneNumberId: e.target.value})}
            required
          >
            <option value="">Select Phone Number</option>
            {phoneNumbers.map(number => (
              <option key={number.id} value={number.id}>
                {number.phoneNumber} ({number.provider})
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Assistant</Form.Label>
          <Form.Select
            value={newCall.assistantId}
            onChange={(e) => setNewCall({...newCall, assistantId: e.target.value})}
            required
          >
            <option value="">Select Assistant</option>
            {assistants.map(assistant => (
              <option key={assistant.assistantId} value={assistant.assistantId}>
                {assistant.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Button type="submit" variant="primary">Create Configuration</Button>
      </Form>

      <h2 className="mb-3">Inbound Call Configurations</h2>
      {inboundCalls.length === 0 ? (
        <Alert variant="info">No inbound call configurations found.</Alert>
      ) : (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Assistant</th>
              <th>Status</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {inboundCalls.map(call => (
              <tr key={call.id}>
                <td>{call.name}</td>
                <td>{call.phoneNumber.phoneNumber}</td>
                <td>{call.assistantResponse.name}</td>
                <td>
                  <Badge bg={call.status === 'active' ? 'success' : 'warning'}>
                    {call.status}
                  </Badge>
                </td>
                <td>{new Date(call.createdAt).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default  VapiInboundCalls;