import React from 'react';

export function Terms() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold mb-8">Terms of Service</h1>
      
      <div className="space-y-8">
        <section>
          <h2 className="text-2xl font-semibold mb-4">1. Agreement to Terms</h2>
          <p className="text-gray-600 mb-4">
            By accessing or using AI Phone Agent, you agree to be bound by these Terms of Service.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">2. Service Description</h2>
          <p className="text-gray-600 mb-4">
            AI Phone Agent provides AI-powered phone automation services for businesses.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">3. User Obligations</h2>
          <ul className="list-disc pl-6 text-gray-600 space-y-2">
            <li>Provide accurate information</li>
            <li>Maintain security of account credentials</li>
            <li>Comply with applicable laws and regulations</li>
            <li>Not misuse or attempt to hack the service</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">4. Limitation of Liability</h2>
          <p className="text-gray-600 mb-4">
            AI Phone Agent shall not be liable for any indirect, incidental, special, consequential, or punitive damages.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">5. Changes to Terms</h2>
          <p className="text-gray-600">
            We reserve the right to modify these terms at any time. Continued use of the service constitutes acceptance of modified terms.
          </p>
        </section>
      </div>
    </div>
  );
}