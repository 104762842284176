import React from 'react';
import { twMerge } from 'tailwind-merge';

export const Spinner = ({ size = 'md', className = '' }) => {
  const sizeClasses = {
    sm: 'h-4 w-4',
    md: 'h-6 w-6',
    lg: 'h-8 w-8',
  };

  return (
    <div
      className={twMerge(
        'animate-spin rounded-full border-4 border-current border-t-transparent',
        sizeClasses[size],
        className
      )}
    />
  );
};