import React, { useState, useEffect, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { checkWebAvailability, bookWebAppointment, getAppointmentSettings } from '../services/api';
import { format, addDays, isAfter, isBefore, isToday, getDay } from 'date-fns';

import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Textarea } from "./ui/textarea";
import { Label } from "./ui/label";
import { ScrollArea } from "./ui/scroll-area";
import { Calendar } from "./ui/calendar";
import ThankYou from './ThankYou';

// Common timezone options
const TIMEZONES = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'Europe/London',
  'Europe/Paris',
  'Asia/Tokyo',
  'Australia/Sydney',
];

const AppointmentSetterOld = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // Form state
  const [formData, setFormData] = useState({
    clientName: '',
    clientEmail: '',
    clientPhone: '',
    clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    message: ''
  });
  
  // Settings state
  const [settings, setSettings] = useState({
    bookingOpenNoDays: null,
    weekendDays: [],
    eventName: '',
    meetingDurationMinutes: ''
  });

  const [showThankYou, setShowThankYou] = useState(false);
  const [bookedAppointment, setBookedAppointment] = useState(null);

  const calendarStyles = {
    calendar: {
      width: '100%',
      maxWidth: '400px',
      margin: '0 auto',
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '1rem',
    },
    header: {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      gap: '0.5rem',
      marginBottom: '0.5rem',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    weekday: {
      width: '2.5rem',
      height: '2.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    daysGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      gap: '0.5rem',
    },
    day: {
      width: '2.5rem',
      height: '2.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRadius: '50%',
    }
  };
  
  useEffect(() => {
    if (selectedDate && organizationId) {
      fetchTimeSlots(selectedDate);
      fetchAppointmentSettings();
    }
  }, [selectedDate, organizationId]);

  const fetchAppointmentSettings = async () => {
    try {
      const settings = await getAppointmentSettings(organizationId);
      setSettings(settings);
    } catch (error) {
      console.error('Error fetching appointment settings:', error);
      setError('Failed to fetch appointment settings');
    }
  };

  const fetchTimeSlots = async () => {
    setLoading(true);
    setError(null);
    try {
      // Format date as ISO string and truncate time/timezone part
      const dateString = selectedDate.toISOString().split('T')[0] + 'T00:00:00Z';
      
      const slots = await checkWebAvailability(
        organizationId, 
        dateString,
        formData.clientTimezone
      );
      
      setTimeSlots(slots.map(slot => ({
        ...slot,
        dateTime: new Date(slot.dateTime)
      })));
      setSelectedTime(null);
    } catch (error) {
      console.error('Error fetching time slots:', error);
      setError('Failed to fetch time slots');
      setTimeSlots([]); // Clear time slots on error
    } finally {
      setLoading(false);
    }
  };
  
  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleTimezoneChange = async (timezone) => {
    await handleInputChange('clientTimezone', timezone);
    await fetchTimeSlots(timezone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedTime) return;

    setLoading(true);
    try {
      const appointment = await bookWebAppointment(
        organizationId,
        selectedTime.toISOString(),
        formData.clientName,
        formData.clientEmail,
        formData.clientPhone,
        formData.clientTimezone,
        formData.message
      );

      setBookedAppointment({
        appointmentDateTime: selectedTime.toISOString(),
        contact: {
          name: formData.clientName,
          email: formData.clientEmail,
          phone: formData.clientPhone
        },
        timezone: formData.clientTimezone,
        message: formData.message
      });
      setShowThankYou(true);
    } catch (error) {
      setError('Failed to book appointment');
    } finally {
      setLoading(false);
    }
  };

  if (showThankYou && bookedAppointment) {
    return <ThankYou type="booked" appointment={bookedAppointment} />;
  }

  const isDateEnabled = (date) => {
    // const today = new Date();
    // const maxDate = addDays(today, settings.bookingOpenNoDays);
    // const isWeekend = settings.weekendDays.includes(getDay(date));
    // return isAfter(date, today) && isBefore(date, maxDate) && !isWeekend;
      if (!settings || !settings.weekendDays) {
          return true; // Or some default behavior until settings load
      }
      const today = new Date();
      const maxDate = addDays(today, settings.bookingOpenNoDays || 30); // Default to 30 days if not set
      const isWeekend = settings.weekendDays.includes(getDay(date));
      return isAfter(date, today) && isBefore(date, maxDate) && !isWeekend;
  };

  return (
    <div className="container max-w-4xl mx-auto py-6">
      <Card>
        <CardHeader>
          <CardTitle>Schedule Appointment</CardTitle>
          {settings.eventName && (
            <CardDescription>
              {settings.eventName} - {settings.meetingDurationMinutes}
            </CardDescription>
          )}
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid md:grid-cols-2 gap-6">
              {/* Calendar Section */}
              <div>
              <Label>Select Date</Label>
              <div className="mt-2">
                <Calendar
                  mode="single"
                  selected={selectedDate}
                  onSelect={handleDateSelect}
                  disabled={date => !isDateEnabled(date)}
                  className="rounded-md border w-full"
                  classNames={{
                    months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
                    month: "space-y-4",
                    caption: "flex justify-center pt-1 relative items-center",
                    caption_label: "text-sm font-medium",
                    nav: "space-x-1 flex items-center",
                    nav_button: "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
                    nav_button_previous: "absolute left-1",
                    nav_button_next: "absolute right-1",
                    table: "w-full border-collapse space-y-1",
                    head_row: "flex",
                    head_cell: "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
                    row: "flex w-full mt-2",
                    cell: "text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                    day: "h-9 w-9 p-0 font-normal aria-selected:opacity-100",
                    day_selected: "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
                    day_today: "bg-accent text-accent-foreground",
                    day_outside: "text-muted-foreground opacity-50",
                    day_disabled: "text-muted-foreground opacity-50",
                    day_range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground",
                    day_hidden: "invisible",
                  }}
                />
              </div>
              </div>

              {/* Time Slots Section */}
              <div>
                <Label>Select Time</Label>
                <ScrollArea className="h-[300px] rounded-md border p-4">
                  <div className="grid grid-cols-2 gap-2">
                    {loading ? (
                      <div>Loading time slots...</div>
                    ) : timeSlots.length === 0 ? (
                      <div>No available time slots</div>
                    ) : (
                      timeSlots.map((slot) => (
                        <Button
                          key={slot.dateTime.toISOString()}
                          variant={selectedTime?.toISOString() === slot.dateTime.toISOString() ? "default" : "outline"}
                          onClick={() => handleTimeSelect(slot.dateTime)}
                          disabled={!slot.available}
                          className="w-full"
                        >
                          {format(slot.dateTime, 'h:mm a')}
                        </Button>
                      ))
                    )}
                  </div>
                </ScrollArea>
              </div>
            </div>

            {/* Contact Information */}
            <div className="space-y-4">
              <div>
                <Label htmlFor="timezone">Timezone</Label>
                <Select
                  value={formData.clientTimezone}
                  onValueChange={handleTimezoneChange}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select timezone" />
                  </SelectTrigger>
                  <SelectContent>
                    {TIMEZONES.map(tz => (
                      <SelectItem key={tz} value={tz}>
                        {tz}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="grid md:grid-cols-2 gap-4">
                <div>
                  <Label htmlFor="name">Name</Label>
                  <Input
                    id="name"
                    value={formData.clientName}
                    onChange={(e) => handleInputChange('clientName', e.target.value)}
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="email">Email</Label>
                  <Input
                    id="email"
                    type="email"
                    value={formData.clientEmail}
                    onChange={(e) => handleInputChange('clientEmail', e.target.value)}
                    required
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="phone">Phone</Label>
                <Input
                  id="phone"
                  type="tel"
                  value={formData.clientPhone}
                  onChange={(e) => handleInputChange('clientPhone', e.target.value)}
                  required
                />
              </div>

              <div>
                <Label htmlFor="message">Message (Optional)</Label>
                <Textarea
                  id="message"
                  value={formData.message}
                  onChange={(e) => handleInputChange('message', e.target.value)}
                  rows={3}
                />
              </div>
            </div>

            {error && (
              <div className="text-red-500 text-sm">{error}</div>
            )}

            <Button 
              type="submit" 
              className="w-full"
              disabled={loading || !selectedTime || !formData.clientName || !formData.clientEmail || !formData.clientPhone}
            >
              {loading ? "Booking..." : "Book Appointment"}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default AppointmentSetterOld;