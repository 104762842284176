import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  LineChart,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  Bar,
  ResponsiveContainer
} from 'recharts';
import _ from 'lodash';
import {
  Bot,
  Send,
  User,
  Loader2,
  Minimize2,
  Maximize2,
  X,
  Phone,
  Calendar,
  FileText,
  Upload,
  Building2,
  Users,
  CreditCard,
} from "lucide-react";
import {
  getCallSummariesByOrganization,
  getMonthlyBillings
} from '../services/api';

// Basic navigation capabilities
const SYSTEM_CAPABILITIES = [
  {
    category: 'Organization Management',
    actions: [
      { name: 'View organization details', path: '/organization' },
      { name: 'Update organization information', path: '/organization' },
      { name: 'Manage subscription', path: '/subscriptions' }
    ]
  },
  {
    category: 'Contact Management',
    actions: [
      { name: 'View contacts', path: '/contact-management' },
      { name: 'Import contacts', path: '/contact-management' },
      { name: 'Search contacts', path: '/contact-management' }
    ]
  },
  {
    category: 'Calls and Communications',
    actions: [
      { name: 'View call summaries', path: '/themed-call-summary' },
      { name: 'Track calls', path: '/themed-call-track' },
      { name: 'Manage phone numbers', path: '/vapi-phone-numbers' },
      { name: 'Make outbound calls', path: '/vapi-outbound-calls' },
      { name: 'View SMS conversations', path: '/sms-conversations' }
    ]
  },
  {
    category: 'Appointments',
    actions: [
      { name: 'Manage appointment settings', path: '/appointments' },
      { name: 'Check availability', path: '/appointments' },
      { name: 'Book appointments', path: '/appointments' }
    ]
  },
  {
    category: 'File Management',
    actions: [
      { name: 'Upload files', path: '/upload-files' },
      { name: 'View uploaded files', path: '/upload-files' }
    ]
  },
  {
    category: 'Billing and Payments',
    actions: [
      { name: 'View monthly billing', path: '/monthly-billing' },
      { name: 'Check payment history', path: '/payment-history-component' },
    ]
  }
];

// Data analysis capabilities
const DATA_ANALYSIS_CAPABILITIES = {
  'call_summary': {
    category: 'Call Analysis',
    actions: [
      { 
        name: 'Show call summary chart',
        description: 'Display a chart showing call trends',
        handler: async (organizationId) => {
          const data = await getCallSummariesByOrganization(organizationId, {
            page: 0,
            size: 100
          });
          return {
            type: 'chart',
            data: data.content,
            chartType: 'line',
            title: 'Call Summary Trends'
          };
        }
      },
      { 
        name: 'Get monthly call statistics',
        description: 'Show monthly call statistics',
        handler: async (organizationId) => {
          const data = await getCallSummariesByOrganization(organizationId, {
            page: 0,
            size: 100
          });
          
          const stats = processCallStats(data.content);
          return {
            type: 'statistics',
            data: stats
          };
        }
      }
    ]
  },
  'billing_analysis': {
    category: 'Billing Analysis',
    actions: [
      {
        name: 'Show billing trends',
        description: 'Display billing trends chart',
        handler: async (organizationId) => {
          const data = await getMonthlyBillings(organizationId);
          return {
            type: 'chart',
            data: data.content,
            chartType: 'bar',
            title: 'Monthly Billing Trends'
          };
        }
      }
    ]
  }
};

// Helper function to process call statistics
const processCallStats = (calls) => {
  const monthlyStats = _.groupBy(calls, call => {
    const date = new Date(call.callTrack.startTime);
    return `${date.getFullYear()}-${date.getMonth() + 1}`;
  });

  return Object.entries(monthlyStats).map(([month, calls]) => ({
    month,
    totalCalls: calls.length,
    averageDuration: _.meanBy(calls, call => call.callTrack.duration),
    totalCost: _.sumBy(calls, call => call.callTrack.cost)
  }));
};

// Data Visualization Component
const DataVisualization = ({ data }) => {
  if (!data) return null;

  const renderChart = () => {
    switch (data.type) {
      case 'chart':
        return (
          <ResponsiveContainer width="100%" height={300}>
            {data.chartType === 'line' ? (
              <LineChart data={data.data}>
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="totalCalls" stroke="#8884d8" />
              </LineChart>
            ) : (
              <BarChart data={data.data}>
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="totalCost" fill="#8884d8" />
              </BarChart>
            )}
          </ResponsiveContainer>
        );

      case 'statistics':
        return (
          <div className="space-y-2">
            {data.data.map((stat, index) => (
              <div key={index} className="p-4 bg-gray-50 rounded-lg">
                <h3 className="font-medium">{stat.month}</h3>
                <div className="grid grid-cols-3 gap-4 mt-2">
                  <div>
                    <div className="text-sm text-gray-500">Total Calls</div>
                    <div className="text-lg font-medium">{stat.totalCalls}</div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-500">Avg Duration</div>
                    <div className="text-lg font-medium">
                      {Math.round(stat.averageDuration)}m
                    </div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-500">Total Cost</div>
                    <div className="text-lg font-medium">
                      £{stat.totalCost.toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="mt-4">
      {data.title && (
        <h3 className="text-lg font-medium mb-4">{data.title}</h3>
      )}
      {renderChart()}
    </div>
  );
};

// Message Component
const MessageComponent = ({ message, onNavigate }) => {
  const isAssistant = message.role === 'assistant';
  
  const getActionName = (action) => {
    if (!action) return '';
    if (action.action) return action.action.name;
    if (action.category) return action.category;
    return 'this section';
  };

  const getActionPath = (action) => {
    if (!action) return '';
    if (action.path) return action.path;
    if (action.action && action.action.path) return action.action.path;
    return '/';
  };
  
  return (
    <div className={`flex gap-3 ${isAssistant ? 'bg-gray-50' : ''} p-4`}>
      <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center">
        {isAssistant ? <Bot className="w-5 h-5" /> : <User className="w-5 h-5" />}
      </div>
      <div className="flex-1">
        <div className="text-sm font-medium mb-1">
          {isAssistant ? 'AI Assistant' : 'You'}
        </div>
        <div className="text-sm whitespace-pre-wrap">
          {message.content}
        </div>
        {message.visualization && (
          <DataVisualization data={message.visualization} />
        )}
        {message.action && (
          <div className="mt-2">
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => onNavigate(getActionPath(message.action))}
            >
              Go to {getActionName(message.action)}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

// Main FloatingChatbot Component
const FloatingChatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [messages, setMessages] = useState([
    {
      role: 'assistant',
      content: "Hello! I'm your AI assistant. I can help you navigate the system, analyze data, and show visualizations. How can I help you today?"
    }
  ]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const scrollAreaRef = useRef(null);
  const navigate = useNavigate();
  const { organizationId } = useContext(OrganizationContext);

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
    }
  }, [messages]);

  const identifyAction = (userInput) => {
    const input = userInput.toLowerCase();
    
    for (const category of SYSTEM_CAPABILITIES) {
      for (const action of category.actions) {
        if (input.includes(action.name.toLowerCase())) {
          return { action, category };
        }
      }
    }
    
    const keywords = {
      'call': '/themed-call-summary',
      'phone': '/vapi-phone-numbers',
      'contact': '/contact-management',
      'appointment': '/appointments',
      'payment': '/monthly-billing',
      'bill': '/monthly-billing',
      'upload': '/upload-files',
      'file': '/upload-files',
      'organization': '/organization',
      'subscription': '/subscriptions'
    };

    for (const [keyword, path] of Object.entries(keywords)) {
      if (input.includes(keyword)) {
        return { path };
      }
    }

    return null;
  };

  const identifyDataRequest = (input) => {
    for (const [key, capability] of Object.entries(DATA_ANALYSIS_CAPABILITIES)) {
      for (const action of capability.actions) {
        if (input.includes(action.name.toLowerCase()) ||
            input.includes(action.description.toLowerCase())) {
          return { action };
        }
      }
    }
    return null;
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
    setIsMinimized(false);
  };

  const toggleMinimize = (e) => {
    e.stopPropagation();
    setIsMinimized(!isMinimized);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setIsOpen(false);
    setIsMinimized(false);
  };

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const userMessage = { role: 'user', content: input };
    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsLoading(true);

    try {
      // First check for data analysis requests
      const dataRequest = identifyDataRequest(input.toLowerCase());
      if (dataRequest) {
        const { action } = dataRequest;
        const result = await action.handler(organizationId);
        
        setMessages(prev => [...prev, {
          role: 'assistant',
          content: `Here's the ${action.name.toLowerCase()} you requested:`,
          visualization: result
        }]);
        
        return;
      }

      // Check for regular navigation actions
      const matchedAction = identifyAction(input);
      
      let assistantResponse;
      if (matchedAction) {
        assistantResponse = {
          role: 'assistant',
          content: `I can help you with that! I'll take you to the appropriate section.`,
          action: matchedAction
        };
        
        setTimeout(() => {
          navigate(matchedAction.path || matchedAction.action.path);
        }, 1500);
      } else {
        assistantResponse = {
          role: 'assistant',
          content: "I can help you with:\n\n" +
            "1. Navigation and basic tasks:\n" +
            SYSTEM_CAPABILITIES.map(cat => 
              `- ${cat.category}`
            ).join('\n') +
            "\n\n2. Data Analysis:\n" +
            Object.values(DATA_ANALYSIS_CAPABILITIES).map(cat =>
              cat.actions.map(action => `- ${action.name}`).join('\n')
            ).join('\n')
        };
      }

      setMessages(prev => [...prev, assistantResponse]);
    } catch (error) {
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: "I apologize, but I encountered an error. Please try again or contact support if the issue persists."
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  if (!isOpen) {
    return (
      <Button
        className="fixed bottom-28 right-6 rounded-full w-14 h-14 p-0 
          bg-gradient-to-br from-indigo-500 to-indigo-600
          shadow-[0_8px_30px_rgb(0,0,0,0.12)]
          hover:shadow-[0_8px_20px_rgb(0,0,0,0.25)]
          active:shadow-[0_4px_10px_rgb(0,0,0,0.15)]
          border-4 border-white
          transition-all duration-200 ease-in-out
          hover:-translate-y-0.5
          active:translate-y-0
          transform-gpu"
        onClick={toggleChat}
      >
        <Bot className="w-6 h-6 text-white" />
        <div className="absolute inset-0 rounded-full bg-white/10 hover:bg-white/20 transition-colors"></div>
      </Button>
    );
  }
  
  return (
    <div
    className={`fixed bottom-28 right-6 z-50 transition-all duration-300 ease-in-out
      ${isMinimized ? 'w-72 h-14' : 'w-96 h-[500px]'}`} // Reduced height from 600px to 500px
    >
      <Card className="w-full h-full flex flex-col shadow-xl">
        {/* Header */}
        <div 
          className="p-4 border-b flex items-center justify-between cursor-pointer"
          onClick={toggleMinimize}
        >
          <div className="flex items-center gap-2">
            <Bot className="w-5 h-5" />
            <span className="font-medium">AI Assistant</span>
          </div>
          <div className="flex items-center gap-2">
            <Button
              variant="ghost"
              size="sm"
              className="h-8 w-8 p-0"
              onClick={toggleMinimize}
            >
              {isMinimized ? <Maximize2 className="h-4 w-4" /> : <Minimize2 className="h-4 w-4" />}
            </Button>
            <Button
              variant="ghost"
              size="sm"
              className="h-8 w-8 p-0"
              onClick={handleClose}
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        </div>

        {/* Chat Content - Only shown when not minimized */}
        {!isMinimized && (
          <>
            <ScrollArea className="flex-1 p-4" ref={scrollAreaRef}>
              <div className="space-y-4">
                {messages.map((message, index) => (
                  <MessageComponent 
                    key={index} 
                    message={message} 
                    onNavigate={navigate}
                  />
                ))}
                {isLoading && (
                  <div className="flex items-center gap-3 p-4">
                    <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center">
                      <Bot className="w-5 h-5" />
                    </div>
                    <Loader2 className="w-4 h-4 animate-spin" />
                  </div>
                )}
              </div>
            </ScrollArea>
            
            <CardContent className="border-t p-4 mt-auto">
              <div className="flex gap-3">
                <Input
                  placeholder="Type your message..."
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                  className="flex-1"
                />
                <Button onClick={handleSendMessage} disabled={isLoading}>
                  {isLoading ? (
                    <Loader2 className="w-4 h-4 animate-spin" />
                  ) : (
                    <Send className="w-4 h-4" />
                  )}
                </Button>
              </div>
            </CardContent>
          </>
        )}
      </Card>
    </div>
  );
};

export default FloatingChatbot;